export const STATUS = {
  SUCCESS: "SUCCESS",
  NOT_STARTED: "NOT_STARTED",
  FETCHING: "FETCHING",
  FAILED: "FAILED",
};

export const QUESTION_COLOR = {
  "total-questions": "#5499C7",
  "not-viewed-yet": "gray.400",
  "not-attempted": "brand.red",
  attempted: "brand.green",
  reviewed: "brand.blue",
  "attempted-reviewed": "accent.400",
};

export const QUESTION_RESPONSE_COLOR = {
  correct: "brand.green",
  incorrect: "brand.red",
  "not-marked": "gray.400",
};

export const CONTENT_TYPE = {
  TEXT: "BookContent",
  // PDF: 'PDF',
  VIDEO: "Video",
  AUDIO: "Audio",
  DOCUMENT: "Document",
  TEST: 'Test'
};

export const OBJ_CONTENT_TYPE = {
  BookContent: "texts",
  // PDF: 'PDF',
  Video: "videos",
  Audio: "audios",
  Document: "documents",
};

export const FILE_TYPE = {
  texts: "BookContent",
  videos: "Video",
  audios: "Audio",
  documents: "Document",
};

export const TEST_STATE = {
  IN_PROGRESS: "in-progress",
  COMPLETED: "completed",
  NOT_STARTED: "not-started",
};

export const TEHSIL_LIST = [
  {
    _id: "KARNAL",
    sub: [
      "Assandh",
      "Ballah ST",
      "Gharaunda",
      "Indri",
      "Karnal",
      "Nigdu ST",
      "Nilokheri",
      "Nising ST",
    ],
  },
  {
    _id: "JALORE",
    sub: [
      "Ahore",
      "Bagora",
      "Bhinmal",
      "Chitalwana",
      "Jalor",
      "Jaswantpura",
      "Raniwara",
      "Sanchore",
      "Sayla",
    ],
  },
  {
    _id: "UDAIPUR",
    sub: [
      "Badgaon",
      "Bhindar",
      "Girwa",
      "Gogunda",
      "Jhadol",
      "Kanod",
      "Kherwara",
      "Kotra",
      "Kurabad",
      "Lasadiya",
      "Mavli",
      "Nayagaon",
      "Rishabhdeo",
      "Salumbar",
      "Sarada",
      "Semari",
      "Vallabhnagar",
    ],
  },
  {
    _id: "SAWAI MADHOPUR",
    sub: [
      "Bamanwas",
      "Bonli",
      "Chauth Ka Barwara",
      "Gangapur",
      "Khandar",
      "Malarna Doongar",
      "Mitrapura",
      "Sawai Madhopur",
      "WAZEERPUR",
    ],
  },
  {
    _id: "JIND",
    sub: [
      "Alewa",
      "Jind",
      "Julana",
      "Narwana",
      "Pillukhera ST",
      "Safidon",
      "Uchana",
    ],
  },
  {
    _id: "DUNGARPUR",
    sub: [
      "Aspur",
      "BICHIWARA",
      "Chikhali",
      "Dowda",
      "Dungarpur",
      "Galiyakot",
      "Gamdi Ahada",
      "JOTHARI",
      "Sabla",
      "Sagwara",
      "Simalwara",
    ],
  },
  {
    _id: "CHITTORGARH",
    sub: [
      "Bari Sadri",
      "Bassi",
      "Begun",
      "Bhadesar",
      "BHOPALSAGAR",
      "Chittaurgarh",
      "Dungla",
      "Gangrar",
      "Kapasan",
      "Nimbahera",
      "Rashmi",
      "Rawatbhata",
    ],
  },
  {
    _id: "SIRSA",
    sub: [
      "Dabwali",
      "Ellenabad",
      "Goriwala ST",
      "Kalanwali",
      "Nathusari Chopta",
      "Rania",
      "Sirsa",
    ],
  },
  {
    _id: "BHIWANI",
    sub: ["Bahal ST", "Bawani Khera", "Bhiwani", "Loharu", "Siwani", "Tosham"],
  },
  {
    _id: "BARMER",
    sub: [
      "Barmer",
      "Barmer Gramin",
      "Baytoo",
      "Chohtan",
      "Dhanaau",
      "DHORIMANNA",
      "Gadraroad",
      "Gida",
      "Gudha Malani",
      "Kalyanpur",
      "Nokhra",
      "Pachpadra",
      "Ramsar",
      "Samdari",
      "SEDWA",
      "Sheo",
      "Sindhari",
      "Siwana",
    ],
  },
  {
    _id: "SIKAR",
    sub: [
      "Danta Ramgarh",
      "DHOD",
      "Fatehpur",
      "KHENDELA",
      "Lachhmangarh",
      "Nechwa",
      "Neem-Ka-Thana",
      "RAMGARH SHEKAWATI",
      "Sikar",
      "Sri Madhopur",
    ],
  },
  {
    _id: "SONIPAT",
    sub: ["Ganaur", "Gohana", "Khanpur ST", "Kharkhoda", "Rai ST", "Sonipat"],
  },
  {
    _id: "TONK",
    sub: [
      "Deoli",
      "Duni",
      "Malpura",
      "Nagarfort",
      "Niwai",
      "Peeplu",
      "Todaraisingh",
      "Tonk",
      "Uniara",
    ],
  },
  {
    _id: "PANIPAT",
    sub: ["Bapoli", "Israna", "Matlauda", "Panipat", "Samalkha"],
  },
  {
    _id: "PRATAPGARH",
    sub: [
      "Arnod",
      "Chhoti Sadri",
      "Dalot",
      "Dhariawad",
      "Peepalkhoont",
      "Pratapgarh",
      "Suhagpura",
    ],
  },
  {
    _id: "REWARI",
    sub: [
      "Bawal",
      "Dahina ST",
      "Dharuhera ST",
      "Kosli",
      "Manethi ST",
      "Nahar ST",
      "Palhawas ST",
      "Rewari",
    ],
  },
  {
    _id: "JODHPUR",
    sub: [
      "Aau",
      "Balesar",
      "Baori",
      "Bap",
      "Bapini",
      "Bhopalgarh",
      "Bilara",
      "Dechu",
      "Jodhpur",
      "LOHAWAT",
      "Luni",
      "Osian",
      "Phalodi",
      "Pipar shahar",
      "Setrawa",
      "Shekhala",
      "Shergarh",
      "TINWARI",
    ],
  },
  {
    _id: "SIROHI",
    sub: ["Abu Road", "Deldar", "Pindwara", "Reodar", "Sheoganj", "Sirohi"],
  },
  {
    _id: "DHOLPUR",
    sub: [
      "Bari",
      "Baseri",
      "Dhaulpur",
      "Mania",
      "Rajakhera",
      "SARMATHURA",
      "Sepau",
    ],
  },
  {
    _id: "PANCHKULA",
    sub: ["Barwala ST", "Kalka", "Morni ST", "Panchkula", "Raipur Rani"],
  },
  {
    _id: "BHILWARA",
    sub: [
      "Asind",
      "Badnor",
      "Banera",
      "Bhilwara",
      "Bijoliya",
      "Hameergarh",
      "Hurda",
      "Jahazpur",
      "Kareda",
      "Kotri",
      "Mandal",
      "Mandalgarh",
      "Phooliya Kalan",
      "Raipur",
      "Sahara",
      "Shahpura",
    ],
  },
  {
    _id: "JAISALMER",
    sub: ["Bhaniyana", "Fatehgarh", "Jaisalmer", "Pokaran", "Sum"],
  },
  {
    _id: "Juhnjhunun",
    sub: [
      "Buhana",
      "Chirawa",
      "Gudhagorji",
      "Jhunjhunun",
      "Khetri",
      "MALSISAR",
      "Mandawa",
      "Nawalgarh",
      "SURAJGARH",
      "Udaipurwati",
    ],
  },
  {
    _id: "KAITHAL",
    sub: [
      "Dhand ST",
      "Fatehpur Pundri",
      "Guhla",
      "Kaithal",
      "Kalayat",
      "Rajaund ST",
      "Siwan ST",
    ],
  },
  {
    _id: "ROHTAK",
    sub: ["Kalanaur", "Lakhan Majra ST", "Maham", "Rohtak", "Sampla"],
  },
  {
    _id: "KARAULI",
    sub: [
      "Hindaun",
      "Karauli",
      "Mandrayal",
      "Masalpur",
      "Nadoti",
      "Sapotra",
      "ShriMahaveer Ji",
      "Suroth",
      "Todabhim",
    ],
  },
  {
    _id: "NUH",
    sub: ["Ferozepur Jhirka", "Indri", "Nagina ST", "Nuh", "Punahana", "Taoru"],
  },
  {
    _id: "BANSWARA",
    sub: [
      "Abapura",
      "ANANDPURI",
      "Artuna",
      "Bagidora",
      "Banswara",
      "CHHOTI SARWAN",
      "Gangadtalai",
      "Ganoda",
      "Garhi",
      "Ghatol",
      "Kushalgarh",
      "SAJJANGARH",
    ],
  },
  {
    _id: "HANUMANGARH",
    sub: [
      "Bhadra",
      "Hanumangarh",
      "Nohar",
      "Pilibanga",
      "Rawatsar",
      "Sangaria",
      "Tibi",
    ],
  },
  {
    _id: "GANGANAGAR",
    sub: [
      "Anupgarh",
      "Ganganagar",
      "Gharsana",
      "Karanpur",
      "Padampur",
      "Raisinghnagar",
      "Ravla",
      "Sadulshahar",
      "Suratgarh",
      "Vijainagar",
    ],
  },
  {
    _id: "BHARATPUR",
    sub: [
      "Bayana",
      "Bharatpur",
      "Bhusawar",
      "Deeg",
      "Kaman",
      "Kumher",
      "Nadbai",
      "Nagar",
      "Pahari",
      "Rupbas",
      "Sikri",
      "Uchhain",
      "Weir",
    ],
  },
  {
    _id: "ALWAR",
    sub: [
      "Alwar",
      "Bansur",
      "Behror",
      "GOVINDGARH",
      "Kathumar",
      "Kishangarh Bas",
      "Kotkasim",
      "Lachhmangarh",
      "MALAKHERA",
      "Mandawar",
      "Narayanpur",
      "Naugaon",
      "NEEMRANA",
      "Rajgarh",
      "Ramgarh",
      "RENI",
      "Tapukara",
      "Tehla",
      "Thanagazi",
      "Tijara",
    ],
  },
  {
    _id: "CHURU",
    sub: [
      "BIDASAR",
      "Churu",
      "Rajgarh",
      "Ratangarh",
      "Sardarshahar",
      "Sidhmukh",
      "Sujangarh",
      "Taranagar",
    ],
  },
  {
    _id: "JHAJJAR",
    sub: [
      "Badli",
      "Bahadurgarh",
      "Beri",
      "Jhajjar",
      "Matenhail",
      "Salhawas ST",
    ],
  },
  {
    _id: "AJMER",
    sub: [
      "Ajmer",
      "Arain",
      "Beawar",
      "Bhinay",
      "Kekri",
      "Kishangarh",
      "Masuda",
      "Nasirabad",
      "Peesangan",
      "Pushkar",
      "Roopangarh",
      "Sarwar",
      "Sawar",
      "Tantoli",
      "Tatgarh",
      "Vijaynagar",
    ],
  },
  {
    _id: "KURUKSHETRA",
    sub: [
      "Babain ST",
      "Ismailabad ST",
      "Ladwa",
      "Pehowa",
      "Shahbad",
      "Thanesar",
    ],
  },
  {
    _id: "CHARKI DADRI",
    sub: ["Badhra", "Bondkalan ST", "Dadri"],
  },
  {
    _id: "GURUGRAM",
    sub: [
      "Badshahpur ST",
      "Farrukhnagar",
      "Gurgaon",
      "Harsaru ST",
      "Kadipur ST",
      "Manesar",
      "Pataudi",
      "Sohna",
      "Wazirabad ST",
    ],
  },
  {
    _id: "PALWAL",
    sub: ["Bahin ST", "Hassanpur ST", "Hathin", "Hodal", "Palwal"],
  },
  {
    _id: "JAIPUR",
    sub: [
      "Aandhi",
      "Amber",
      "Bassi",
      "Chaksu",
      "Chomu",
      "DUDU",
      "Jaipur",
      "Jamwa Ramgarh",
      "Jobner",
      "KISHANGARH RENWAL",
      "KOTKHAWDA",
      "Kotputli",
      "Madhorajpura",
      "Mauzamabad",
      "Pavta",
      "Phagi",
      "Phulera (Hq.Sambhar)",
      "Sanganer",
      "Shahpura",
      "Tunga",
      "Viratnagar",
    ],
  },
  {
    _id: "PALI",
    sub: [
      "Bali",
      "Desuri",
      "Jaitaran",
      "Marwar Junction",
      "Pali",
      "Raipur",
      "Rani",
      "Rohat",
      "Sojat",
      "Sumerpur",
    ],
  },
  {
    _id: "DAUSA",
    sub: [
      "Baswa",
      "Behrawanda",
      "Bejupada",
      "Dausa",
      "Lalsot",
      "Lawan",
      "Mahwa",
      "Mandawar",
      "Nangal Rajawatan",
      "Rahuwas",
      "Ramgarh Pachwara",
      "Sainthal",
      "Sikrai",
    ],
  },
  {
    _id: "FATEHABAD",
    sub: [
      "Bhattukalan ST",
      "Bhuna ST",
      "Fatehabad",
      "Jakhal ST",
      "Kulan ST",
      "Ratia",
      "Tohana",
    ],
  },
  {
    _id: "YAMUNANAGAR",
    sub: [
      "Bilaspur",
      "Chhachhrauli",
      "Jagadhri",
      "Pratap Nagar ST",
      "Radaur",
      "Sadhaura ST",
      "Saraswati Nagar ST",
    ],
  },
  {
    _id: "AMBALA",
    sub: [
      "Ambala",
      "Ambala Cantonment",
      "Barara",
      "Mulana ST",
      "Naraingarh",
      "Saha ST",
      "Shahzadpur ST",
    ],
  },
  {
    _id: "JHALAWAR",
    sub: [
      "Aklera",
      "ASNAWAR",
      "Bakani",
      "Dug",
      "Gangdhar",
      "Jhalrapatan",
      "Khanpur",
      "Manohar Thana",
      "Pachpahar",
      "Pirawa",
      "Raipur",
      "Sunel",
    ],
  },
  {
    _id: "MAHENDRAGARH",
    sub: [
      "Ateli",
      "Kanina",
      "Mahendragarh",
      "Nangal Chawdhary",
      "Narnaul",
      "Satnali ST",
    ],
  },
  {
    _id: "FARIDABAD",
    sub: [
      "Badkhal",
      "Ballabgarh",
      "Dayalpur ST",
      "Dhauj ST",
      "Faridabad",
      "Gaunchi ST",
      "Mohna ST",
      "Tigaon ST",
    ],
  },
  {
    _id: "RAJSAMAND",
    sub: [
      "Amet",
      "Bhim",
      "Delwara",
      "Deogarh",
      "GARHBOR",
      "KHAMNOR",
      "Kumbhalgarh",
      "Kuwaria",
      "Nathdwara",
      "Railmagra",
      "Rajsamand",
    ],
  },
  {
    _id: "BIKANER",
    sub: [
      "Bajju",
      "Bikaner",
      "Chhatargarh",
      "Khajuwala",
      "Kolayat",
      "Lunkaransar",
      "Nokha",
      "Poogal",
      "Sridungargarh",
    ],
  },
  {
    _id: "BUNDI",
    sub: ["Bundi", "Hindoli", "Indragarh", "Keshoraipatan", "Nainwa", "TALERA"],
  },
  {
    _id: "NAGAUR",
    sub: [
      "Degana",
      "Didwana",
      "Jayal",
      "Kheenvsar",
      "KUCHAMAN CITY",
      "Ladnu",
      "Makrana",
      "Merta",
      "MUNDWA",
      "Nagaur",
      "Nawa",
      "Parbatsar",
      "RIYAN BADI",
      "Sanjhu",
    ],
  },
  {
    _id: "KOTA",
    sub: ["Digod", "Kanwas", "Ladpura", "Pipalda", "Ramganj Mandi", "Sangod"],
  },
  {
    _id: "BARAN",
    sub: [
      "Antah",
      "Atru",
      "Baran",
      "Chhabra",
      "Chhipabarod",
      "Kishanganj",
      "Mangrol",
      "Shahbad",
    ],
  },
  {
    _id: "HISAR",
    sub: [
      "Adampur",
      "Balsamand ST",
      "Barwala",
      "Bass",
      "Hansi",
      "Hisar",
      "Kheri Jalab ST",
      "Narnaund",
      "Uklana ST",
    ],
  },
];
