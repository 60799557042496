/* eslint-disable no-extend-native */
import React, { useState } from "react";
import {
  Box,
  HStack,
  Image,
  VStack,
  Text,
  Spacer,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  IconButton,
  Button,
  Tooltip,
  AspectRatio,
  Heading,
  Tag,
} from "@chakra-ui/react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiBadgeCheck } from "react-icons/bi";
import { FiExternalLink } from "react-icons/fi";
import { dateDecompose } from "../../utils/datehelper";
import { useHistory } from "react-router-dom";
import { MdAddShoppingCart } from "react-icons/md";
import { ROUTES } from "../../Constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "../../Cart/useCart";
import { STATUS } from "../../App/Constants";
import {
  addToWishlistAction,
  rmvFromWishlistAciton,
} from "../../redux/reducers/wishlist";
import { LockIcon, StarIcon } from "@chakra-ui/icons";
import { useIsAuthenticated } from "../../App/Context";
import { GiCheckMark } from "react-icons/gi";
import { round, size, toLower } from "lodash";
import { getNumberParsedString } from "../../utils/Number";
import { BsEye } from "react-icons/bs";
import { getPackagePrice } from "../../utils/Helper";
import moment from "moment";
import { useLoginModal } from "../../App/useLoginModal";

String.prototype.capitalize = function () {
  return (
    this.charAt(0).toUpperCase() + this.slice(1, this.length).toLowerCase()
  );
};

export const TestCard = ({ item }) => {
  const isAuthenticated = useIsAuthenticated();

  const carousel = size(item.carousel) && item.carousel;
  const history = useHistory();

  const imgUrl = carousel && carousel[0];

  const isBefore = moment(item.startDate).isAfter(moment());


  const isAfter = moment(item.endDate).set("h", 23).set("minute",59).isBefore(moment());
  // console.log({isBefore, isAfter, d: currentPkg.startDate, e: endDate})
  const isAvailable = !isBefore && !isAfter;



  console.log({ item, isBefore, isAfter });

  const goToDetails = () => {
    // window.open(
    //   "https://prayassikar.com" + ROUTES.PACKAGE_DETAILS + "?id=" + item._id,
    //   "_self"
    // );
    history.push(ROUTES.PACKAGE_DETAILS + "?id=" + item._id)
  };

  const answerfiles = item?.files?.filter((f) =>
    toLower(f.name).startsWith("answer")
  )?.length;

  const resultFiles = item?.files?.filter(
    (f) => !toLower(f.name).startsWith("answer")
  )?.length;

  return (
    <Box
      cursor={"pointer"}
      onClick={goToDetails}
      display={["block", "block", "flex"]}
      my={10}
      alignItems="center"
      bg="white"
      p={{ base: 0, md: 10 }}
    >
      <Box flex={1}>
        <AspectRatio ratio={16 / 9}>
          <Image
            src={imgUrl}
            width={"100%"}
            height={"100%"}
            fallbackSrc="https://via.placeholder.com/200x200.png/FAFAFA/C7C7C7?text=PRAYAS"
          />
        </AspectRatio>
      </Box>
      <Box p={10} flex={[1, 1, 1.5, 2]}>
        <Heading color="gray.600" fontSize="2xl">
          {item?.name.en}
        </Heading>
        {isAvailable ? (
          <Button onClick={goToDetails} size="md" colorScheme="primary" mt={10}>
            Register Now
          </Button>
        ) : (
          <Box mt={3}>
            <Tag mx={1} colorScheme="orange">
              Download Admit Card
            </Tag>
            {answerfiles ? (
              <Tag mx={1} colorScheme="blue">
                Answer keys available
              </Tag>
            ) : null}
            {resultFiles ? (
              <Tag mx={1} colorScheme="green">
                Result Available
              </Tag>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const Card = ({
  item,
  width,
  height,
  horizontal,
  removePkg,
  hideActions,
  minWidth,
  testSeries,
}) => {
  // const [isSmallDevice] = useMediaQuery("(max-width: 48em)");
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { openLoginModal } = useLoginModal();

  const { wishlistPackages, rmvFromWishlistStatus, addWishlistStatus } =
    useSelector((state) => ({
      wishlistPackages: state.wishlist.wishlistPackages,
      rmvFromWishlistStatus: state.wishlist.rmvFromWishlistStatus,
      addWishlistStatus: state.wishlist.addWishlistStatus,
    }));

  const [showDescription, setShowDescription] = useState(false);
  const [currentPkg, setCurrentPkg] = useState();

  const openModal = () => {
    setShowDescription((s) => !s);
  };

  const addToWishlist = (e) => {
    e.stopPropagation();
    dispatch(addToWishlistAction({ packageId: item._id }));
    setCurrentPkg(item._id);
  };

  const removeFromWishlist = (e) => {
    e.stopPropagation();
    dispatch(rmvFromWishlistAciton({ packageId: item._id }));
    setCurrentPkg(item._id);
  };

  const { isPackageInCart, isPackagePurchased } = useCart();

  const isPurchased = isPackagePurchased(item?._id);
  const itemInCart = !isPurchased && isPackageInCart(item?._id);

  const history = useHistory();
  const _buyNow = () => {
    if (!isAuthenticated) {
      openLoginModal()
    }
    else {
      history.push("/checkout?id=" + item._id + "&name=" + item?.name.en)
    }
  };

  let wishlisted =
    wishlistPackages?.packages?.length &&
    _.findIndex(wishlistPackages.packages, (p) => p._id == item._id) != -1;

  const cardCtaBtnStyle = {
    variant: "ghost",
    colorScheme: "white",
    _focus: { boxShadow: "none" },
  };

  const viewPkg = () => {
    if (item.type === "TEST")
      history.push("/dashboard/test-packages/" + item._id);
    else if (item.type === "MAGAZINE") history.push("/dashboard/magazines/");
    else history.push("/dashboard/courses/");
  };

  const { price, fakePrice, offer } = getPackagePrice(item);

  const carousel =
    (offer && size(offer?.carousel) && offer?.carousel) ||
    (size(item?.carousel) && item?.carousel);

  const imgUrl = carousel && carousel[0];
  const goToDetails = () => {
    history.push(ROUTES.PACKAGE_DETAILS + "?id=" + item._id);
  };

  const free = !price || price === 0 || price === "0";

  return testSeries ? (
    <Box
      width={width || "330px"}
      align="stretch"
      py="1rem"
      px={[0, "1rem", "1rem"]}
    >
      <Box
        flexGrow={1}
        background="white"
        cursor="pointer"
        boxShadow={["xs", "sm", "md"]}
        borderRadius="5px"
        minHeight={["250px", "280px", "280px"]}
        // maxW = {isSmallDevice ? "200px" : "300px"}
        overflow="hidden"
        transition="all 0.25s"
        filter="brightness(1)"
        // bg="#00000007"
        _hover={{ boxShadow: "lg", filter: "brightness(0.94)" }}
      >
        <Box onClick={goToDetails} p={4}>
          <AspectRatio ratio={16 / 9}>
            <Image
              src={imgUrl}
              width={"100%"}
              height={"100%"}
              fallbackSrc="https://via.placeholder.com/200x200.png/FAFAFA/C7C7C7?text=PRAYAS"
            />
          </AspectRatio>
        </Box>
        <Box width="100%" my={2}>
          <Text fontSize="lg" color="gray.700" fontWeight="bold">
            {item?.name.en}
          </Text>
        </Box>
      </Box>
    </Box>
  ) : (
    <VStack
      width={width || "330px"}
      align="stretch"
      py="1rem"
      px={[0, "1rem", "1rem"]}
    >
      <Box
        flexGrow={1}
        background="white"
        cursor="pointer"
        boxShadow={["xs", "sm", "md"]}
        borderRadius="5px"
        minHeight={["250px", "260px", "260px"]}
        // maxW = {isSmallDevice ? "200px" : "300px"}
        overflow="hidden"
        transition="all 0.25s"
        filter="brightness(1)"
        // bg="#00000007"
        _hover={{ boxShadow: "lg", filter: "brightness(0.94)" }}
      >
        <VStack h="100%" align="stretch">
          <Box onClick={goToDetails} flex={1}>
            <Box width="100%" pos="relative">
              {isAuthenticated ? (
                <Tooltip
                  label={
                    wishlisted ? "Remove from wishlist" : "Add to wishlist"
                  }
                  placement="top"
                >
                  <Box
                    position="absolute"
                    right="0"
                    top="0"
                    bg="white"
                    zIndex="99"
                    boxShadow="lg"
                    borderRadius="0 0 0 50%"
                    overflow="hidden"
                    onClick={wishlisted ? removeFromWishlist : addToWishlist}
                  >
                    {wishlisted ? (
                      <IconButton
                        colorScheme="red"
                        borderRadius="0"
                        variant="ghost"
                        isLoading={
                          currentPkg == item._id &&
                          rmvFromWishlistStatus == STATUS.FETCHING
                        }
                        icon={<AiFillHeart fontSize="22px" color="red" />}
                      />
                    ) : (
                      <IconButton
                        colorScheme="red"
                        borderRadius="0"
                        variant="ghost"
                        isLoading={
                          currentPkg == item._id &&
                          addWishlistStatus == STATUS.FETCHING
                        }
                        icon={<AiOutlineHeart fontSize="22px" color="red" />}
                      />
                    )}
                  </Box>
                </Tooltip>
              ) : null}

              <AspectRatio ratio={16 / 9}>
                <Image
                  src={imgUrl}
                  width={"100%"}
                  height={"100%"}
                  fallbackSrc="https://via.placeholder.com/200x200.png/FAFAFA/C7C7C7?text=PRAYAS"
                />
              </AspectRatio>
              {/* <Image src='https://competitioncommunity.com/local/assets/images/packages/13/348-1615812695.jpg' width='348px'  fallbackSrc="https://via.placeholder.com/150x100" width="100%"/> */}
            </Box>
            <Box p={4}>
              <Box
                alignItems={"flex-start"}
                display={"flex"}
                justifyContent="space-between"
                width="100%"
                my={2}
              >
                <Text
                  flex={1}
                  fontSize="md"
                  textAlign="start"
                  color="gray.700"
                  fontWeight="bold"
                >
                  {item?.name.en}
                </Text>
                <HStack mt={2}>
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      bg="lightGreen"
                      p="0.2rem 0.5rem"
                      alignItems="center"
                      borderRadius="4px"
                    >
                      <Text
                        fontSize="xs"
                        fontWeight="bold"
                        color="white"
                        mr="0.5rem"
                      >
                        {item?.rating || 4.9}
                      </Text>
                      <StarIcon style={{ color: "white", fontSize: "12px" }} />
                    </Box>
                    {/* <Text ml="0.3rem" fontSize="xs" color="text.100">(86,854)</Text> */}
                  </Box>
                  {/* <Spacer/> */}
                  {/* <Box display="flex" alignItems="center" fontSize="sm" color="text.100">
                                        <Box display="flex" alignItems="center">
                                            <HiOutlineUserGroup fontSize="sm" style={{display: 'inline-block'}}/>
                                            <Text>320</Text>
                                        </Box>
                                        <Box display="flex" alignItems="center" ml="1rem">
                                            <AiOutlineComment fontSize="sm" style={{display: 'inline-block'}}/>
                                            <Text>320</Text>
                                        </Box>
                                    </Box> */}
                </HStack>
              </Box>
              <Box mt={1}>
                <HStack>
                  <Flex flexWrap="wrap" fontWeight="bold" alignItems={"center"}>
                    {!testSeries && (
                      <Text fontSize="lg" pr={2} color="brand.redAccent">
                        Rs.{price || "0"}{" "}
                      </Text>
                    )}
                    {fakePrice ? (
                      <Flex wrap="wrap">
                        <Text
                          textDecor="line-through"
                          fontSize="xs"
                          color="text.200"
                        >
                          Rs. {fakePrice || "0"}
                        </Text>
                        <Text
                          fontSize="xs"
                          fontWeight="lighter"
                          color="#DB4437E8"
                        >
                          (
                          {getNumberParsedString(
                            ((fakePrice - price) / fakePrice) * 100
                          )}
                          % Off)
                        </Text>
                      </Flex>
                    ) : null}
                  </Flex>
                </HStack>
                {item?.gst ? (
                  <Text fontSize="xs" textAlign={"start"} color="success.500">
                    (+{round(item.gst, 2)}% GST Extra)
                  </Text>
                ) : null}
              </Box>
              <Box>
                {/* To Do : Fetch and Replace Hard Coded Values */}
                {/* <Text textAlign={"start"} color="gray.400" fontSize="xs">
                  Latest updated at {toReadableDate(item.updatedAt)}
                </Text> */}
              </Box>
            </Box>
          </Box>
          {hideActions ? null : (
            <>
              <br />
              <Box pb="0.5rem">
                {item?.published === 2 ? (
                  <Flex width="100%" justify="space-around">
                    <Text
                      colorScheme="white"
                      style={{
                        color: "#5DADE2",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Coming soon
                    </Text>
                  </Flex>
                ) : isPurchased ? ( // Check is Item is Already Purchased
                  <Box>
                    <Flex width="100%" justify="space-around">
                      <Button
                        leftIcon={<GiCheckMark style={{ fontSize: "20px" }} />}
                        colorScheme="white"
                        style={{ color: "#DB4437" }}
                        {...cardCtaBtnStyle}
                      >
                        Purchased
                      </Button>
                      <Button
                        // onClick={_buyNow}
                        leftIcon={<BsEye style={{ fontSize: "20px" }} />}
                        colorScheme="white"
                        color="success.400"
                        onClick={viewPkg}
                      >
                        View
                      </Button>
                    </Flex>
                  </Box>
                ) : testSeries ? null : free ? (
                  <Flex width="100%" justify="space-around">
                    <Button
                      onClick={openModal}
                      leftIcon={<BiBadgeCheck style={{ fontSize: "20px" }} />}
                      color="green.500"
                      {...cardCtaBtnStyle}
                    >
                      Unlock
                    </Button>
                  </Flex>
                ) : itemInCart ? ( // Check is Item is Already In the Cart
                  <Flex width="100%" justify="space-around">
                    <Button
                      onClick={openModal}
                      leftIcon={<BiBadgeCheck style={{ fontSize: "20px" }} />}
                      color="primaryBlue.400"
                      {...cardCtaBtnStyle}
                    >
                      In Cart
                    </Button>
                  </Flex>
                ) : (
                  // If Not Already In the Cart
                  <Flex width="100%" justify="space-around">
                    {/* <Button
                      onClick={openModal}
                      leftIcon={
                        <MdAddShoppingCart style={{ fontSize: "20px" }} />
                      }
                      colorScheme="white"
                      style={{ color: "#DB4437" }}
                      {...cardCtaBtnStyle}
                    >
                      Add To Cart
                    </Button> */}

                    <Button
                      onClick={_buyNow}
                      leftIcon={
                        <MdAddShoppingCart style={{ fontSize: "20px" }} />
                      }
                      colorScheme="white"
                      color="success.400"
                    >
                      Buy Now
                    </Button>
                  </Flex>
                )}
              </Box>
            </>
          )}
        </VStack>
      </Box>
      <BuyNowModal
        visible={showDescription}
        closeModal={() => setShowDescription(false)}
        data={item}
      />
    </VStack>
  );
};

export const BuyNowModal = ({ visible, data, closeModal }) => {
  return (
    <Modal isOpen={visible} onClose={closeModal} width={200}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <DescriptionBox item={data} closeModal={closeModal} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const DescriptionBox = ({ item, closeModal }) => {
  const {
    addPackageToCart,
    isPackageInCart,
    isPackagePurchased,
    unlockPackage,
  } = useCart();
  const isPurchased = isPackagePurchased(item._id);
  const history = useHistory();

  const goToDetails = () => {
    history.push(ROUTES.PACKAGE_DETAILS + "?id=" + item._id);
  };

  const itemInCart = isPackageInCart(item._id);

  const handleCart = (item) => {
    if (itemInCart) {
      history.push("/cart");
    } else {
      addPackageToCart(item._id);
    }
  };

  const status = useSelector((s) => s.cart.getCartAddStatus);
  const free = !item.price || item.price === 0 || item.price === "0";

  return (
    <Box p="0.5rem">
      <Box width="100%">
        <Text fontSize="md" fontWeight="bold">
          {item?.name.en}
        </Text>
      </Box>

      <VStack width="100%">
        <HStack color="green.400" width="100%" fontSize="sm">
          <Text>Updated</Text>
          <Text fontWeight="bolder">
            {dateDecompose(item.updatedAt).month +
              " " +
              dateDecompose(item.updatedAt).year}
          </Text>
          <Spacer />
          <IconButton
            icon={<FiExternalLink />}
            variant="ghost"
            onClick={goToDetails}
          />
        </HStack>
        <HStack color="gray.500" width="100%" fontSize="xs">
          {item?.medium ? (
            <Text>{item.medium.capitalize()}&nbsp;|&nbsp;</Text>
          ) : null}
          {item?.mode ? (
            <Text>{item.mode.capitalize()}&nbsp;|&nbsp;</Text>
          ) : null}
          {item?.type ? <Text>{item.type.capitalize()}</Text> : null}
        </HStack>
        <Box width="100%" p="0.5rem 1rem">
          <Text
            fontSize="xs"
            color="#000000AA"
            noOfLines={10}
            dangerouslySetInnerHTML={{ __html: item.description.en }}
          />
        </Box>
        <HStack width="100%">
          {free ? (
            <Flex width="100%" justify="center" color="darkGreen">
              <Button
                leftIcon={<LockIcon style={{ fontSize: "20px" }} />}
                colorScheme="green"
                onClick={() => {
                  unlockPackage({ packages: [item._id] });
                  closeModal();
                }}
                _focus={{ boxShadow: "none" }}
              >
                Unlock
              </Button>
            </Flex>
          ) : isPurchased ? (
            <Flex width="100%" justify="center" color="darkGreen">
              <Button
                leftIcon={<GiCheckMark style={{ fontSize: "20px" }} />}
                variant="ghost"
                colorScheme="white"
                _focus={{ boxShadow: "none" }}
              >
                Package Purchased
              </Button>
            </Flex>
          ) : (
            <Button
              loadingText="Adding to Cart"
              isLoading={status === STATUS.FETCHING}
              colorScheme={itemInCart ? "yellow" : "green"}
              color={itemInCart ? "black" : "white"}
              fontWeight="bolder"
              width="100%"
              onClick={() => {
                handleCart(item);
              }}
            >
              {itemInCart ? "Go to Cart" : "Add To Cart"}
            </Button>
          )}
          {/* <IconButton
            variant="outline"
            colorScheme="red"
            icon={<AiOutlineHeart />}
            borderRadius="50%"
          /> */}
        </HStack>
      </VStack>
    </Box>
  );
};
