import { filter, flatMap, map, forEach, orderBy } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSubjContentAction } from "../../redux/reducers/courses";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { studentAttemptsAction } from "../../redux/reducers/packages";
import { SubjectsList } from "./AudioFiles";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { Box, Button, Divider, Flex, HStack, Heading, Spacer, Text, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { bilingualText } from "../../utils/Helper";
import { ExamWindow } from "../../Components/ExamWindow";
import { MultipleAnalysisModal } from "../../Components/MultipleAnalysisModal";
import { BsClipboardData } from "react-icons/bs";
import moment from "moment";
import { AiFillClockCircle, AiOutlineAreaChart, AiOutlineRightCircle, AiOutlineUnorderedList } from "react-icons/ai";
import { BiCalendarCheck } from "react-icons/bi";
import { FaScroll } from "react-icons/fa";
import { MdLeaderboard, MdOutlinePictureAsPdf, MdQuestionAnswer } from "react-icons/md";
import { SiAnsible } from "react-icons/si";
import { useState } from "react";
import { toggleExamWindowAction } from "../../redux/reducers/onlineExam";
import { find } from "lodash";
import { TEST_STATE } from "../../Constants";
import { size } from "lodash";


export const CourseTestListContent = ({ course }) => {
    
    const {subjectContent, studentAttemptsStatus} = useSelector((state) => ({
        subjectContent: state.course.subjectContent,
        studentAttemptsStatus: state.package.studentAttemptsStatus
    }))
    const params = useParams();
    const dispatch = useDispatch();

    const subjectId = params.subjectId;
    useEffect(() => {
        if(subjectId)
            dispatch(getSubjContentAction({id:subjectId, skipVideo: 1}))
    },[subjectId, dispatch])

    const testsData = useMemo( () => map(flatMap(filter(subjectContent, c => c.contentId === subjectId), t => map(t.tests, test => ({...test.data, chapterId: test.chapterId}) )), t => ({ ...t, test: t._id })), [subjectContent, subjectId])

    const { userData, attemptsData } = useSelector((s) => ({
        userData: s?.user?.user,
        attemptsData: s.package.attemptsData
      }));

    useEffect( () =>{
        if(testsData.length > 0){
            let data = { id: userData._id };
            forEach(testsData, (t, i) => {
                data[`testIds[${i}]`] = t._id;
            });
            dispatch(studentAttemptsAction(data));
        }
    },[userData, testsData])

    const chaptersData = useMemo( () => _.find(course.subjects,s => s.content._id === params.subjectId),[course, params.subjectId])

    const testList = useMemo( () => {
        return map(testsData, test => {
            return ({
                ...test, 
                userAttempts: filter(attemptsData, a => a.testId === test._id )
            });
        });
    },[testsData, attemptsData])
    return (
        <Flex pt={4}>
            <SubjectsList course={course} type='tests' selectedSubject={ (e) => {} }/>
            <Box w='75%' bg='white' ml={2} p={4}>
                <ErrorChecker status={studentAttemptsStatus}>
                {
                    chaptersData?.template?.chapters?.length ? 
                            orderBy(chaptersData.template.chapters, ['order'], ['asc']).map(ch => 
                                <Box pb={4} key={ch._id}>
                                    <HStack justify='space-between' p={2} boxShadow='0px 1px 2px #00000040' bg='white'>
                                        <Text fontSize='md'>{bilingualText(ch.chapterId.name)}</Text>
                                    </HStack>
                                    <Box pl={2} mt={2}>
                                        <CurrentTestList hideLeaderBoard testList={ testList } testsData={ filter(testsData, t => t.chapterId === ch.chapterId?._id)}/>
                                    </Box>
                                </Box>
                            )
                        : null
                }   
                <ExamWindow />  
                </ErrorChecker>
            </Box>
        </Flex>
    )
}


export const CurrentTestList = ({ testsData, startDate, endDate, testList, isOffline, hideLeaderBoard = false }) => {
  

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
  
    const [multipleAnalysisModal, changeMultipleAnalysisModal] = useState({
      modal: false,
      testData: "",
      attempts: [],
    });
    
    const _discussion = (test) => {
      history.push(
        "/dashboard/exam/discussion/test/" +
          test._id +
          (params.packageId != undefined ? "?pkgId=" + params.packageId : '')
      ); //_.last(test.userAttempts)._id)
    };
    const _leaders = (test) => {
      history.push(
        "/dashboard/exam/leaders/" + test._id + (params.packageId != undefined ? "?pkgId=" + params.packageId : '')
      ); //_.last(test.userAttempts)._id)
    };
  
    const viewAnalysis = (test) => {
      let attempts = _.orderBy(
        _.filter(test.userAttempts, (att) => att.progressStatus === "completed"),
        ["createdAt"],
        ["desc"]
      );
      if (attempts.length > 1) {
        changeMultipleAnalysisModal({
          modal: true,
          testData: test,
          attempts: attempts,
        });
      } else {
        history.push(
          "/dashboard/exam/analysis/" +
            test._id +
            "/" +
            _.head(attempts)._id
            + (params.packageId != undefined ? "?pkgId=" + params.packageId : '')
        ); //_.last(test.userAttempts)._id)
      }
    };
    const toast = useToast();
  
    const startExam = (test, attemptCheck) => {
      if (isOffline) {
        return toast({
          status: "error",
          title: "Offline Test",
          description:
            "You have applied for offline attempt, so you cannot attempt this test online.",
        });
      }
  
      localStorage?.removeItem("testId");
      localStorage?.removeItem("attemptId");
      localStorage.setItem("packageId", params.packageId);
  
      const attemptId = attemptCheck
        ? _.head(_.orderBy(test?.userAttempts, ["createdAt"], ["desc"]))._id
        : null;
      const attemptStatus = attemptCheck ? "Resume" : "Start";
  
      // history.push(`/exam/start/?testId=${test._id}&testAtemptId=${attemptId}&mode=${attemptStatus}`)

      dispatch(
        toggleExamWindowAction({
          newWindow: true,
          redirect: false,
          testId: test._id,
          attemptId,
          attemptStatus,
        })
      );
    };
  
    return (
        <VStack align='stretch'>
          {MultipleAnalysisModal.modal ? (
              <MultipleAnalysisModal
                testData={multipleAnalysisModal.testData}
                attempts={multipleAnalysisModal.attempts}
                visible={multipleAnalysisModal.modal}
                closeModal={() =>
                  changeMultipleAnalysisModal({
                    modal: false,
                    testData: "",
                    attempts: [],
                  })
                }
              />
            ) : null}
          {_.chain( testsData).map((t) => ({
                      ...t,
                      test: _.find(testList, (tst) => tst._id == t.test),
                    }))
            .orderBy("startDate", "desc")
            .map((test, i) => {
                const attempts = test.test?.userAttempts;
                const sizeAttempts = size(attempts);
                const isProgress = find(attempts, a => a.progressStatus === 'in-progress');
                const isAnalytics = find(attempts, a => a.progressStatus === 'completed');
                const canAttempt = sizeAttempts < (test.test.maxAttempts || 1);
                const isMultipleAttempts = sizeAttempts > 1;

              let attemptCheck =
                test.test?.userAttempts.length &&
                _.last(test.test?.userAttempts).progressStatus ==
                  "in-progress"
                  ? true
                  : false;
  
              const isResume = find(
                test?.test?.userAttempts,
                (a) => a.progressStatus === TEST_STATE.IN_PROGRESS
              );
              const firstAttempt =
                test?.test?.userAttempts && test.test.userAttempts[0];
              const isCompleted =
                firstAttempt &&
                firstAttempt.progressStatus === TEST_STATE.COMPLETED;
  
              const currentAttempt = isResume;
  
              let notAttempts =
                isCompleted &&
                test.test.maxAttempts &&
                test?.test?.userAttempts.length >=
                  test.test.maxAttempts;
  
              let lastCompleted = test.test?.userAttempts.length
                ? _.find(
                    test.test?.userAttempts,
                    (s) => s.progressStatus == "completed"
                  )
                : null;
              let isBefore =
                test.startDate &&
                moment().isBefore(moment(test.startDate));
              let isExpired =
                test.endDate &&
                moment().isAfter(moment(test.endDate));
              return (
                <HStack
                  key={i}
                  background="white"
                  boxShadow="sm"
                  borderWidth="1px"
                  borderRadius="lg"
                  minWidth="21.0vw"
                  px={4}
                  py={4}
                  // maxWidth="337px"
                >
                  <>
                        <Box flex={1}>
                            <Text mb={2} fontWeight='bold' size='md' noOfLines={1} maxW={200}>
                            {bilingualText(test.test?.name)}
                            </Text >
                            <HStack fontSize={14} color='gray.500'>
                                <HStack>
                                    <AiFillClockCircle />{" "}
                                    <Text>
                                        { "Duraton: " + (test.test?.totalTime || '') + " min"}
                                    </Text>
                                </HStack>
                                <HStack>
                                    <BiCalendarCheck />{" "}
                                    <Text>
                                    Attempts: {" "}
                                    { sizeAttempts }
                                    </Text>
                                </HStack>
                            </HStack>
                        </Box>
                  </>
                 <Box
                    p="1"
                  >
                    <Box display="flex"
                    alignItems="center">
                  {
                    isAnalytics ?
                    <>
                        {isAnalytics ? (
                        <Box mr="1" ml="1">
                            <Tooltip label="Test Analysis">
                            <Box
                                borderRadius="50%"
                                padding={1}
                                background="#EFF3F6"
                            >
                                <AiOutlineAreaChart
                                fontSize="28px"
                                cursor="pointer"
                                color="green"
                                onClick={() => viewAnalysis(test.test)}
                                />
                            </Box>
                            </Tooltip>
                        </Box>
                        ) : null}
                        {test?.test?.testOption?.discussion ? (
                        <Box mr="1" ml="1">
                            <Tooltip label="Doubt Community">
                            <Box
                                onClick={() => _discussion(test.test)}
                                borderRadius="50%"
                                padding={1}
                                background="#EFF3F6"
                            >
                                <MdQuestionAnswer
                                fontSize="28px"
                                cursor="pointer"
                                color="#4285F4"
                                />
                            </Box>
                            </Tooltip>
                        </Box>
                        ) : null}
                        {test.test?.questionPaper ? (
                        <Box mr="1" ml="1">
                            <Tooltip label="Question Paper">
                            <Box
                                borderRadius="50%"
                                padding={1}
                                background="#EFF3F6"
                                onClick={() =>
                                window.open(test.test.questionPaper)
                                }
                            >
                                <MdOutlinePictureAsPdf
                                fontSize="28px"
                                cursor="pointer"
                                color="#4E8DF1"
                                />
                            </Box>
                            </Tooltip>
                        </Box>
                        ) : null}
                        {test.test?.answerKey ? (
                        <Box mr="1" ml="1">
                            <Tooltip label="Answer Keys">
                            <Box
                                borderRadius="50%"
                                padding={1}
                                background="#EFF3F6"
                                onClick={() =>
                                window.open(test.test.answerKey)
                                }
                            >
                                <SiAnsible
                                fontSize="28px"
                                cursor="pointer"
                                color="#4E8DF1"
                                />
                            </Box>
                            </Tooltip>
                        </Box>
                        ) : null}
                        </>
                    : null
                    }
                    { isProgress ? (
                      <Tooltip
                        label={"Resume"}
                      >
                        <Button
                          size='sm'
                          colorScheme="blue"
                          variant="ghost"
                          borderRadius="0"
                          onClick={() =>
                            startExam(test.test, attemptCheck)
                          }
                        >
                            Resume
                        </Button>
                      </Tooltip>
                    )
                    : canAttempt ?
                          (
                            <Tooltip
                                label={"Start"}
                                >
                                <Button 
                                    size='sm'
                                    colorScheme="green"
                                    variant="ghost"
                                    borderRadius="0"
                                    onClick={() =>
                                        startExam(test.test, attemptCheck)
                                    }
                                >
                                    Start
                                </Button>
                            </Tooltip>
                        )
                    : null   
                    }
                    </Box>
                  </Box>
                </HStack>
              );
            })
            .value()}
        </VStack>
    )
  } 
  
  