import { Box, Heading } from "@chakra-ui/layout";
import {
  Button,
  Flex,
  Image,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { useQueryParams } from "../../utils/useQueryParams";

export const SuccessOrder = (props) => {
  let query = useQueryParams();

  let rpdata = query.get("rpdata");
  rpdata = JSON.parse(rpdata);

  const history = useHistory();
  if (!rpdata || !rpdata.orderId) {
    return <Redirect to={"/cart"} />;
  }
  return (
    <Box p={4}>
      <Box display="flex" mt={10} justifyContent="center">
        <Image
          w={["80%", "60%", "40%", "30%"]}
          style={{ aspectRatio: 1 }}
          src={require("../../Components/success.svg")}
        />
      </Box>
      <Flex w="100%" mt={20} flexDirection="column" alignItems="center">
        <Heading fontSize={"3xl"} color="green.500">
          Order Payment Success!
        </Heading>
        <Button
          onClick={() => {
            history.replace(ROUTES.ORDER);
          }}
          my={10}
          colorScheme="green"
        >
          Go To Orders
        </Button>
        <Text mt={4}>
          Your payment is success, Please go to order for details
        </Text>
        <Table width={["80%", "60%", "40%", "30%"]} variant="simple" my={4}>
          <TableCaption>Payment Details</TableCaption>
          <Tbody>
            {rpdata?.amount ? (
              <Tr>
                <Td>Amount</Td>
                <Td>RS. {rpdata.amount}</Td>
              </Tr>
            ) : null}

            {rpdata?.orderId ? (
              <Tr>
                <Td>Order Id</Td>
                <Td>{rpdata.orderId}</Td>
              </Tr>
            ) : null}

            {rpdata?.rPaymentId ? (
              <Tr>
                <Td>Payment Id Id</Td>
                <Td>{rpdata.rPaymentId}</Td>
              </Tr>
            ) : null}
          </Tbody>
        </Table>
      </Flex>
    </Box>
  );
};
