import { Box, HStack, Text } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
// import { Card } from '../Components/ItemCard'
import { ItemsSection } from '../../Components/ItemsSection'
import { getPackagesAction } from '../../redux/reducers/packages'
import { useQueryParams } from '../../utils/useQueryParams'

import {Empty} from '../../Components/Empty'
import { SectionHeader } from '../../Components/SectionHeader'

export const CurrentAffairs = () => {
  

    let query = useQueryParams();
  
    const {packages} = useSelector(state => ({
        packages: state.package.packagesList
    }))
    
    const filteredPackages = packages.filter((pkg)=>{
        return pkg.type === "MAGAZINE"
    })

    if(filteredPackages.length){
        return(
        <Box bg="#E3E6EC" p='2rem'>
            <SectionHeader breadcrumbs={[{title : "Home", link: "/"}, {title : "Current Affairs", link: "#"}]} />
            <Box p='4rem 2rem' marginY="4rem" boxShadow='lg' bg="white" borderRadius='lg' >
                <ItemsSection
                    items={filteredPackages}
                    title="CURRENT AFFAIRS"
                    showAll
                />
            </Box>
        </Box>
        )
    }else{
        return <Empty style={{height:'200px'}} title="Nothing Here" subtitle="Currently no Magazines are Available" ></Empty>
    }   
}
