import React, { useReducer, useState, useEffect, useCallback } from "react";
import { STATUS } from "../../App/Constants";
import {
  Box,
  Image,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Spacer,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
  useDisclosure,
  Badge,
  useToast,
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Center,
} from "@chakra-ui/react";
import {
  AiOutlineSolution
} from "react-icons/ai";
import { useAppContext, useIsAuthenticated } from "../../App/Context";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  ChevronDownIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import { useWindowSize } from "../../utils/useWindowSize";
import {
  AiOutlineLogin,
  AiOutlineLogout,
  AiOutlineBell,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { ROUTES } from "../../Constants/Routes";
import { BasicDetailsComponent } from "../../routes/BasicDetailsComponent";
import { Dropdown, ProfileDropdown } from "../ProfileDropdown";
import { useLoginModal } from "../../App/useLoginModal";
import { BiMenu } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { SideBarContent } from "../Sidebar";
import { mobileView, webView } from "../../utils/Helper";
import {LoginRoute} from '../../routes/Login'

export const CommonHeader = ({ pageContext, config }) => {
  // const [isWebDevice] = useMediaQuery("(min-width: 768px)");
  const isAuthenticated = useIsAuthenticated();
  const [width, height] = useWindowSize();
  const { logout } = useAppContext();
  const history = useHistory();
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  const { user } = useSelector((s) => ({
    user: s.user,
  }));

  const courses = config?.courseCategories;
  const tests = config?.testSeries;

  // const { colorMode, toggleColorMode } = useColorMode();

  const {
    modalType,
    setModalType,
    closeLoginModal,
    toggleLoginModal,
  } = useLoginModal();
  // const [modalType , setModalType] = useState(SIGNIN_MODAL_TYPE.SIGNIN)

  // const _couponLogin = useCallback(() => {
  //   setModalType(SIGNIN_MODAL_TYPE.COUPON_LOGIN);
  //   openLoginModal();
  // }, [openLoginModal, setModalType]);

  const _signInOut = () => {
    if (isAuthenticated) {
      logout();
    } else {
      setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
      toggleLoginModal();
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (modalType !== SIGNIN_MODAL_TYPE.COUPON_LOGIN) {
        closeLoginModal();
      }
     
    }
  }, [modalType, isAuthenticated, closeLoginModal]);

  const contact = ["7737106009","7737176009"]

  return (
    <Box>
     <Box {...mobileView}>
        <Box bg="white">
          <Box maxW='80em'>
            <HStack justifyContent="space-between">
              <Box w="33%">
                <MobileDrawer courses={courses} tests={tests} />
              </Box>
              <HStack py={3} w="33%" justifyContent="center">
                  <Image
                    w="40px"
                    src={"prayasEduhub.png"}
                    alt="Prayas"
                    cursor="pointer"
                  />
               
              </HStack>
              <HStack justifyContent="flex-end" w="33%">
                {isAuthenticated ? (
                  <HStack>
                    <Box>
                      <Box
                        pos="relative"
                        cursor="pointer"
                        onClick={() => history.push("/notifications")}
                      >
                        <Box color="brand.redAccent">
                          <AiOutlineBell fontSize="20px" />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {/* <Box
                        pos="relative"
                        onClick={() =>
                          history.push(
                            isAuthenticated ? ROUTES.CART : ROUTES.PUBLIC_CART
                          )
                        }
                        cursor="pointer"
                      >
                        {context?.cart?.packages?.length ? (
                          <Badge
                            position="absolute"
                            top={-2}
                            right={-1}
                            zIndex={9}
                            background="#38A169"
                            variant="solid"
                            fontSize="10px"
                          >
                            {context.cart.packages.length}
                          </Badge>
                        ) : null}

                        <Box color="brand.redAccent">
                          <AiOutlineShoppingCart
                            fontSize="20px"
                          /></Box>
                      </Box> */}
                    </Box>
                    <Box color="brand.redAccent">
                      <IconButton
                        onClick={_signInOut}
                        variant="ghost"
                        fontSize="20px"
                        icon={<AiOutlineLogout />}
                      />
                    </Box>
                  </HStack>
                ) : (
                  <Box color="brand.redAccent">
                    <IconButton
                      fontSize="20px"
                      onClick={_signInOut}
                      variant="ghost"
                      icon={<AiOutlineLogin />}
                    />
                  </Box>
                )}
              </HStack>
            </HStack>
          </Box>
          <SearchBarHeader contact={contact} width="100%" />
        </Box>
      </Box>
      <Box {...webView} color="white">
        <Center>
          <Box maxW="6xl" w="100%" py={2}>
            <Box
              pos="relative"
              backgroundColor='white'
              backgroundRepeat="no-repeat"
              h={"3.51vw"}
            >
              <HStack
                alignItems="center"
                justifyContent='space-between'
                wrap
              >
                <HStack
                  flexGrow='1'
                  w={"25%"}
                  style={{ verticalAlign: "middle" }}
                  align="center"
                  py={2}
                  spacing={4}
                >
                    <Image
                      w="3.0vw"
                      src={"/prayasEduhub.png"}
                      alt="Prayas-logo"
                      cursor="pointer"
                      onClick={() => history.push("/")}
                    />
                  <Box w="100%" pos='relative'>
                    {/* <DesktopNav data={navItems} /> */}
                  </Box>
                </HStack>
                <HStack w='fit' align="center">
                  <HStack wrap="wrap" spacing={6}>
                    {contact &&
                      contact.map((c) =>  (
                        <Flex
                          key={c}
                          align="center"
                          display={{ base: "none", md: "inline-flex" }}
                        >
                          <Image src="/contact.png" w="20px" alt="contact" />
                          <Text
                            ml={1}
                            fontSize="sm"
                            color="brand.redAccent"
                            fontWeight={"500"} mr={2}
                          >
                            {c}
                          </Text>
                        </Flex>
                      ))}
                    {user.user ?
                      (
                        <Box
                          display="inline-block"
                          paddingY='5px'
                          borderRadius={5}
                          color="brand.redAccent"
                          border='1px solid'
                          borderColor='brand.redAccent'
                        >
                          <ProfileDropdown />
                        </Box>
                      )
                      : <Button
                        fontSize={"sm"}
                        // borderRightRadius={user ? 0 : null}
                        fontWeight={600}
                        color={"white"}
                        colorScheme="redAccent"
                        bg={"brand.redAccent"}
                        size="sm"
                        // href={LMS_URL}
                        // target="_blank"
                        onClick={_signInOut}
                        isLoading={status === STATUS.FETCHING}
                        // rel="noreferrer"
                        p={5}
                        _hover={{
                          bg: "redAccent.900",
                        }}
                      >Sign In / Sign Up
                      </Button>
                    }
                  </HStack>
                </HStack>
              </HStack>
            </Box>
          </Box>
        </Center>
        <Center py={2} bg="brand.redAccentLight">
          <Flex
            maxW='6xl'
            justify={"space-between"}
            alignItems="center"
            p="2px"
            w='100%'
          >
            {width < 900 ? (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <HamburgerIcon
                      style={{
                        display: "inline-block",
                        fontSize: "1.75vw",
                        padding: "5px",
                      }}
                    />
                  }
                  size="xs"
                />
                <MenuList
                  style={{
                    backgroundColor: "#2c7de9",
                    border: "0px",
                    padding: "10px",
                  }}
                >
                  <MenuComponent
                    isAuthenticated={isAuthenticated}
                    hamburger={true}
                  />
                </MenuList>
              </Menu>
            ) : null}
            {width > 900 ? (
              <Box>
                <MenuComponent
                  isAuthenticated={isAuthenticated}
                  hamburger={false}
                  signInOut={_signInOut}
                />
              </Box>
            ) : null}
           
            <Center flexGrow={1} mx={3}>
              {/* <SearchBarHeader /> */}
            </Center>
            <Box display="flex" alignItems="center">
            {
              isAuthenticated &&
              <Button mr={8} size="sm" onClick={() => history.push("/doubts")} bg="brand.redAccent" _hover={{ bg: "brand.redAccent" }}>
                <AiOutlineSolution color="white" />
                <Text ml={1} style={{ color: "white" }} fontSize="sm">
                  Connect to Teacher
                </Text>
              </Button>
            }
              {/* <HStack
                style={{
                  color: "white",
                  fontSize: "",
                  borderRight: "1px solid #D6DBDF",
                  padding: "0px 10px",
                  cursor: "pointer",
                }}
                onClick={_couponLogin}
              >
                <Box color='brand.redAccent'>
                  <ImPriceTags fontSize="1.05vw" />
                </Box>
                <Text color="brand.redAccent" fontSize="sm">
                  Coupon Login
                </Text>
              </HStack> */}
              {isAuthenticated ? (
                <HStack ml={2} spacing={3}>
                  <Box>
                    <Box
                      pos="relative"
                      onClick={() => history.push("/dashboard/wishlist")}
                      cursor="pointer"
                    >
                      <Box color="brand.redAccent">
                        <AiOutlineHeart fontSize="1.52vw" />
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      pos="relative"
                      onClick={() => history.push("/notifications")}
                      cursor="pointer"
                    >
                      <Box color="brand.redAccent">
                        <AiOutlineBell fontSize="1.52vw" />
                      </Box>
                    </Box>
                  </Box>
                  {/* <Box>
                    <Box
                      pos="relative"
                      onClick={() =>
                        history.push(
                          isAuthenticated ? ROUTES.CART : ROUTES.PUBLIC_CART
                        )
                      }
                      cursor="pointer"
                    >
                      {context?.cart?.packages?.length ? (
                        <Badge
                          position="absolute"
                          top={-2}
                          right={-1}
                          zIndex={9}
                          background="#38A169"
                          variant="solid"
                          fontSize="10px"
                        >
                          {context.cart.packages.length}
                        </Badge>
                      ) : null}

                      <Box color="brand.redAccent">
                        <AiOutlineShoppingCart
                          fontSize="1.52vw"
                        /></Box>
                    </Box>
                  </Box> */}
                </HStack>
              ) : (
                <span
                  style={{
                    display: "inline-block",
                    color: "#717678",
                    fontSize: "",
                    padding: "",
                    cursor: "pointer",
                  }}
                  // onClick={_signInOut}
                >
                  {/* <Button
                    variant="ghost"
                    color="brand.redAccent"
                    fontWeight="normal"
                    leftIcon={
                      <IoPersonCircleOutline style={{ fontSize: "22px" }} />
                    }
                    _focus={{ boxShadow: "none" }}
                    fontSize="sm"
                  >
                    {isAuthenticated ? "Sign Out" : "Sign In"}
                  </Button> */}
                </span>
              )}
            </Box>
          </Flex>
        </Center>
        <SignInFlow />
        <CompleteProfilePopUp />
        {isOpen ? (
          <Dropdown isOpen={isOpen} user={user} close={onClose} />
        ) : null}
      </Box>
    </Box>
  );
};

function MobileDrawer({ courses, tests }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCourses, setShowCourses] = useState(false);
  const [showTestSeries, setShowTestSeries] = useState(false);
  const history = useHistory();
  //console.log(tests);
  function handleCourses() {
    if (showCourses) setShowCourses(false);
    else setShowCourses(true);
  }
  function handleTestSeries() {
    if (showTestSeries) setShowTestSeries(false);
    else setShowTestSeries(true);
  }
  const isAuthenticated = useIsAuthenticated();
  const btnRef = React.useRef();

  const { logout } = useAppContext();
  const { setModalType, toggleLoginModal } = useLoginModal();

  const _signInOut = () => {
    if (isAuthenticated) {
      logout();
    } else {
      setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
      toggleLoginModal();
    }
    onClose();
  };

  return (
    <>
      <IconButton
        colorScheme="url(/images/Header_1920.jpg)"
        icon={<HamburgerIcon />}
        onClick={onOpen}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader fontWeight="bold" color="gray.800" bgColor="gray.50">
            Prayas Career Institute
          </DrawerHeader>
          <Divider />
          {!isAuthenticated ? (
            <DrawerBody>
              {isAuthenticated ? (
                <Box
                  onClick={() => {
                    history.push("/dashboard/courses");
                  }}
                  py={2}
                >
                  Dashboard
                </Box>
              ) : null}
              <HStack py={2} onClick={handleCourses}>
                <b>Courses </b> <Spacer /> <ChevronDownIcon fontSize="20px" />
              </HStack>
              <Flex direction="column">
                {showCourses
                  ? courses.map((course, index) => {
                      return (
                        <Text
                          onClick={() => {
                            history.push(
                              ROUTES.PACKAGES +
                                "?id=" +
                                course._id +
                                "&name=" +
                                course.name.en
                            );
                            onClose();
                          }}
                          fontSize="14px"
                          pt={2}
                          key={index}
                        >
                          {course.name.en}
                        </Text>
                      );
                    })
                  : null}
              </Flex>
              <HStack py={2} onClick={handleTestSeries}>
                {" "}
                <b>Test Series</b> <Spacer />{" "}
                <ChevronDownIcon fontSize="20px" />
              </HStack>
              <Flex direction="column">
                {showTestSeries
                  ? tests.map((test, index) => {
                      return (
                        <Text
                          onClick={() => {
                            history.push(
                              ROUTES.PACKAGES +
                                "?id=" +
                                test._id +
                                "&name=" +
                                test.name.en
                            );
                            onClose();
                          }}
                          fontSize="14px"
                          pt={2}
                          key={index}
                        >
                          {test.name.en}
                        </Text>
                      );
                    })
                  : null}
              </Flex>
              {/* <Box
                py={2}
                onClick={() => {
                  history.push(ROUTES.CURRENT_AFFAIRS);
                  onClose();
                }}
              >
                <b>Current Affairs</b>
              </Box> */}
              {/* <Box
                py={2}
                onClick={() => {
                  history.push(ROUTES.BOOKS);
                  onClose();
                }}
              >
                <b>Book Store</b>
              </Box> */}
              <Box py={2} onClick={_signInOut}>
                {isAuthenticated ? "Sign Out" : "Sign In"}
              </Box>

              {
                //<Box py={2}><b>Help &amp; Support</b></Box>
              }
            </DrawerBody>
          ) : (
            <DrawerBody>
            {
              isAuthenticated &&
              <Button size="sm" onClick={() => history.push("/doubts")} colorScheme="yellow">
              <AiOutlineSolution color="white"/>
                    <Text ml={1} style={{ color: "white" }} fontSize="sm">
                      Connect to Teacher
                    </Text>
              </Button>
            }
              <SideBarContent />
            </DrawerBody>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}

const MenuComponent = ({
  isAuthenticated,
  courses,
  tests,
  signInOut,
  hamburger,
}) => {
  const history = useHistory();
  const toast = useToast();
  //console.log("hamburger",hamburger)
  //hamburger = true
  const handleSignIn = () => {
    toast({
      title: "Sign In to Continue.",
      position: "top",
      variant: "subtle",
      status: "info",
      duration: 2500,
      isClosable: true,
    });

    signInOut();
  };

  const location = useLocation();
  const [openMenu, changeOpenMenu] = useState();
  const _openMenu = () => {
    //console.log("In OpenMenu")
    changeOpenMenu(!openMenu);
    if (openMenu) $("#sidebar").animate({ width: 0 });
    else $("#sidebar").animate({ left: "250px", width: "20%" });
  };

  // useEffect(() => {
  //   // changeOpenMenu(false);
  //   $("#sidebar").animate({ width: 0 });
  // }, []);

  return (
    <HStack spacing={7}>
      {isAuthenticated ? (
        <Menu>
          <MenuButton
            className="menuButton"
            fontSize="xs"
            // onClick={openMenu}
            onClick={_openMenu}
          >
            <BiMenu fontSize="2.2vw" color="#4285F4" />
          </MenuButton>
        </Menu>
      ) : null}
      {/* <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => history.push("/")}
        >
          HOME
        </MenuButton>
      </Menu> */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {/* {isAuthenticated ? (
        <Menu>
          <MenuButton
            fontSize="xs"
            style={{ color: "#717678" }}
            _hover={menuButtonHoverStyle}
            onClick={() => history.push("/dashboard")}
          >
            DASHBORAD
          </MenuButton>
        </Menu>
      ) : null} */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {courses ? (
        <Menu style={{ backgroundColor: "grey" }}>
          <MenuButton
            fontSize="xs"
            style={{ color: "#717678" }}
            _hover={menuButtonHoverStyle}
          >
            COURSES {<ChevronDownIcon fontSize="20px" />}{" "}
          </MenuButton>
          <MenuList>
            {courses.map((course) => (
              <MenuItem
                key={course._id}
                color="#717678"
                fontSize="sm"
                onClick={() =>
                  history.push(
                    ROUTES.PACKAGES +
                      "?id=" +
                      course._id +
                      "&name=" +
                      course.name.en
                  )
                }
              >
                {course.name.en}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {tests ? (
        <Menu>
          <MenuButton
            fontSize="xs"
            style={{ color: "#717678" }}
            _hover={menuButtonHoverStyle}
          >
            TEST SERIES {<ChevronDownIcon fontSize="20px" />}{" "}
          </MenuButton>
          <MenuList>
            {tests.map((test) => (
              <MenuItem
                color="#717678"
                fontSize="sm"
                key={test._id}
                onClick={() =>
                  history.push(
                    ROUTES.PACKAGES +
                      "?id=" +
                      test._id +
                      "&name=" +
                      test.name.en
                  )
                }
              >
                {test.name.en}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {/* <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => history.push(ROUTES.CURRENT_AFFAIRS)}
        >
          CURRENT AFFAIRS
        </MenuButton>
      </Menu> */}
      {/* {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => history.push(ROUTES.BOOKS)}
        >
          BOOKS STORE
        </MenuButton>
      </Menu> */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {/* <Menu>
                <MenuButton fontSize="xs" style={{paddingRight: '20px', color: 'white'}} _hover={menuButtonHoverStyle}>PACKAGES{<ChevronDownIcon />} </MenuButton>
                <MenuList>{packages.map((pkg,i)=><MenuItem key={i} >{pkg.name}</MenuItem>)}</MenuList>
            </Menu> */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {/* <Menu>
                <MenuButton fontSize="xs" style={{paddingRight: '20px', color: 'white'}} _hover={menuButtonHoverStyle}>DOWNLOAD</MenuButton>
            </Menu> */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
      {/* <Menu>
                <MenuButton fontSize="xs" style={{paddingRight: '20px', color: 'white'}} _hover={menuButtonHoverStyle}>BLOG</MenuButton>
            </Menu> */}
      {hamburger ? (
        <hr style={{ paddingTop: "5px", paddingBottom: "3px" }} />
      ) : null}
     {/*
      <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => window.open(CUSTOM_ROUTES.JOBS,"_")}
        >
          JOBS
        </MenuButton>
      </Menu>
      <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => window.open(CUSTOM_ROUTES.RESULTS,"_")}

         
        >
          RESULTS
        </MenuButton>
      </Menu>
      <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => window.open(CUSTOM_ROUTES.CAREERS,"_")}

         
        >
          CAREERS
        </MenuButton>
      </Menu>
        */}
      {/* <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => window.open(CUSTOM_ROUTES.DOWNLOADS,"_")}

         
        >
          DOWNLOADS
        </MenuButton>
      </Menu> */}
      {/* <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() => window.open(CUSTOM_ROUTES.BLOGS,"_")}

        >
          BLOGS
        </MenuButton>
      </Menu> */}
      <Menu>
        <MenuButton
          fontSize="xs"
          style={{ color: "#717678" }}
          _hover={menuButtonHoverStyle}
          onClick={() =>
            isAuthenticated
              ? history.push(ROUTES.HELP_AND_SUPPORT)
              : handleSignIn()
          }
        >
          HELP &amp; SUPPORT
        </MenuButton>
      </Menu>
    </HStack>
  );
};

const menuButtonHoverStyle = {
  color: "white",
};

export const SIGNIN_MODAL_TYPE = {
  SIGNIN: "signin",
  COUPON_LOGIN: "coupon",
};

export const SignInFlow = () => {
  const { modalType, isLoginModalOpen, toggleLoginModal } = useLoginModal();
  console.log("isLoginModalOpen",isLoginModalOpen)
  return (
    <Modal size="xl" isOpen={isLoginModalOpen} onClose={toggleLoginModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {modalType === SIGNIN_MODAL_TYPE.COUPON_LOGIN
            ? "Coupon Login"
            : "Sign In"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <LoginRoute />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const CompleteProfilePopUp = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isConfirmed, setIsConfirmed] = useState(null);

  const { user } = useSelector((s) => ({
    user: s.user.user,
  }));

  useEffect(() => {
    if (user && !user.confirmed) {
      setIsConfirmed(false);
      onOpen();
    } else {
      setIsConfirmed(true);
      onClose();
    }
  }, [onClose, onOpen, user]);
  
  const toast = useToast();

  const handleModalClose = () => {
    if (isConfirmed) {
      onClose();
    } else {
      toast.closeAll();
      toast({
        title: "Please Complete your Profile first.",
        position: "top",
        variant: "subtle",
        status: "warning",
        duration: 2500,
        isClosable: false,
      });
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} onClose={handleModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Complete Your Profile</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <BasicDetailsComponent />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const SearchBarHeader = ({ width }) => {
  const history = useHistory();
  const [searchData, changeSearchData] = useState("");
  const [searchType, changeSearchType] = useState("all");

  const search = (e) => {
    e.preventDefault();
    history.push(
      ROUTES.PACKAGES + `?search=${searchData}&searchType=${searchType}`
    );
  };

  // const [isSmallDevice] = useMediaQuery("(max-width: 48em)");

  // if (isSmallDevice) {
  //   return (
  //     <Box key="small" p={2} bg="gray.50" width={width} display={"inline"}>
  //       <form onSubmit={search} width={width}>
  //         <Box>
  //           <InputGroup>
  //             <InputLeftElement
  //               pointerEvents="none"
  //               children={<SearchIcon color="gray.300" />}
  //             />
  //             <Input
  //               background="white"
  //               color="black"
  //               borderRadius="full"
  //               placeholder="What do you want to learn"
  //               onChange={(e) => changeSearchData(e.target.value)}
  //               // borderRightRadius={2}
  //             />
  //           </InputGroup>
  //         </Box>
  //       </form>
  //     </Box>
  //   );
  // }
  return (
    <Box w={width} p={[3, 3, 3, 0]} bg={["gray.50", "gray.50", "transparent"]}>
      <form onSubmit={search}>
        {/* <InputLeftAddon style={{paddingRight: '0px'}}>
                    <Menu style={{backgroundColor: 'lightGrayBlue', color: "text.100", border: '0px'}}>
                        <MenuButton style={{color: "text.100"}}>Exam &nbsp;&nbsp;<ChevronDownIcon/>&nbsp;&nbsp;</MenuButton>
                        <Portal>
                            <MenuList>
                            <MenuItem>Exam</MenuItem>                           
                            </MenuList>
                        </Portal>
                    </Menu>
                </InputLeftAddon> */}
      
      </form>
      {/* <Box {...mobileView} p={3} mt={2} textAlign="center">
        <HStack fontSize="xs">
          <PhoneIcon color="#13F0FA" />
          <Text color="gray.500">IT Help : {CONTACT_IT_HELP}</Text>{" "}
          <Text color="gray.500">Other : {CONTACT_OTHER}</Text>{" "}
        </HStack>{" "}
      </Box> */}
    </Box>
  );
};
