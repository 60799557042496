import { Box, Center, Flex, Heading, VStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Spacer,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { BsStarFill, BsStar } from "react-icons/bs";
import { FaTrash, FaEdit } from "react-icons/fa";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Divider,
  Radio,
  RadioGroup,
  VisuallyHidden,
  Textarea,
  IconButton,
  Avatar,
  AvatarBadge,
  AvatarGroup,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  addReviewAction,
  delReviewAction,
  getReviewAction,
} from "../../redux/reducers/packages";
import { useIsAuthenticated } from "../../App/Context";
import { STATUS } from "../../App/Constants";
import _ from "lodash";

const PackageReviews = ({ currentPkg }) => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentUserReview, setCurrentUserReview] = useState();
  const isAuthenticated = useIsAuthenticated();

  const { user } = useSelector((s) => ({
    user: s.user.user,
  }));
  const { reviews, delReviewStatus, getReviewStatus, addReviewStatus } =
    useSelector((state) => ({
      reviews: state.package.reviewsList,
      delReviewStatus: state.package.delReviewStatus,
      getReviewStatus: state.package.getReviewStatus,
      addReviewStatus: state.package.addReviewStatus,
    }));

  useEffect(() => {
    if (
      getReviewStatus === STATUS.SUCCESS ||
      addReviewStatus === STATUS.SUCCESS
    ) {
      reviews?.forEach((el) => {
        if (user?._id === el?.user?._id) {
          //console.log("list aayi tu aaya");
          setCurrentUserReview(el);
        }
      });
    }
  }, [
    addReviewStatus,
    getReviewStatus,
    isAuthenticated,
    currentUserReview,
    reviews,
  ]);

  useEffect(() => {
    dispatch(getReviewAction({ packageId: currentPkg._id }));
  }, [addReviewStatus, delReviewStatus]);

  useEffect(() => {
    if (delReviewStatus === STATUS.SUCCESS) {
      //console.log('delete kiwajah se hua h')
      setCurrentUserReview();
    }
  }, [delReviewStatus]);

  const toTitleCase = (str = " ") => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const dateFormat = (reviewDate) => {
    reviewDate = new Date(reviewDate);
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][reviewDate.getMonth()];
    return month + " " + reviewDate.getDay() + " , " + reviewDate.getFullYear();
  };

  //not using currently
  const deleteReview = () => {
    dispatch(delReviewAction({ reviewId: currentUserReview?._id }));
  };
  return (
    <Box
      flex={3}
      background="white"
      color="#78909C"
      borderRadius={8}
      p={[2, 8]}
    >
      <Text color="#263238" fontWeight={600} fontSize="xl">
        Reviews{" - "}
        {reviews?.[0]?.packageId?.rating ? reviews[0].packageId.rating : 0}
      </Text>
      {/* <br /> */}
      {/* <VStack direction="column">
        <HStack>
          <Heading fontSize="5xl" color="#263238">
            
          </Heading>
        </HStack>
        <HStack spacing={2}>
          {fillStars(reviews?.[0]?.packageId?.rating)}
        </HStack>
        <Flex>
          <Text pt={2}>
            based on {reviews?.length}{" "}
            {reviews?.length > 1 ? "reviews" : "review"}
          </Text>
        </Flex>
      </VStack> */}
      <br />
      <Divider />
      <br />
      {isAuthenticated ? (
        currentUserReview ? (
          <Flex direction="column">
            <b>
              <Text>MY REVIEW</Text>
            </b>
            <Flex direction="row">
              <Flex direction="row" ml={2} mr={2} mb={4} mt={2}>
                <Avatar mt={1} name={currentUserReview?.user?.name} src="" />
                <Flex ml={2} direction="column">
                  <HStack direction="row" spacing={5} alignItems="baseline">
                    <Text fontSize="" color="#263238">
                      {currentUserReview?.user?.name}
                    </Text>
                  </HStack>
                  <Flex flexWrap="wrap">
                    <HStack spacing={1} pr={3}>
                      {fillStars(currentUserReview?.rating, 14)}
                    </HStack>
                    <Text fontSize={14}>
                      {dateFormat(
                        currentUserReview?.createdAt.slice(
                          0,
                          currentUserReview?.createdAt.indexOf("T")
                        )
                      )}
                    </Text>
                  </Flex>

                  <Box mt={2}>
                    <Text>{currentUserReview?.review}</Text>
                  </Box>
                </Flex>
              </Flex>
              <Spacer />
              <Tooltip label="edit review">
                <IconButton
                  variant="ghost"
                  onClick={onOpen}
                  icon={<FaEdit fontSize={22} />}
                />
              </Tooltip>
              {/* <IconButton onClick={deleteReview} colorScheme='red' variant='ghost' icon={<FaTrash />} /> */}
            </Flex>
          </Flex>
        ) : (
          <Flex alignItems={["center", "flex-end"]} direction="column">
            <Box p={2}>
              <Button onClick={onOpen} size="sm" colorScheme={"blue"}>
                Write a Review
              </Button>
            </Box>
          </Flex>
        )
      ) : null}
      <br />
      {isAuthenticated ? (
        <Flex direction="column">
          <b>
            <Text>REVIEWS</Text>
          </b>
          {reviews?.map((el) => {
            return user?._id === el?.user?._id ? (
              <></>
            ) : (
              <>
                <Flex direction="row" ml={2} mr={2} mb={4} mt={2}>
                  <Avatar mt={1} name={el?.user?.name} src="" />
                  <Flex ml={2} direction="column">
                    <HStack direction="row" spacing={5} alignItems="baseline">
                      <Text color="#263238">{el?.user?.name}</Text>
                    </HStack>
                    <Flex flexWrap="wrap">
                      <HStack spacing={1} pr={3}>
                        {fillStars(el?.rating, 14)}
                      </HStack>
                      <Text fontSize={14}>
                        {dateFormat(
                          el?.createdAt.slice(0, el?.createdAt.indexOf("T"))
                        )}
                      </Text>
                    </Flex>
                    <Box mt={2}>
                      <Text>{el?.review}</Text>
                    </Box>
                  </Flex>
                </Flex>
                <Divider />
              </>
            );
          })}
        </Flex>
      ) : (
        <Flex direction="column">
          <b>
            <Text>REVIEWS</Text>
          </b>
          {reviews?.map((el) => {
            // console.log("el.updatedAt", el.updatedAt)
            return (
              <>
                <Flex direction="row" ml={2} mr={2} mb={4} mt={2}>
                  <Avatar mt={1} name={el?.user?.name} src="" />
                  <Flex ml={2} direction="column">
                    <HStack direction="row" spacing={5} alignItems="baseline">
                      <Text color="#263238">{el?.user?.name}</Text>
                    </HStack>
                    <Flex flexWrap="wrap">
                      <HStack spacing={1} pr={3}>
                        {fillStars(el?.rating, 14)}
                      </HStack>
                      <Text fontSize={14}>
                        {dateFormat(
                          el?.createdAt.slice(0, el?.createdAt.indexOf("T"))
                        )}
                      </Text>
                    </Flex>
                    <Box mt={2}>
                      <Text>{el?.review}</Text>
                    </Box>
                  </Flex>
                </Flex>
                <Divider />
              </>
            );
          })}
        </Flex>
      )}
      <ReviewModal
        name={currentPkg.name.en}
        id={currentPkg._id}
        currentUserReview={currentUserReview}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export const fillStars = (rate, size) => {
  let stars = [];
  for (let filledStars = 0; filledStars < _.round(rate); filledStars++) {
    stars.push(<BsStarFill size={size || 24} color="#F5BE18" />);
  }
  for (let emptyStars = 0; emptyStars < 5 - _.round(rate); emptyStars++) {
    stars.push(<BsStarFill size={size || 24} color="#E7E7E7" />);
  }

  return stars;
};

function ReviewModal({ name, id, isOpen, onClose, currentUserReview }) {
  const dispatch = useDispatch();
  const [rating, setRating] = useState();
  const [hover, setHover] = useState(null);
  const initialRef = useRef();

  const { addReviewStatus } = useSelector((state) => ({
    addReviewStatus: state.package.addReviewStatus,
  }));

  console.log({ addReviewStatus });
  useEffect(() => {
    if (addReviewStatus === STATUS.SUCCESS) onClose();
  }, [addReviewStatus, onClose]);

  useEffect(() => {
    setRating(currentUserReview?.rating);
  }, [currentUserReview]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = {};
    const formData = new FormData(event.target);
    formData.append("packageId", id);
    for (const pair of formData.entries()) {
      data[pair[0]] = pair[1];
    }

    dispatch(addReviewAction(data));
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="2xl"
        onClose={onClose}
        initialFocusRef={initialRef}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review: {name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit}>
              <FormControl isRequired>
                <FormLabel>Review:</FormLabel>
                <Textarea
                  defaultValue={currentUserReview?.review}
                  name="review"
                  ref={initialRef}
                  placeholder="How was your experience with the product"
                  rows={6}
                />
              </FormControl>
              <br />
              <FormControl display="flex" flexDir="row" isRequired>
                <FormLabel>Rating :</FormLabel>
                <HStack spacing={2} direction="row">
                  {[...Array(5)].map((star, index) => {
                    const ratingValue = index + 1;
                    return (
                      <label>
                        <input
                          type="radio"
                          style={{ display: "none" }}
                          name="rating"
                          value={ratingValue}
                          onClick={() => setRating(ratingValue)}
                        />
                        <BsStarFill
                          cursor="pointer"
                          style={{ transition: "color 1000ms" }}
                          size="30"
                          color={
                            ratingValue <= (hover || rating)
                              ? "#ffc107"
                              : "#e4e5e9"
                          }
                          onMouseEnter={() => setHover(ratingValue)}
                          onMouseLeave={() => setHover(null)}
                        />
                      </label>
                    );
                  })}
                </HStack>
              </FormControl>
              <ModalFooter>
                <Button
                  type="submit"
                  isLoading={addReviewStatus === STATUS.FETCHING}
                  colorScheme="green"
                  mr={3}
                >
                  Save
                </Button>
                <Button variant="outline" onClick={onClose} colorScheme="blue">
                  Close
                </Button>
              </ModalFooter>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PackageReviews;
