import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { STATUS } from "../../../App/Constants"
import { apis } from '../../../services/api/apis'
import _ from 'lodash'

export const getInstaPost = createAsyncThunk(
    'get/instaPost',
    async (payload, thunkAPI) => {
        const response = await apis.getInstaPost(payload)
        const { ok, problem, data } = response

        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)


let initialState = {
    instaPosts: [],
    getInstaPostStatus: STATUS.NOT_STARTED
}

const instaSlice = createSlice({
    name: 'insta',
    initialState,
    reducers: {
    },
    
    extraReducers: {
        [getInstaPost.pending]: (state) => {
            state.getInstaPostStatus = STATUS.FETCHING
        },
        [getInstaPost.fulfilled]: (state, action) => {
            state.getInstaPostStatus = STATUS.SUCCESS;
            state.instaPosts = action.payload;
        },
        [getInstaPost.rejected]: (state) => {
            state.getInstaPostStatus = STATUS.FAILED
        },
    }
}
    )

export const { resetFormStatus } = instaSlice.actions
export const instaReducer = instaSlice.reducer