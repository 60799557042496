import { Box, Flex, HStack, Center, Button, Spinner, Text } from '@chakra-ui/react'
import { filter, map, orderBy } from 'lodash'
import { useEffect } from 'react'
import { AiFillInstagram } from 'react-icons/ai'
import { BsFacebook, BsTwitter } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FromBottomAnimation } from '../common/animation'
import ContainerX from '../common/containerX'
import HeadingX from '../common/headingX'
import SliderCarousel from '../common/slider'
import { STATUS } from '../Constants'
import { getWebsiteStaff } from '../redux/reducers/doubt'
import { chunk } from 'lodash'

export default function Instructors(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWebsiteStaff())
    }, [dispatch])

    const { websiteStaffs, getWebsiteStaffStatus } = useSelector(s => ({
        websiteStaffs: s.doubts.websiteStaffs,
        getWebsiteStaffStatus: s.doubts.getWebsiteStaffStatus
    }))
    return (
        <Box>
            {websiteStaffs?.length ?
                <Box py={20}>
                    <HeadingX textAlign='center'>
                        Expert instructors
                    </HeadingX>
                    <FromBottomAnimation>
                        <Flex display={{ base: "none", sm: "initial" }}>
                            <ContainerX mt={10}>
                                <Box>
                                    {getWebsiteStaffStatus === STATUS.FETCHING ?
                                        <Spinner size={"2xl"} />
                                        :
                                        websiteStaffs?.length ?
                                            <SliderCarousel slidesToScroll={4} dotesStyle={{ bottom: "-40px", pb: "10px" }}>
                                                {map(chunk(orderBy(filter(websiteStaffs, s => s.user.isActive === true), ("createdAt", "priority"), "asc"), 4), (staffs, i) => (
                                                    <Box>
                                                        <Flex key={i} flexWrap={"wrap"}>
                                                            {map(staffs, staff => (
                                                                <Instructor w={{ sm: "50%", lg: "25%" }} key={staff._id} staff={staff} />
                                                            ))}
                                                        </Flex>
                                                    </Box>
                                                ))}
                                            </SliderCarousel>
                                            :
                                            null
                                    }
                                </Box>
                                {websiteStaffs.length > 4 ?
                                    < Center mt={20}>
                                        <Link to='/about-us'><Button size='lg' variant='outline' colorScheme='default'>View all</Button></Link>
                                    </Center>
                                    :
                                    null
                                }
                            </ContainerX>
                        </Flex>
                        <Flex display={{ base: "initial", sm: "none" }}>
                            <ContainerX>
                                <SliderCarousel dotesStyle={{ bottom: "-40px", pb: "10px" }}>
                                    {getWebsiteStaffStatus === STATUS.FETCHING ?
                                        <Spinner size={"2xl"} />
                                        :
                                        websiteStaffs?.length ?
                                            map(orderBy(filter(websiteStaffs, s => s.user.isActive === true), ("createdAt", "priority"), "asc"), (staff, i) => (
                                                i < 4 ?
                                                    <Instructor key={i} staff={staff} />
                                                    :
                                                    null
                                            ))
                                            :
                                            null
                                    }
                                </SliderCarousel>
                            </ContainerX>
                        </Flex>
                    </FromBottomAnimation>
                </Box>
                :
                null
            }
        </Box >
    )
}

export const Instructor = ({ w, key, staff }) => {
    return (
        <Box key={key} w={w || { base: "100%", sm: "50%", md: '33%', lg: "25%" }} px={{ base: 1, lg: 1 }} py={4}>
            <Flex flexDir='column' borderRadius={10} overflow='hidden' border='1px solid' borderColor='gray.200' h='full' w='100%'>
                <Box h={{ base: 250, lg: 300 }} pos='relative' w={'100%'} backgroundColor='gray.400' backgroundImage={staff.user.avatar ? `url(${staff?.user?.avatar})` : "url('/assets/default-user-image.png')"} backgroundPosition='center' backgroundSize='cover'>
                    {/* <SocialLinks /> */}
                </Box>
                <Box py={8} px={2} grow={1} align="center">
                    <Text fontSize="xl">{staff?.user?.name}</Text>
                    <Box color='gray.500' fontSize={"sm"}><pre>{staff?.staffDesc}</pre></Box>
                </Box>
            </Flex>
        </Box>
    )
}

const SocialLinks = () => {
    return (
        <HStack spacing={1} right={0} pos='absolute' bottom={0} transform='translateY(50%)'>
            <SocialLink icon={<BsTwitter />} />
            <SocialLink icon={<AiFillInstagram />} />
            <SocialLink icon={<BsFacebook />} />
        </HStack>
    )
}

const SocialLink = ({ icon }) => {
    return (
        <Center fontSize={20} color='white' _hover={{ color: 'default.500' }} w={10} h={10} borderRadius='50%' bg='gray.700'>
            {icon}
        </Center>
    )
}