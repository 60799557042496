import React from "react";
import { BiTrendingUp } from "react-icons/bi";
import { HiOutlineNewspaper } from "react-icons/hi";
import { ImBooks } from "react-icons/im";
import { MdLocalOffer, MdOutlineLocalOffer } from "react-icons/md";
import { Flex, Box, Center, Heading, HStack, Text, Image } from "@chakra-ui/react";
import { FromBottomAnimation } from "../common/animation";

export default function HeaderBottom(props) {
  return (
    <>
      <Flex bg="gray.700" flexWrap={"wrap"} py={{ base: 10, md: 0 }}>
        <SingleCard
          id="courses"
          icon={<BiTrendingUp />}
          py={{ base: 5, md: 10, lg: 20 }}
          hover={{ bg: "default.400" }}
          heading="Trending course"
          desc="we provide Video lectures for the courses (available on our channel/App/Website) at an affordable price. So start learning with the export of the field from the comfort of your home."
        />
        <SingleCard
          id="test-series"
          icon={<HiOutlineNewspaper />}
          py={{ base: 5, md: 10, lg: 20 }}
          hover={{ bg: "default.400" }}
          heading="Test series"
          desc="You can evaluate your level of preparation by participating in live tests, along with solutions substantiated by proper explanations."
        />
        <SingleCard
          id="book-library"
          icon={<ImBooks />}
          py={{ base: 5, md: 10, lg: 20 }}
          hover={{ bg: "default.400" }}
          heading="Books & library"
          desc="We provide books on all Subjects at an affordable price, which will help the student throughout their preparation journey. Do check it out."
        />
        <SingleCard
          id="offer-discount"
          icon={<MdOutlineLocalOffer />}
          py={{ base: 5, md: 10, lg: 20 }}
          hover={{ bg: "default.400" }}
          heading="Offers"
          desc="Don't miss the chance! We are providing discounts and free test series with courses."
        />
      </Flex>
      <Flex mt={-1} pb={3} bg="gray.700" color="white" display={{ base: "flex", md: "none" }} px={[3, 10]} justifyContent="space-between" align="center">
        <Text fontSize={{ base: "lg", sm: "2xl" }} fontWeight="bold">Download Our App</Text>
        <Image
          w="45%"
          h={{ base: "65px", sm: "80px" }}
          src="assets/google-play.png"
          alt=""
          cursor="pointer"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.prayascareerinstitute"
            )
          }
        />
      </Flex>
    </>
  );
}
export const SingleCard = ({
  width,
  icon,
  py,
  hover,
  heading,
  desc,
  id,
  align = "center",
}) => {
  return (
    <Box
      _hover={hover}
      w={width || { base: "50%", md: "25%" }}
      px={{ base: 2, md: 4 }}
      py={py || 10}
      onClick={() =>
        document.getElementById(id).scrollIntoView({ behavior: "smooth" })
      }
      cursor="pointer"
      align="center"
    >
      <FromBottomAnimation>
        <Center>
          <Center
            fontSize={25}
            bg="white"
            color="default.500"
            w={14}
            h={14}
            borderRadius="50%"
          >
            {icon}
          </Center>
        </Center>
        {heading ?
          <Heading size={"md"} textAlign={align} color="white" mt={4}>
            {heading}
          </Heading>
          :
          null
        }
        {desc ?
          <Box textAlign={align} mt={4} display={{ base: "none", md: "initial" }} fontSize="sm" color="white">
            {desc}
          </Box>
          :
          null
        }
      </FromBottomAnimation>
    </Box>
  );
};

export const SingleCardH = ({
  width,
  icon,
  py,
  hover,
  heading,
  desc,
  id,
  align = "center",
}) => {
  return (
    <Box
      _hover={hover}
      w={width || { base: "50%", md: "25%" }}
      px={4}
      py={py || 10}
      onClick={() =>
        document.getElementById(id)?.scrollIntoView({ behavior: "smooth" })
      }
      cursor="pointer"
    >
      <FromBottomAnimation>
        <HStack>
          <Center
            bg="white"
            color="default.500"
            borderRadius="50%"
            w="60px"
            h="60px"
          >
            {icon}
          </Center>

          <Box flex={1}>
            <Heading size={"md"} textAlign={align} color="white" mt={4}>
              {heading}
            </Heading>
            <Box align="justify" mt={4} fontSize="sm" color="white">
              {desc}
            </Box>
          </Box>
        </HStack>
      </FromBottomAnimation>
    </Box>
  );
};
