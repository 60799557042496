import { Box } from '@chakra-ui/react'
import React from 'react'

export default function VimeoPlayer({ url }) {
    

    return (
        <Box>
            <iframe src={url} style={{ width:'100vw', height:'100vh', position:'fixed', top: '0px', left: '0px' }}/>
        </Box>
    )
}
