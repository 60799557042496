import React from "react";
import {
  Box,
  HStack,
  Text,
  Flex,
  Spacer,
} from "@chakra-ui/react";

import {  useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { CommonHeader } from "../../Components/CommonHeader";


import { useIsAuthenticated } from "../../App/Context";
import { ROUTES } from "../../Constants/Routes";
import { HiOutlineChevronDown, HiOutlineChevronRight } from "react-icons/hi";
import { FooterComponent } from "../../Components/FooterComponent";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { CoursesPage } from "../CoursesPage";
import { BooksPage } from "../BooksPage";
import { CurrentAffairs } from "../CurrentAffairs";
import { HelpAndSupport } from "../HelpAndSupport";
import { PrivacyPolity } from "../PrivacyPolicy.js";
import { Sidebar } from "../../Components/Sidebar";
import { NoticeAndEvents } from "../Notice&Events";
import { DemoContent } from "../DemoContent";
import { TermsService } from "../TermsService";
import { AboutUs } from "../AboutUs";
import { ProtectedRoute } from "..";
import { ContactUs } from "../contact";
import Doubts from "../Doubts";
import { PackageDemoContent } from "../DemoContent/PackageDemoContent";
import { Notifications } from "../Notifications";
import { PackageDetails } from "../PackageDetails";

export const HomeRoute = (props) => {
  const isAuthenticated = useIsAuthenticated();
  // let { path } = useRouteMatch();
  
  const { config } = useSelector((s) => ({
    config: s.package.config,
    pkg: s.package,
  }));

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     // eslint-disable-next-line no-undef
  //     const tawk = Tawk_API;
  //     tawk?.hideWidget?.();
  //   } else {
  //     // eslint-disable-next-line no-undef
  //     const tawk = Tawk_API;
  //     tawk?.showWidget?.();
  //   }
  // }, [isAuthenticated]);

  return (
    <Box>
      <CommonHeader
        isAuthenticated={isAuthenticated}
        pageContext="home"
        config={config}
      />
      <Flex minH="70vh" w={"full"}>
        {isAuthenticated ? (
          <Box w={0} id="sidebar">
            <Sidebar />
          </Box>
        ) : null}
        <Box
          flex={1}
          minHeight="60vh"
          display="flex"
          id="other"
          background="#F7F8FB"
        >
          <Box w="100%">
            <Switch>
              {/* <Route exact path={path} component={MainContent} /> */}
              <Route path={ROUTES.PACKAGE_DETAILS} component={PackageDetails} />
              {/* <Route
                exact
                path={ROUTES.OFFLINE_PACKAGE_REGISTER}
                component={OfflinePackageRegistration}
              /> */}
              <Route path={ROUTES.VIDEO_PLAYER} component={CoursesPage} />
              <Route path={ROUTES.BOOKS} component={BooksPage} />
              <Route
                path={ROUTES.HELP_AND_SUPPORT}
                component={HelpAndSupport}
              />
              <Route path={ROUTES.CURRENT_AFFAIRS} component={CurrentAffairs} />
              <Route path={ROUTES.NOTICE} component={NoticeAndEvents} />
              <Route path={ROUTES.DEMO_CONTENT} component={DemoContent} />
              <ProtectedRoute
                path={ROUTES.NOTIFICATIONS}
                component={Notifications}
              />
              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolity} />
              <Route path={ROUTES.TERMS_SERVICE} component={TermsService} />
              <Route path={ROUTES.ABOUT_US} component={AboutUs} />
              <Route path={ROUTES.CONTACT_US} component={ContactUs} />
              <Route path={ROUTES.DOUBTS} component={Doubts} />
              <Route
                path={ROUTES.PACKAGE_DEMO_CONTENT}
                component={PackageDemoContent}
              />
            </Switch>
          </Box>
        </Box>
      </Flex>

      <FooterComponent />
    </Box>
  );
};

export const packageHomeSelector = createSelector(
  (state) => ({
    packages: state.package.packagesList,
    config: state.package.config,
  }),
  ({ packages, config }) => {
    return packages;
  }
);



export const CommonCardHeader = ({
  title,
  color,
  changeFilterOpen,
  collapseOpened,
}) => {
  return (
    <HStack
      bg="white"
      align="center"
      cursor="pointer"
      p={2}
      boxShadow="sm"
      borderLeft={"4px solid " + color}
      onClick={() => changeFilterOpen(title)}
    >
      <Box width="100%">
        <Text fontSize="" fontWeight="" textAlign="left" color={color}>
          {title}
        </Text>
      </Box>
      <Spacer />
      <Box>
        {collapseOpened ? (
          <HiOutlineChevronDown style={{ color: color, fontSize: "20px" }} />
        ) : (
          <HiOutlineChevronRight style={{ color: color, fontSize: "20px" }} />
        )}
      </Box>
    </HStack>
  );
};
