import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { packageHomeSelector } from ".";
import { useIsAuthenticated } from "../../App/Context";
import { CommonHeader } from "../../Components/CommonHeader";
import { FooterComponent } from "../../Components/FooterComponent";
import { ItemsSection, TestItemsSection } from "../../Components/ItemsSection";
import Nabar from "../../HomePageComponents/navbar";
import { ROUTES } from "../../Constants/Routes";
import { getPackagesAction } from "../../redux/reducers/packages";
import { AboutUs } from "../AboutUs";
import { ContactUs } from "../contact";
import { PackageDemoContent } from "../DemoContent/PackageDemoContent";
import { PrivacyPolity } from "../PrivacyPolicy.js";
import { TermsService } from "../TermsService";

export const AltHome = (props) => {
  const isAuthenticated = useIsAuthenticated();
  let { path } = useRouteMatch();
  const { config } = useSelector((s) => ({
    config: s.package.config,
    pkg: s.package,
  }));

  const packagesData = useSelector(packageHomeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!packagesData?.length) {
      dispatch(getPackagesAction()); // API Call to get All the Packages
    }
  }, [dispatch, packagesData?.length]);

  return (
    <Box>
      <Nabar />
      {/* <ContainerX py={20}> */}

      {/* <CommonHeader
        isAuthenticated={isAuthenticated}
        pageContext="home"
        config={config}
      /> */}

      <Box
        minHeight="60vh"
        p={{ base: 2, md: 10 }}
        id="other"
        background="#F7F8FB"
      >
        <Box w="100%">
          <Switch>
            <Route exact path={path} component={MainContainer} />

            <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolity} />
            <Route path={ROUTES.TERMS_SERVICE} component={TermsService} />
            <Route path={ROUTES.ABOUT_US} component={AboutUs} />
            <Route path={ROUTES.CONTACT_US} component={ContactUs} />
            <Route
              path={ROUTES.PACKAGE_DEMO_CONTENT}
              component={PackageDemoContent}
            />
          </Switch>
        </Box>
      </Box>

      <FooterComponent />
    </Box>
  );
};

const MainContainer = () => {
  const packagesData = useSelector(packageHomeSelector);

  return (
    <Container maxW="7xl">
      <TestItemsSection
        title={"Register for P-SAT 2023"}
        items={packagesData.filter((pkg) => pkg.isOfflineTestSeriesPackage)}
        testSeries
        filtersApplied={false}
      />
    </Container>
  );
};
