import { Box, Button, Center, Flex, Icon, Image, Text } from '@chakra-ui/react'
import { filter, map, orderBy } from 'lodash'
import React, { useEffect } from 'react'
import { FromBottomAnimation, FromLeftAnimation, FromRightAnimation } from '../common/animation'
import ContainerX from '../common/containerX'
import HeadingX from '../common/headingX'
import { Instructor } from './instructors'
import { useDispatch, useSelector } from 'react-redux'
import { getWebsiteStaff } from '../redux/reducers/doubt'
import Nabar from './navbar'
import Footer from './footer'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FaChalkboardTeacher } from 'react-icons/fa'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { BsPeople } from 'react-icons/bs'

export default function AboutUs(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getWebsiteStaff())
    }, [dispatch])

    const { websiteStaffs } = useSelector(s => ({
        websiteStaffs: s.doubts.websiteStaffs,
    }))

    return (
        <Box>
            <Nabar />
            <ContainerX py={20}>
                <Box>
                    <Flex wrap='wrap'>
                        <FromLeftAnimation w={{ base: '100%', lg: '60%' }} px={{ lg: 4, xl: 0 }} pr={{ xl: 8 }}>
                            <Box>
                                <HeadingX>About PRAYAS</HeadingX>
                                <Box mt={4} color='gray.600'>
                                    A premier coaching institute for competitive exams such as Teacher (Grade 2nd, Grade 3rd), LDC, CET, RAS, and others.
                                    The Institute is well-known for providing high-quality entrance exam preparation and consistently producing excellent results. PRAYAS focuses on providing an excellent platform for students to prepare for competitive exams education by laying a strong foundation of knowledge and concepts in them.
                                    We help students meet their career goals and objectives by providing the best academic support and personal care.
                                </Box>

                                <Box mt={{ lg: 18 }} pt={10}>
                                    <Button colorScheme='default' onClick={() => document.getElementById("footer").scrollIntoView({ behavior: "smooth" })} cursor="pointer">Contact us</Button>
                                </Box>
                            </Box>
                        </FromLeftAnimation>
                        <FromRightAnimation mt={{ base: 8, lg: 0 }} h={{ lg: 500 }} w={{ lg: '40%' }} pos='relative' flexGrow={1}>
                            <Box>
                                <Image src='https://images.everydayhealth.com/images/cs-prevent-as-back-pain-working-from-home-1440x810.jpg'
                                    objectFit='cover'
                                    h={400}
                                    zIndex={2}
                                    pos='relative'
                                    alt='aboutUs'
                                    borderRadius={5}
                                />
                                <Box pos='absolute' top={'-10%'} right="-10%" w={{ lg: 400 }} h={{ lg: 300 }} bg='#fd5f0017' borderRadius={5}>
                                </Box>
                            </Box>
                        </FromRightAnimation>
                    </Flex>
                </Box>
                <Values />
                <Flex pt={20} flexDir={{ base: "column", md: "row" }}>
                    <HeadingX flex={2}>Mission & Vision</HeadingX>
                    <Box flex={5}>
                        <Text mt={1} mb={10} fontSize={"lg"} align="justify" >Our vision is to become a leading education service provider capable of reaching every deserving candidate in urban, rural, and remote areas.</Text>
                        <MissionVision text="To provide a competitive platform for aspirants through a technology-enabled digital learning system, thus contributing to the Government of India's Digital India initiative." />
                        <MissionVision text="To assemble the best team of experienced subject experts to create best-in-class study material and test material for students in order to better equip them for their goals and journey to success." />
                        <MissionVision text="To assist all deserving candidates from all corners of Rajasthan with our cost-effective services in order to increase their capability and confidence for various competitive exams." />
                    </Box>
                </Flex>
                {websiteStaffs?.length ?
                    <Flex mt={20} wrap='wrap' bg="gray">
                        {
                            map(orderBy(filter(websiteStaffs, s => s.user.isActive === true), ("createdAt", "priority"), "asc"), (staff, i) => (
                                <Instructor key={i} staff={staff} />
                            ))
                        }
                    </Flex>
                    :
                    null
                }
            </ContainerX>
            <Box>
                <Footer />
            </Box>
        </Box>
    )
}

const Values = () => {
    return (
        <Box mt={{ base: 10, lg: 10 }}>
            <HeadingX>The values we live by</HeadingX>
            <Flex mt={4} wrap='wrap'>
                <Value icon={<FaChalkboardTeacher />} title="Teaching Values" desc="To be Innovative and creative in our teaching approach" />
                <Value icon={<BsPeople  />} title="Student Values" desc="To remain focussed on the needs of the students." />
                <Value icon={<HiOutlineNewspaper />} title="Competition Eaxm Values" desc="To provide best possible coaching and guidance for the Competitive Examinations" />
            </Flex>
        </Box>
    )
}

const Value = ({icon, title, desc}) => {
    return (
        <FromBottomAnimation p={2} w={{ base: '100%', md: '50%', lg: '33%' }}>
            <Box>
                <Box bg='gray.50' px={8} py={10} borderRadius={5} boxShadow='sm'>
                    <Center fontSize={25} color='default.400' _hover={{ color: 'default.500' }} w={14} h={14} borderRadius='50%' bg='gray.100'>
                        <Icon >{icon}</Icon>
                    </Center>
                    <Box mt={6} fontWeight='bold'>{title}</Box>
                    <Box color='gray.600' mt={2}>{desc}</Box>
                </Box>
            </Box>
        </FromBottomAnimation>
    )
}

const MissionVision = ({ text }) => {
    return (
        <Flex my={4} align="start">
            <Flex fontSize={"lg"} color="default.500" mr={3} pt={2}>
                <AiFillCheckCircle fontSize={20} />
            </Flex>
            <Text fontSize={"lg"} align="justify" >{text}</Text>
        </Flex>
    )
}