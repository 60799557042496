import { Box, Avatar, Center, Flex, Heading, Image } from '@chakra-ui/react'
import { map } from 'lodash'
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im'
import HeadingX from '../common/headingX'
import SliderCarousel from '../common/slider'

export default function StudentSay({ data }) {

    return (
        <Box mt={10} pt={20} position="relative" bgGradient='linear(to-tr,#FDF3E7, #fd5f00)'>
            <Flex w="100%" top={0} position="absolute" h="100%">
                <Image w="100%" opacity={0.1} src={'/assets/student6.webp'} />
            </Flex>
            <Box zIndex={4}>
                <HeadingX textAlign='center' color="black">what our students say</HeadingX>
                <Box>
                    <SliderCarousel>
                        {map(data, (testimonial, i) => (
                            <StudentStatement key={i} testimonial={testimonial} />
                        ))}
                    </SliderCarousel>
                </Box>
            </Box>
        </Box>
    )
}


const StudentStatement = ({ key, testimonial }) => {
    return (
        <Center key={key} mb={20} py={20} px={{ base: 5, md: 10, lg: 0 }}>
            <Box maxW={800}>
                <Flex mb={10}>
                    <Flex h="100%" color='default.400' align={"start"} top={0} left={-10}>
                        <ImQuotesLeft />
                    </Flex>
                    <Box w="100%" px={2} fontSize="lg" color="blackAlpha.900">{testimonial.review}</Box>
                    <Flex h="100%" color='default.400' alignSelf={"end"} bottom={0} right={-10}>
                        <ImQuotesRight />
                    </Flex>
                </Flex>
                <Box mt={20}>
                    <Flex align='center' justify='end'>
                        <Box mr={4}>
                            <Heading color='default.500'>{testimonial.name}</Heading>
                            {/* <Box fontSize='sm'>Student in B.tech</Box> */}
                        </Box>
                        <Avatar size='xl' src={testimonial.image} />
                    </Flex>
                </Box>
            </Box>
        </Center>
    )
}