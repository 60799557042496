import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Card } from '../Components/ItemCard'
import { ItemsSection } from "../../Components/ItemsSection";
import { useQueryParams } from "../../utils/useQueryParams";

import { Empty } from "../../Components/Empty";
import { SectionHeader } from "../../Components/SectionHeader";
export const BooksPage = () => {

let query = useQueryParams();
  // useEffect(() => {
  //     dispatch(getPackagesAction({tags:['60754258c2d544375767166d', '6075337655f2c3372c97bef1']}))
  //     // history.push({pathname:'/packages', search:''})
  // }, [])

  const { packages } = useSelector((state) => ({
    packages: state.package.packagesList,
  }));
  const filteredPackages = packages.filter((pkg) => {
    return pkg.type === "BOOK";
  });

  if (filteredPackages.length) {
    return (
      <Box p="2rem">
        <SectionHeader
          title="Books Store"
          breadcrumbs={[
            { title: "Home", link: "/" },
            { title: "Books Store", link: "#" },
          ]}
        />
        <Box
          display="flex"
          flexWrap="wrap"
        >
          <ItemsSection items={filteredPackages} showAll />
        </Box>
      </Box>
    );
  } else {
    return (
      <Empty title="Nothing Here" subtitle="Currently No Books are Available" />
    );
  }
};
