import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, FormControl, FormLabel, Input, Select, Text, Textarea, useToast } from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadingX from "../common/headingX";
import { STATUS } from "../Constants";
import { addCareerRequest, resetFormStatus } from "../redux/reducers/formSubmit";
import { uploadFileAction } from "../redux/reducers/website";
import { useCheckStatus } from "../utils/useCheckStatus";

export default function CVSubmission() {
    const dispatch = useDispatch()
    const [userInput, setInput] = useState()
    const { file, uploadFileStatus, addCareerRequestStatus } = useSelector(s => ({
        file: s.website.file,
        addCareerRequestStatus: s.form.addCareerRequestStatus,
    }))

    useEffect(() => {
        setInput(pre => ({ ...pre, cvUrl: file?.url }))
    }, [file?.url])

    const inputHandler = (name, value) => {
        setInput(pre => ({ ...pre, [name]: value }))
    }

    const uploadImage = (file, e) => {
        let formData = new FormData()
        formData.append("file", file)

        dispatch(uploadFileAction(formData))
    }

    const _submit = (e) => {
        e.preventDefault()
        dispatch(addCareerRequest(userInput))
    }

    const toast = useToast()

    useCheckStatus({
        status: addCareerRequestStatus,
        onSuccess: () => {
            toast({
                title: "Submitted",
                status: "success",
                duration: 2000,
                isClosable: true,
            });
            document.getElementById("careerForm").reset()
            setInput()
            resetFormStatus()
        },
    });
    return (
        <Flex display={{ base: "column", md: "flex" }} py={20}>
            <Center flex={1} align="center" justify={"center"} bg="gray.50">
                <Box alignSelf='center'>
                    <HeadingX fontSize={"4xl"}>Join Our Team</HeadingX>
                    <Text color='gray.600' align="justify" my={{ base: 6, lg: 10 }} w={{ base: '90%', md: "60%" }} fontSize={{ base: "md", md: "lg" }}>
                        Our people drive PRAYAS vision.
                        We firmly believe that our greatest asset is our workforce.
                        We take great pride in the exceptional pedagogy and educational quality we provide to our students.
                        We go to extraordinary lengths to find and hire the best person for each job.
                        We give our team members a lot of ownership and give them the opportunity to advance faster than they would in most other places.
                        We're looking for mission-driven candidates with a sense of purpose in their work. We promote diversity and provide a welcoming environment for our employees.
                    </Text>
                </Box>
            </Center>
            <Box flex={1} bg="white">
                <Box p={5} borderBottom="1px solid black">
                    <Text mx={4} fontSize={"2xl"} wordBreak="break-word">About You</Text>
                    <Text m={4} fontSize={"xs"}>Do you want to work with us? Please fill all the details below.</Text>
                </Box>
                <Box p={5}>
                    <form id={"careerForm"} onSubmit={_submit}>
                        <FormControl isRequired>
                            <Flex my={4} w="100%" align="center">
                                <FormLabel flex={1}>First Name</FormLabel>
                                <Input name="name" flex={1} type="text" placeholder="Enter Your Name" onChange={(e) => inputHandler("name", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired>
                            <Flex my={5} w="100%" align="center">
                                <FormLabel flex={1}>Last Name</FormLabel>
                                <Input name="lastName" flex={1} placeholder="Enter Your Last Name" onChange={(e) => inputHandler("lastName", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired>
                            <Flex my={5} w="100%" align="center">
                                <FormLabel flex={1}>Contact</FormLabel>
                                <Input name="contact" type="tel" pattern="[6-9]{1}[0-9]{9}" maxLength={10} flex={1} placeholder="Enter Your Contact" onChange={(e) => inputHandler("contact", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired>
                            <Flex my={5} w="100%" align="center">
                                <FormLabel flex={1}>Email Address</FormLabel>
                                <Input name="email" flex={1} type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$" placeholder="Enter Your Email" onChange={(e) => inputHandler("email", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl isRequired w="100%">
                            <Flex my={5} w="100%" align="center">
                                <FormLabel w="47.5%">Position Applying For</FormLabel>
                                <Flex w="52.5%">
                                    <Select name="applyingPosition" w="100%" flex={1} placeholder="Select Position" onChange={(e) => inputHandler("applyingPosition", e.target.value)}>
                                        <option>Teacher</option>
                                        <option>Management</option>
                                        <option>Accountant</option>
                                        <option>IT</option>
                                        <option>Computer Operator (Hindi & English Typist)</option>
                                        <option>Graphic Designer</option>
                                        <option>Content Writer / Researchers</option>
                                        <option>Proof Reader</option>
                                        <option>Publication</option>
                                    </Select>
                                </Flex>
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex my={5} w="100%" align="center">
                                <FormLabel flex={1}>Educational Qualification</FormLabel>
                                <Input name="qualification" flex={1} type="text" placeholder="Your Educational Qualification" onChange={(e) => inputHandler("qualification", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex my={5} w="100%">
                                <FormLabel flex={1}>Additional Information</FormLabel>
                                <Textarea name="additionalInfo" flex={1} rows={5} type="text" placeholder="Enter some additional information" onChange={(e) => inputHandler("additionalInfo", e.target.value)} />
                            </Flex>
                        </FormControl>
                        <FormControl>
                            <Flex my={5} w="100%">
                                <FormLabel flex={1}>Upload Your Resume Here</FormLabel>
                                <Input flex={1} type="file" onChange={(e) => uploadImage(e.target.files[0])} />
                            </Flex>
                        </FormControl>
                        <Flex justify={"end"}>
                            <Button type="submit" isDisabled={addCareerRequestStatus === STATUS.FETCHING ? true : false} colorScheme={"default"}>Submit</Button>
                        </Flex>
                    </form>
                </Box>
            </Box>
        </Flex>
    )
}