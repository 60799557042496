import { Box } from "@chakra-ui/react";
import React,{ useCallback, useEffect, useMemo, useState } from "react";

export const FromBottomAnimation = (props) => {
    const [ show, setShow ] = useState(false);
    const divRef = React.createRef();

    const options = useMemo(() => ({
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }),[])
    const callback = useCallback((entries) => {
        const [entry] = entries;
        if(entry.isIntersecting){
            setShow(true);
        }
    },[setShow])
    useEffect(() => {
            const divCurrent = divRef.current;
            const observer = new IntersectionObserver(callback, options);
            if(divCurrent){
                observer.observe(divRef.current)
            }
            return () => {
                if(divCurrent)
                observer.unobserve(divCurrent)
            }
    }, [divRef, options, callback])
    
    return (
        <Box ref={divRef} w='100%' transition='all 1.4s' pos='relative' bottom={ show ? "0px" : "-25px" } opacity={ show ? '1' : '0' } { ...props}>
            {props.children}
        </Box>
    )
}


export const FromLeftAnimation = (props) => {
    const [ show, setShow ] = useState(false);
    const divRef = React.createRef();

    const options = useMemo(() => ({
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }),[])
    const callback = useCallback((entries) => {
        const [entry] = entries;
        if(entry.isIntersecting){
            setShow(true);
        }
    },[setShow])
    useEffect(() => {
            const divCurrent = divRef.current;
            const observer = new IntersectionObserver(callback, options);
            if(divCurrent){
                observer.observe(divRef.current)
            }
            return () => {
                if(divCurrent)
                observer.unobserve(divCurrent)
            }
    }, [divRef, options, callback])
    
    return (
        <Box ref={divRef} w='100%' transition='all 1.4s' pos='relative' left={ show ? "0px" : "-25px" } opacity={ show ? '1' : '0' } { ...props}>
            {props.children}
        </Box>
    )
}

export const FromRightAnimation = (props) => {
    const [ show, setShow ] = useState(false);
    const divRef = React.createRef();

    const options = useMemo(() => ({
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    }),[])
    const callback = useCallback((entries) => {
        const [entry] = entries;
        if(entry.isIntersecting){
            setShow(true);
        }
    },[setShow])
    useEffect(() => {
            const divCurrent = divRef.current;
            const observer = new IntersectionObserver(callback, options);
            if(divCurrent){
                observer.observe(divRef.current)
            }
            return () => {
                if(divCurrent)
                observer.unobserve(divCurrent)
            }
    }, [divRef, options, callback])
    
    return (
        <Box ref={divRef} w='100%' transition='all 1.4s' pos='relative' right={ show ? "0px" : "-25px" } opacity={ show ? '1' : '0' } { ...props}>
            {props.children}
        </Box>
    )
}