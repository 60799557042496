import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import _, { filter, find, lowerCase, map, size, trim } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { STATUS, TEHSIL_LIST } from "../../Constants";
import { requestUserAddressAction } from "../../redux/reducers/address";
import { resetUserStatusAction } from "../../redux/reducers/user";
import { apis } from "../../services/api/apis";
import { bilingualText } from "../../utils/Helper";
import { usePaymentVerify } from "../Cart/usePaymentVerify";
import { ProfPicModal } from "../Settings";

export const RegisterationForm = ({
  nextStep,
  currentPkg,
  successView,
  setSuccessView,
}) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const { user, updateUserStatus, userAddress, lmsConfig } = useSelector((state) => ({
    user: state.user.user,
    lmsConfig: state.lmsConfig.defaultData,
    updateUserStatus: state.user.updateUserStatus,
    userAddress: state.address.userAddress,
  }));
  
  const exams = lmsConfig?.exams

  

  const [userData, changeUserData] = useState({});
  const [states, setStates] = useState([]);
  const [profpic, setProfpic] = useState();
  const [addressData, setAddressData] = useState({});
  const {
    isOpen: isOpenForProfPicModal,
    onOpen: onOpenForProfPicModal,
    onClose: onCloseForProfPicModal,
  } = useDisclosure();

  // useEffect(() => {
  //   if (userAddress?.length) {
  //     const add = userAddress[0];
  //     const state = find(states, (s) => s.name === add.state);
  //     const city = state
  //       ? find(state.cities, (c) => c.name === add.city)
  //       : null;
  //     setAddressData({
  //       address: add.address,
  //       city: city,
  //       state: state,
  //       pincode: add.pincode,
  //     });
  //   }
  // }, [states, userAddress]);

  const fetchStateCity = useCallback(async () => {
    const response = await apis.fetchStateCityDataApi();
    if (response.ok) setStates(filter(response.data, r => r.name === "HARYANA" || r.name ===  "RAJASTHAN" ));
    else
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
  }, [toast]);

  const isEmitra = user && user.emitra;

  useEffect(() => {
    if (user) {
      changeUserData({ fatherName: user.fatherName });
      setProfpic(user.avatar);
    }
  }, [user]);

  useEffect(() => {
    fetchStateCity();
  }, [fetchStateCity]);

  const _changeData = (type, value) => {
    changeUserData((d) => ({ ...d, [type]: value }));
  };

  const [confirmModal, openConfirmModal] = useState();

  useEffect(() => {
    if (user) dispatch(requestUserAddressAction({ userId: user._id }));
  }, [dispatch, user]);

  const [required, setRequired] = useState();


  const userExamChoices = useMemo(() => {
    if(userData.package && exams){
      return userData.package?.exams?.map(ex => {
        const exx = find(exams, es => es._id === ex)
        return exx
      })
    }
  },[exams, userData.package])


  const _submit = (e) => {
    e.preventDefault();
    const data = { ...userData, avatar: profpic, userId: user?._id };

    if(size(userExamChoices) && !userData.interestedCourse){
      return toast({
        title:"Please select Exam Category",
        status:"warning"
      })
    }

    if (!userData.package) {
      setRequired({ package: !userData.package });
      return;
    } else setRequired(false);

    openConfirmModal(data);
  };

  const handleDelete = () => {
    setProfpic();
  };
  const [loading, setLoading] = useState();

  const addImage = async (payload) => {
    setLoading(true);
    const response = await apis.uploadFileApi(payload);
    if (response?.ok) {
      setLoading();
      setProfpic(response.data?.url);
    } else {
      setLoading();
      toast({ status: "error", title: "something went wrong" });
    }
  };

  const handlePictureSelected = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    addImage(formData);
  };

  const fileUploadButton = () => {
    document.getElementById("fileButton").click();
  };

  const planData = useMemo(() => {
    if (currentPkg) {
      let subjects = currentPkg.subjects && currentPkg.subjects.split(",");
      subjects = subjects?.length ? subjects.map((d) => trim(d)) : [];

      let cities = currentPkg.cities && currentPkg.cities.split(",");
      cities = cities?.length ? cities.map((d) => trim(d)) : [];

      return { cities, subjects };
    } else {
    }
  }, [currentPkg]);

  const _closeConfirm = () => {
    openConfirmModal();
  };

  const _resetData = () => {
    changeUserData({});
  };

  const validate=(input)=>{
    if(/^\s/.test(input?.value))
      input.value = '';
  }


  

  return (
    <Box>
      {confirmModal && (
        <ConfirmModal
          setSuccessView={setSuccessView}
          avatar={profpic}
          resetData={_resetData}
          currentPkg={currentPkg}
          visible={confirmModal}
          defaultData={confirmModal}
          closeModal={_closeConfirm}
        />
      )}
      {isEmitra ? (
        <Alert
          position={"sticky"}
          top={0}
          zIndex={"1000"}
          colorScheme="yellow"
          mb={4}
          border={"1px solid #52BE80"}
        >
          You are logged in as emitra user ({user.name})
        </Alert>
      ) : null}

      <Alert
        colorScheme="teal"
        mb={4}
        color="#145A32"
        border={"1px solid #52BE80"}
      >
        Only one aspirant can be enrolled from one mobile number.
      </Alert>
      <Text color={"#F39C12"}>Enter Your Details Carefully</Text>
      <ProfPicModal
        isOpen={isOpenForProfPicModal}
        onClose={onCloseForProfPicModal}
        avatar={user?.user?.avatar}
      />
      <form onSubmit={_submit}>
        {
          <VStack align="stretch" spacing={4} mt={6}>
            {/* <FormControl>
              <FormLabel>Profile Picture</FormLabel>
              <Flex direction="row" align={"center"}>
                <Avatar
                  cursor="pointer"
                  onClick={() => onOpenForProfPicModal()}
                  border="2px solid #d4d4d4"
                  mb={2}
                  size="xl"
                  name={"profile"}
                  src={profpic}
                />
                <Flex direction="row" pl={4}>
                  {profpic ? (
                    <>
                      <Button
                        size="sm"
                        ml={4}
                        onClick={handleDelete}
                        colorScheme="red"
                      >
                        Remove
                      </Button>
                    </>
                  ) : (
                    <>
                      <Input
                        onChange={handlePictureSelected}
                        id="fileButton"
                        type="file"
                        hidden
                      />
                      <Button
                        isLoading={loading}
                        size="sm"
                        mr={4}
                        colorScheme="green"
                        onClick={fileUploadButton}
                      >
                        Upload New
                      </Button>
                    </>
                  )}
                </Flex>
              </Flex>
              {required?.profilePic && (
                <Text color={"#E74C3C"} fontSize="sm">
                  *Please select profile pic
                </Text>
              )}
            </FormControl> */}
            <FormControl isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                value={userData.name}
                name="name"
                type="text"
                onInput={(e)=>validate(e.target)}
                placeholder="name"
                onChange={(e) => _changeData("name", e.target.value)}
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Father Name</FormLabel>
              <Input
                value={userData.fatherName}
                name="fatherName"
                onChange={(e) => _changeData("fatherName", e.target.value)}
                placeholder="father name"
              />
            </FormControl>
            <FormControl isRequired>
              {isEmitra ? (
                <FormLabel color="red.400">
                  Enter conditate mobile number
                </FormLabel>
              ) : (
                <FormLabel>Mobile No.</FormLabel>
              )}

              <Input
                value={userData.contact}
                name="contact"
                onChange={(e) => _changeData("contact", e.target.value)}
                maxLength={10}
                placeholder="mobile no."
                pattern="[6789][0-9]{9}"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                value={userData.email}
                name="email"
                type="email"
                onChange={(e) => _changeData("email", e.target.value)}
                placeholder="email"
              />
            </FormControl>
            <Flex flexWrap={"wrap"}>
              <Box w={{ base: "100%", md: "33%" }} pr={4} pb={4}>
                <FormControl isRequired>
                  <FormLabel>State</FormLabel>
                  <Select
                    value={userData.state?.id}
                    name="state"
                    placeholder="select state"
                    onChange={(e) =>
                      _changeData(
                        "state",
                        find(states, (s) => s.id == e.target.value)
                      )
                    }
                  >
                    {states?.length
                      ? states.map((st) => (
                          <option value={st.id} key={st.id}>
                            {st.name}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Box>
              <Box w={{ base: "100%", md: "33%" }} pr={4} pb={4}>
                <FormControl isRequired>
                  <FormLabel>City</FormLabel>
                  <Select
                    name="city"
                    value={userData.city?.id}
                    disabled={!userData.state}
                    placeholder="select city"
                    onChange={(e) =>
                      _changeData(
                        "city",
                        find(
                          userData.state.cities,
                          (s) => s.id == e.target.value
                        )
                      )
                    }
                  >
                    {userData.state && userData.state.cities?.length
                      ? userData.state.cities.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Box>
              {userData.state &&
              (userData.state?.name === "RAJASTHAN" ||
                userData.state?.name === "HARYANA") ? (
                <Box w={{ base: "100%", md: "33%" }} pr={4} pb={4}>
                  <FormControl isRequired>
                    <FormLabel>Tehsil</FormLabel>
                    <Select
                      name="tehsil"
                      value={userData.tehsil}
                      disabled={!userData.state}
                      placeholder="select tehsil"
                      onChange={(e) => _changeData("tehsil", e.target.value)}
                    >
                      {userData.city &&
                      userData.city &&
                      find(
                        TEHSIL_LIST,
                        (t) =>
                          lowerCase(t._id) === lowerCase(userData.city.name)
                      )?.sub?.length
                        ? find(
                            TEHSIL_LIST,
                            (t) =>
                              lowerCase(t._id) === lowerCase(userData.city.name)
                          ).sub.map((tehsil) => (
                            <option key={tehsil} value={tehsil}>
                              {tehsil}
                            </option>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Box>
              ) : null}
            </Flex>

            <Flex flexWrap={"wrap"}>
              <Box w={{ base: "100%", md: "66%" }} pr={4} pb={4}>
            <FormControl isRequired>
              <FormLabel>Choose Exam</FormLabel>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                  {userData.package?.name?.en || "CHOOSE EXAM"}
                </MenuButton>
                <MenuList>
                  {currentPkg?.packages?.length
                    ? map(currentPkg.packages,(pkg, i) => {
                        return (
                          <>
                            <MenuItem
                              onClick={(e) => {
                                _changeData("package", pkg)
                                _changeData("interestedCourse", null)
                              }}
                              key={pkg._id}
                            >
                              <Box>
                                <Text>{pkg.name?.en}</Text>
                                <HStack>
                                  <Text fontWeight={"bold"} color="#27AE60">
                                    ₹{pkg.price}
                                  </Text>
                                  {pkg.fakePrice ? (
                                    <Text fontSize={"xs"} color="gray.400">
                                      <del>₹{pkg.fakePrice}</del>
                                    </Text>
                                  ) : null}
                                  {pkg.gst ? (
                                    <Text fontSize="xs" color="red.500">
                                      *{pkg.gst}% gst extra
                                    </Text>
                                  ) : null}
                                </HStack>
                              </Box>
                            </MenuItem>
                            {i + 1 < currentPkg.packages.length && (
                              <MenuDivider />
                            )}
                          </>
                        );
                      })
                    : null}
                </MenuList>
              </Menu>
              {required?.package && (
                <Text color={"#E74C3C"} fontSize="sm">
                  *Please choose exam
                </Text>
              )}
              <br />
              {userData.package && (
                <Tag colorScheme="blue" fontSize={16} mt={4}>
                  {userData.package?.name?.en}
                </Tag>
              )}
            </FormControl>
            </Box>
            <Box>
            {
                  size(userExamChoices) ? 
                  <FormControl isRequired>
                  <FormLabel>Category</FormLabel>
                    <Select value={userData.interestedCourse} onChange={(e) => _changeData("interestedCourse", e.target.value)}>
                    <option  value={""}>
                              - select - 
                            </option>
                      {
                        map(userExamChoices, exc => {
                          return (
                            <option key={exc._id} value={exc?.name?.en}>
                              {exc?.name?.en}
                            </option>
                          )
                        })
                      }
                    </Select>
                  </FormControl> : null
                }
            </Box>
            </Flex>
              

                
            {userData.package ? (
              <>
               
                {userData.package.showAsRelevant ? (
                  <FormControl isRequired>
                    <FormLabel>Select Subject</FormLabel>
                    <Select
                      name="subject"
                      value={userData.subject}
                      onChange={(e) => _changeData("subject", e.target.value)}
                      placeholder="select subject"
                    >
                      {planData.subjects?.length
                        ? planData.subjects.map((sub) => (
                            <option key={sub} value={sub}>
                              {sub}
                            </option>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                ) : null}
                <FormControl isRequired>
                  <FormLabel>Select Exam Center City</FormLabel>
                  <Select
                    name="center"
                    value={userData.center}
                    onChange={(e) => _changeData("center", e.target.value)}
                    placeholder="select city"
                  >
                    {planData.cities?.length
                      ? planData.cities.map((city) => (
                          <option key={city} value={city}>
                            {city}
                          </option>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </>
            ) : null}
            <br />
            <FormControl>
              <Button
                type="submit"
                isLoading={updateUserStatus === STATUS.FETCHING}
                colorScheme="blue"
                width={"100%"}
              >
                Save & Proceed To Payment
              </Button>
            </FormControl>
          </VStack>
        }
      </form>
    </Box>
  );
};

const ConfirmModal = ({
  closeModal,
  visible,
  defaultData,
  setSuccessView,
  resetData,
  avatar,
}) => {
  const toast = useToast();

  const { user, updateUserStatus, userAddress } = useSelector((state) => ({
    user: state.user.user,
    updateUserStatus: state.user.updateUserStatus,
    userAddress: state.address.userAddress,
  }));

  const isEmitra = user && user.emitra;

  const { _checkout } = usePaymentVerify({
    mode: "register",
    emitra: isEmitra,
    onSuccess: (data, orderId, resp) => {
      toast({
        status: "success",
        // title: "Payment done",
        description: "Registered for this package.",
      });
      closeModal();
      resetData();
      setSuccessView(true);
    },
  });

  // todo unlock for 0 amount
  const _buyNow = () => {
    const {
      name,
      fatherName,
      contact,
      email,
      state,
      city,
      tehsil,
      center,
      interestedCourse,
      subject,
    } = defaultData;
    closeModal();
    const dd = {
      user: {
        avatar,
        name,
        fatherName,
        contact,
        email,
        address: { state: state?.name, city: city?.name, tehsil },
      },
      interestedCourse,
      subject,
      city: center,
      packages: [defaultData.package._id],
    };
    _checkout(dd, {
      name,
      contact,
      email,
    });
  };

  const gst = (defaultData.package.gst * defaultData.package.price) / 100 || 0;
  const price = defaultData.package.price;

  return (
    <Modal size="lg" onClose={closeModal} isOpen={visible}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align={"stretch"}>
            <HStack align={"start"}>
              <Text fontWeight={"bold"}>Exam:</Text>
              <Text>{bilingualText(defaultData?.package?.name)}</Text>
            </HStack>
            {defaultData?.package?.showAsRelevant ? (
              <HStack>
                <Text fontWeight={"bold"}>Subjects:</Text>
                <Text>{defaultData.subject}</Text>
              </HStack>
            ) : null}
            <HStack>
              <Text fontWeight={"bold"}>Cities:</Text>
              <Text>{defaultData.center}</Text>
            </HStack>

            <HStack>
              <Text fontWeight={"bold"}>Final Price: </Text>
              <Text color="blue.400" fontSize={18}>
                ₹{price}
              </Text>
              {defaultData.package.fakePrice ? (
                <Text fontSize={"sm"} color="#AEB6BF">
                  <del>₹{defaultData.package.fakePrice}</del>
                </Text>
              ) : null}
            </HStack>

           {
            price ? 
            <>
             <HStack>
              <Text fontWeight={"bold"}>Gst:</Text>
              <Text>
                {defaultData.package.gst}% ({" ₹"}
                {gst})
              </Text>
            </HStack>

            <HStack>
              <Text fontWeight={"bold"}>Amount with gst:</Text>
              <Text fontSize={20} fontWeight={"bold"} color="green.600">
                ₹{price + gst}
              </Text>
            </HStack>
            </> : 
            <Text>Free</Text>
           }
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button colorScheme="primary" flex={1} onClick={_buyNow}>
              {
                price === 0 ? "Register Now" : "Buy Now"
              }
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
