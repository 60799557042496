import { Box, HStack } from "@chakra-ui/react";
import { map } from 'lodash'
import Slider from "react-slick"
import { useMemo, useState } from "react";

export default function SliderCarousel({ rows, slides, dots, arrow, settings = {}, ...props }) {
    const { dotesStyle = {} } = props;
    const [selectedIndex, setSelectedIndex] = useState(0);
    const sliderSettings = useMemo(() => ({
        dots: dots === false ? false : true,
        infinite: true,
        autoplaySpeed: 2000,
        slidesToShow: slides || 1,
        rows: rows || 1,
        slidesToScroll: 1,
        nextArrow: null,
        prevArrow: null,
        beforeChange: (oldIndex, newIndex) => setSelectedIndex(newIndex),
        arrows: arrow || false,
        autoplay: true,
        appendDots: dots => {
            return (
                <Box
                    padding={"10px"}
                    width='100%'
                    position='absolute'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    bottom={{ base: 0, sm: '40px' }}
                    marginLeft={(dots * 10) + 'px'}
                    {...dotesStyle}
                >
                    <HStack spacing={2}>
                        {
                            map(dots, (d, index) => (
                                <Box key={index} onClick={d.props.children.props.onClick} w={4} h={4} borderRadius='50%' bg={selectedIndex === index ? 'default.400' : 'gray.400'}>

                                </Box>
                            ))
                        }
                    </HStack>
                </Box>
            )
        },
        ...settings
    }), [dots, slides, rows, arrow, settings, dotesStyle, selectedIndex]);

    return (
        <Box pos='relative' css={{
            "& slick-dots": {
                display: 'flex'
            }
        }}>
            <Slider  {...sliderSettings}>
                {
                    props.children
                }
            </Slider>
        </Box>
    )
}
