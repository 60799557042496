
import React, { useReducer, useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

export const PerformanceReport = (props) => { 
    return (
        <Box>
            PerformanceReport Content
        </Box>
    );
};
