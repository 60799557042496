import { Center, Box } from '@chakra-ui/react'

export default function ContainerX(props) {
    return (
        <div>
            <Center px={{ base: 4, lg: 0 }}>
                <Box maxW='6xl' w='100%' {...props}>
                    { props.children }
                </Box>
            </Center>
        </div>
    )
}
