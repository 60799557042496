import { getBaseUrl } from "./utils/baseUrlHelper";

// export const localBaseUrl = "http://192.168.1.38:4000/"
export const localBaseUrl = "http://localhost:4000/";
export const testBaseUrl = "https://api.test.prayas.tutorlog.in/";
export const liveBaseUrl = "https://api.prayassikar.com/";

const isclient = typeof window !== "undefined";
if (isclient) {
  console.log("[loc]", window.location);
}
export const BaseURL = isclient
  ? getBaseUrl(window.location?.hostname)
  : testBaseUrl;
