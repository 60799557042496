
import React, { useReducer, useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";

export const Logout = (props) => { 
    return (
        <Box>
            Logout Content
        </Box>
    );
};
