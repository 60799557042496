import { AspectRatio, Box, Flex, Image } from "@chakra-ui/react";
import { filter, map } from "lodash";
import React from "react";
import { BsInstagram } from "react-icons/bs";
import ContainerX from "../common/containerX";
import SliderCarousel from "../common/slider";
import "react-ig-feed/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getInstaPost } from "../redux/reducers/instaAuth";
import HeadingX from "../common/headingX";

export default function Gallery() {
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getInstaPost("IGQVJXY2FtX1ZASYy12ekQwa0JyRHN4RU1tWTR0SnQ3ZA1h1emM3Q3pkRmZATNXU4ZA2ltdjc4VGpMdlBhNDRaYmUxaFlOeGFMZAXFKd0xic19aRTE4ZAjJEZAGYwTkdMQTg2cGJiaGE4bGltTC1wclF1a0Y2SAZDZD"))
    dispatch(
      getInstaPost(
        "IGQVJWcEZA5N2NwNjV2dnlfLXJXNk5xakxaYkJLZAzFJbnBEb0ZANUy1WRFRQWVpqZA1pyRkxKM1lnTjNpaTVMdnJ3ZA1U0akNOTUxhTlBPUXJwalUyZAVV1dmxOMnF5amdIeHJKMlJQLUgyOEtRa3pUdTYwRQZDZD"
      )
    );
  }, [dispatch]);

  const { instaPosts } = useSelector((s) => ({
    instaPosts: s.insta.instaPosts,
  }));

  return (
    <ContainerX align="center">
      <Box align="center" display={{ base: "none", lg: "initial" }}>
        <HeadingX>Gallery</HeadingX>

        <Flex
          mt={10}
          justifyContent="space-around"
          flexWrap="wrap"
        >
          {map(
            filter(instaPosts?.data, (d) => d.media_type !== "VIDEO"),
            (post, i) => {
              return i < 8 ? (
                <Flex
                  key={i}
                  role="group"
                  m={1}
                  justifyContent="space-around"
                  // h={"300px"}
                  position="relative"
                  onClick={() =>
                    window.open("https://www.instagram.com/prayaseduhub/")
                  }
                >
                  {post.media_type === "IMAGE" ||
                    post.media_type === "CAROUSEL_ALBUM" ? (
                    <Image
                      w="240px"
                      h="260px"
                      backgroundPosition={"center"}
                      border={"0px solid white"}
                      src={post.media_url}
                      alt="Image"
                    />
                  ) : (
                    <AspectRatio w="100%" ratio={16 / 9} border="1px solid white">
                      <iframe
                        title="naruto"
                        src={post.media_url}
                        allowFullScreen
                      />
                    </AspectRatio>
                  )}
                  <Flex
                    _groupHover={{ opacity: 0.3 }}
                    top={0}
                    opacity={0}
                    w="100%"
                    h="100%"
                    justify={"center"}
                    align="center"
                    position={"absolute"}
                    bg="default.300"
                  >
                    <BsInstagram fontSize={60} />
                  </Flex>
                </Flex>
              ) : null;
            }
          )}
        </Flex>
      </Box>
      <Flex display={{ base: "none", md: "initial", lg: "none" }}>
        <HeadingX mb={10}>Gallery</HeadingX>
        <InstaSlides instaPosts={instaPosts} rows={1} slides={3} />
      </Flex>
      <Flex display={{ sm: "none" }}>
        <HeadingX mb={10}>Gallery</HeadingX>
        <InstaSlides instaPosts={instaPosts} rows={2} slides={1} h="260px" w="260px" />
      </Flex>
      <Flex display={{ base: "none", sm: "initial", md: "none" }}>
        <HeadingX mb={10}>Gallery</HeadingX>
        <InstaSlides instaPosts={instaPosts} rows={1} slides={2} />
      </Flex>
    </ContainerX>
  );
}

const InstaSlides = ({ instaPosts, rows, slides, h, w }) => {
  return (
    <SliderCarousel rows={rows} slides={slides} dotesStyle={{ bottom: "-40px", pb: "10px" }}>
      {map(
        filter(instaPosts?.data, (d) => d.media_type !== "VIDEO"),
        (post, i) =>
          i < 6 ? (
            <Flex
              key={post.id}
              role="group"
              h={"250px"}
              w="100%"
              border={"1px solid white"}
              position="relative"
              onClick={() =>
                window.open("https://www.instagram.com/prayaseduhub/")
              }
            >
              {post.media_type === "IMAGE" ||
                post.media_type === "CAROUSEL_ALBUM" ? (
                // <AspectRatio ratio={1}>
                <Image
                  w={w || "100%"}
                  h={h || "100%"}
                  objectFit={"cover"}
                  border={"0px solid white"}
                  src={post.media_url}
                  alt="Image"
                />
                // </AspectRatio>
              ) : (
                <AspectRatio
                  w={w || "100%"}
                  h={h || "100%"}
                  ratio={16 / 9}
                  border="1px solid white"
                >
                  <iframe
                    title="naruto"
                    src={post.media_url}
                    allowFullScreen
                  />
                </AspectRatio>
              )}
              <Flex
                top={0}
                _groupHover={{ opacity: 0.3 }}
                opacity={0}
                w="100%"
                h="100%"
                justify={"center"}
                align="center"
                position={"absolute"}
                bg="default.300"
              >
                <BsInstagram fontSize={60} />
              </Flex>
            </Flex>
          ) : null
      )}
    </SliderCarousel>
  )
}