import { Box, Button, Center, Heading } from "@chakra-ui/react";
import { orderBy } from "lodash";
import { filter, map } from "lodash";
import SliderCarousel from "../common/slider";

export default function HeaderCarousel(props) {
  const { carouselData } = props;

  return (
    <Box>
      <SliderCarousel dots={false}>
        {map(
          orderBy(
            filter(carouselData, (carousel) => carousel.active === true),
            "order",
            "asce"
          ),
          (c) => {
            return (
              <SingleCarouselPage
                key={c._id}
                isDarkMode={c.background === "dark" ? true : false}
                backgoroundImage={`url(${c.imageUrl})`}
                heading={c.heading}
                desc={c.description}
                link={c.link}
              />
            );
          }
        )}
        {/* <SingleCarouselPage isDarkMode={true} actionTitle={"Contact us"} backgoroundImage="url('https://technext.github.io/fox/images/bg_2.jpg')" action={() => console.log("click")} heading='Education Needs Complete Solution' desc="A small river named Duden flows by their place and supplies it with the necessary regelialia." />
                <SingleCarouselPage isDarkMode={true} actionTitle={"Contact us"} backgoroundImage="url('https://technext.github.io/fox/images/bg_2.jpg')" action={() => console.log("click")} heading='Education Needs Complete Solution' desc="A small river named Duden flows by their place and supplies it with the necessary regelialia." />
                <SingleCarouselPage isDarkMode={true} actionTitle={"Contact us"} backgoroundImage="url('https://technext.github.io/fox/images/bg_2.jpg')" action={() => console.log("click")} heading='Education Needs Complete Solution' desc="A small river named Duden flows by their place and supplies it with the necessary regelialia." /> */}
      </SliderCarousel>
    </Box>
  );
}

const SingleCarouselPage = ({
  key,
  isDarkMode,
  backgoroundImage,
  heading,
  desc,
  link,
}) => {
  return (
    <Box
      key={key}
      pos="relative"
      h={{ base: 150, sm:300, md: 400, lg: 500 }}
      backgroundImage={backgoroundImage}
      backgroundPosition="center"
      backgroundSize="contain"
      bgRepeat={"no-repeat"}
    >
      <Box
        opacity="0.4"
        // bg="linear-gradient(45deg, #0d1128 0%, #fd6100 100%)"
        pos="absolute"
        top={0}
        w="full"
        h="full"
        left={0}
      ></Box>
      <Box px={{ base: 10, lg: 40 }} py={{ base: 20 }} pos="relative">
        <Box zIndex={2} maxW={{ lg: 700 }}>
          <Heading
            color={isDarkMode ? "white" : "gray.700"}
            fontSize={{ base: "2xl", lg: "3xl", xl: "5xl" }}
          >
            {heading}
          </Heading>
          <Box mt={5} mb={10} color={isDarkMode ? "gray.200" : "gray.200"}>
            {desc}
          </Box>
          {link ? (
            <Button colorScheme="default" onClick={() => window.open(link)}>
              More details
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
