import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import { STATUS } from "../../../App/Constants"
import { apis } from '../../../services/api/apis'
import _ from 'lodash'

export const addCareerRequest = createAsyncThunk(
    'add/career-request',
    async (payload, thunkAPI) => {
        const response = await apis.addCareerRequestApi(payload)
        const { ok, problem, data } = response

        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)

export const addVisiterRequest = createAsyncThunk(
    'add/visiter-request',
    async (payload, thunkAPI) => {
        const response = await apis.addVisiterRequest(payload)
        const { ok, problem, data } = response

        if (ok) {
            return data
        } else {
            return thunkAPI.rejectWithValue(problem)
        }
    }
)


let initialState = {
    userAskedDoubts: {},
    teacherDoubts: {},
    allTeachers: []
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetFormStatus: state => {
            state.addCareerRequestStatus = STATUS.NOT_STARTED
            state.addVisiterRequestStatus = STATUS.NOT_STARTED
        },
    },
    extraReducers: {
        [addCareerRequest.pending]: (state) => {
            state.addCareerRequestStatus = STATUS.FETCHING
        },
        [addCareerRequest.fulfilled]: (state, action) => {
            state.addCareerRequestStatus = STATUS.SUCCESS;
            state.careerRequest = action.payload;
        },
        [addCareerRequest.rejected]: (state) => {
            state.addCareerRequestStatus = STATUS.FAILED
        },

        [addVisiterRequest.pending]: (state) => {
            state.addVisiterRequestStatus = STATUS.FETCHING
        },
        [addVisiterRequest.fulfilled]: (state, action) => {
            state.addVisiterRequestStatus = STATUS.SUCCESS;
            state.visiterRequest = action.payload;
        },
        [addVisiterRequest.rejected]: (state) => {
            state.addVisiterRequestStatus = STATUS.FAILED
        },
    }
})

export const { resetFormStatus, extraReducers } = formSlice.actions
export const formReducer = formSlice.reducer