/* eslint-disable no-dupe-keys */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  HStack,
  Table,
  Tr,
  Th,
  Td,
  Flex,
  List,
  ListItem,
  Tooltip,
  Divider,
  Wrap,
  WrapItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  useToast,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import { useQueryParams } from "../../utils/useQueryParams";
import { useDispatch, useSelector } from "react-redux";
import {
  getSinglePackageAction,
  resetGetSinglePkg,
} from "../../redux/reducers/packages";
import { useHistory } from "react-router";
import { STATUS } from "../../App/Constants";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { bilingualText, getPackagePrice } from "../../utils/Helper";
import { useCart } from "../../Cart/useCart";
import { GiCheckMark } from "react-icons/gi";
import _, { filter, find, map, round, size, toLower } from "lodash";
import { apis } from "../../services/api/apis";
import { StarIcon, ViewIcon } from "@chakra-ui/icons";
import { IoCart } from "react-icons/io5";
import { AiOutlineEye, AiOutlineFileText } from "react-icons/ai";
import PackageReviews from "./PackageReviews";
import {
  resetGetPublicContent,
  getPublicContentAction,
} from "../../redux/reducers/courses";
import { useIsAuthenticated } from "../../App/Context";
import { BsEye } from "react-icons/bs";
import moment from "moment";
import {
  InfoOfflinePackage,
  OfflinePackageRegistration,
} from "./OfflinePackageRegistration";

export const PackageDetails = (props) => {
  const dispatch = useDispatch();
  let query = useQueryParams();
  const slug = query.get("slug");
  const id = query.get("id");
  const { getPkgStatus, currentPkg, getPublicContentStatus } = useSelector(
    (state) => ({
      getPkgStatus: state.package?.getSinglePackgStatus,
      currentPkg: state.package.currentPackage?.[0],
      getPublicContentStatus: state.course.getPublicContentStatus,
    })
  );
  const isAuthenticated = useIsAuthenticated();

  const { isPackagePurchased } = useCart();
  const isPurchased = useMemo(
    () => isPackagePurchased(id),
    [id, isPackagePurchased]
  );

  useEffect(() => {
    console.log("_++++++++++++++++++++++++++++++++++++");
    dispatch(
      getSinglePackageAction({
        id,
        slug,
        isAuthenticated,
        isPurchased,
        test: true,
      })
    );
    return () => {
      dispatch(resetGetPublicContent());
      dispatch(resetGetSinglePkg());
    };
  }, [slug, id, dispatch, isAuthenticated, isPurchased]);

  useEffect(() => {
    if (currentPkg?.courses?.length) {
      dispatch(
        getPublicContentAction({
          courseIds: currentPkg.courses.map((c) => c._id),
        })
      );
    }
  }, [currentPkg, dispatch]);

  const isOfflineTestSeriesPackage = currentPkg?.isOfflineTestSeriesPackage;
  return (
    <Box p={[5, 50]}>
      <ErrorChecker status={getPkgStatus}>
        <ErrorChecker status={getPublicContentStatus}>
          {getPkgStatus === STATUS.SUCCESS && currentPkg ? (
            <Box>
              <PackageCard
                currentPkg={currentPkg}
                courses={currentPkg.courses}
              />
              {/* <br /> */}

              {/* <HStack p="2rem" width="100%" spacing="3rem">
              {currentPkg.description.en && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: currentPkg.description.en,
                  }}
                />
              )}
              {currentPkg.description.hn && (
                <Text
                  dangerouslySetInnerHTML={{
                    __html: currentPkg.description.hn,
                  }}
                />
              )}
            </HStack> */}

              <br />
              {/* <Box>
                <Text fontSize="lg">Content included in the package:</Text>
                <br />
                {currentPkg.courses?.length ? (
                  <Courses currentPkg={currentPkg} />
                ) : null} */}

              {/* {currentPkg.books?.length ? (
                  <BooksTable currentPkg={currentPkg} />
                ) : null} */}
              {/* {currentPkg.magazines?.length ? (
                  <MagazinesTable currentPkg={currentPkg} />
                ) : null}
                {currentPkg.drives?.length ? (
                  <DrivesTable currentPkg={currentPkg} />
                ) : null} */}
              {/* {currentPkg.assignments?.length ? (
                  <AssignmentsTable currentPkg={currentPkg} />
                ) : null}
                {currentPkg.tests?.length ? (
                  <TestsTable currentPkg={currentPkg} />
                ) : null} */}
              {/* </Box> */}
              <Divider />
              <br />
              <br />
            </Box>
          ) : (
            <Text>Something went wrong</Text>
          )}
        </ErrorChecker>
        {currentPkg && !isOfflineTestSeriesPackage ? <PackageReviews currentPkg={currentPkg} /> : null}
      </ErrorChecker>
    </Box>
  );
};

const PackageCard = ({ currentPkg }) => {
  const history = useHistory();

  const {
    // isPackageInCart,
    isPackagePurchased,
    unlockPackage,
    // registerForPackage,
  } = useCart();
  const isPurchased = isPackagePurchased(currentPkg._id);
  console.log({isPurchased})
  // const itemInCart = !isPurchased && isPackageInCart(currentPkg._id);

  const { publicContent } = useSelector((state) => ({
    publicContent: state.course.publicContent,
  }));

  const [demoCount, setDemoCount] = useState(0);

  // const isAuthenticated = useIsAuthenticated();
  useEffect(() => {
    if (publicContent?.subjects?.length) {
      let count = _.reduce(
        publicContent.subjects,
        (sum, sub) =>
          sum +
          _.sum([
            sub.audios?.length,
            sub.videos?.length,
            sub.documents?.length,
            sub.texts?.length,
            sub.test?.length,
          ]),
        0
      );
      setDemoCount(count);
    } else {
      setDemoCount(0);
    }
  }, [publicContent]);

  const _buyNow = () => {
    // addPackageToCart(currentPkg._id, () => {
      history.push("/checkout?id=" + currentPkg._id + "&name=" + currentPkg?.name.en);
    // });
  };



  const { price, fakePrice, offer } = getPackagePrice(currentPkg);
  const free = !price || price === 0 || price === "0";
  const carousel =
    (offer && size(offer.carousel) && offer.carousel) ||
    (size(currentPkg.carousel) && currentPkg.carousel);

  const imgUrl = carousel && carousel[0];

  // const toDemo = () => {
  //   document
  //     .getElementById("courseTable")
  //     ?.scrollIntoView({ behavior: "smooth" });
  // };

  const handleUnlockPackage = () => {
    unlockPackage({ packages: [currentPkg._id] });
  };

  const handleViewPkg = () => {
    if (currentPkg.type === "TEST")
      history.push("/dashboard/test-packages/" + currentPkg._id);
    else if (currentPkg.type === "MAGAZINE")
      history.push("/dashboard/magazines/");
    else history.push("/dashboard/courses/");
  };

  const isOfflineTestSeriesPackage = currentPkg.isOfflineTestSeriesPackage;
  return (
    <Box bg="white">
      <Flex flexWrap="wrap" background="white" p={10}>
        {/* <Button onClick={() => history.push('/package-demo/'+currentPkg._id)}>Demo</Button> */}
        {carousel?.length ? (
          <Box flex={1} align="center">
            <Image
              src={imgUrl}
              fallbackSrc="https://via.placeholder.com/200x200.png/FAFAFA/C7C7C7?text=PRAYAS"
              mb={5}
              style={{
                width: "100%",
                height: "auto",
                minWidth: "200px",
                maxWidth: "400px",
              }}
            />
            {offer ? (
              <Flex
                flexDirection="column"
                alignItems="center"
                p={6}
                mt={10}
                borderRadius={"sm"}
                border="0.6px solid"
                borderColor="gray.100"
              >
                <Text color="accent.600" fontSize="sm">
                  * Offer Applied *
                </Text>
                <Text fontSize="lg">{offer.title}</Text>
              </Flex>
            ) : null}
          </Box>
        ) : null}
        <Box flex={3} px={6}>
          <Flex
            flexWrap={["wrap", "nowrap"]}
            mt={[4, 0]}
            spacing={6}
            justifyContent="space-between"
          >
            <Box pr={8}>
              <Heading
                fontWeight="400"
                color="gray.800"
                fontSize="20px"
                marginBottom="10px"
              >
                {bilingualText(currentPkg.name)}
              </Heading>

              <Wrap align="center" spacing={5}>
                <WrapItem>
                  <HStack
                    spacing={1}
                    // p={1}
                    alignItems="center"
                    borderRadius={"sm"}
                    // border="0.6px solid"
                    borderColor="gray.100"
                  >
                    <Box fontSize="sm">{currentPkg.rating || 4.9}</Box>
                    <StarIcon color="yellow.400" fontSize="12px" />
                  </HStack>
                </WrapItem>
                {isOfflineTestSeriesPackage ? null : (
                  <WrapItem>
                    <HStack mt={2}>
                      {fakePrice && price ? (
                        <Text
                          fontSize={["xs", "sm", "md"]}
                          textDecoration="line-through"
                        >
                          ₹ {fakePrice}
                        </Text>
                      ) : null}
                      <Heading fontSize={["sm", "md", "lg"]}>
                        {free ? (
                          <span style={{ color: "#2ECC71" }}>Free</span>
                        ) : (
                          "₹" + price
                        )}
                      </Heading>
                      {currentPkg.gst && price ? (
                        <Text fontSize={["xs", "xs", "sm"]} color="success.500">
                          (+{round(currentPkg.gst, 2)}% GST Extra)
                        </Text>
                      ) : null}
                    </HStack>
                  </WrapItem>
                )}
              </Wrap>
            </Box>
            <Box>
              {currentPkg.demoContent?.[0] &&
              _.concat(
                currentPkg.demoContent[0].texts,
                currentPkg.demoContent[0].documents,
                currentPkg.demoContent[0].videos,
                currentPkg.demoContent[0].audios, 
                currentPkg.demoContent[0].tests
              ).length ? (
                <>
                  <Button
                    colorScheme={"green"}
                    leftIcon={<ViewIcon />}
                    color="white"
                    onClick={() =>
                      history.push("/package-demo/" + currentPkg._id)
                    }
                  >
                    Demo
                  </Button>
                  {/* <Image
                    w="100px"
                    className="mobile-hide"
                    cursor="pointer"
                    onClick={() => history.push('/package-demo/'+currentPkg._id)}
                    src="/images/demo-2.jpeg"
                  /> */}
                </>
              ) : null}
            </Box>
          </Flex>

          <Box style={{ marginTop: "20px" }}>
            {isPurchased ? (
              <div>
                <HStack spacing={8}>
                  <div
                    style={{
                      alignItems: "center",
                      fontWeight: "bold",
                      color: "green",
                      display: "flex",
                      fontSize: "16px",
                    }}
                  >
                    <div style={{ paddingRight: "10px" }}>
                      <GiCheckMark display="inline" />
                    </div>
                    <div>Purchased</div>
                  </div>
                  <Button
                    width={100}
                    onClick={handleViewPkg}
                    leftIcon={<BsEye />}
                    background="brand.blue"
                    color={"white"}
                  >
                    View
                  </Button>
                </HStack>
                <PurchasedView pkg={currentPkg} />
              </div>
            ) : free ?currentPkg.isOfflineTestSeriesPackage ? 
            (<Box>
              -
            </Box>) : 
            (
              <Button
                size="md"
                leftIcon={<IoCart />}
                onClick={handleUnlockPackage}
                colorScheme={"primary"}
              >
                Unlock Package
              </Button>
            ) : currentPkg.published === 2 ? (
              <>
                {/* <Image w="100px" src='/images/coming-soon.png'/>  */}
                <Text
                  colorScheme="white"
                  style={{
                    color: "#5DADE2",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  Coming soon
                </Text>
              </>
            ) : !isOfflineTestSeriesPackage ? (
              <Button
                size="md"
                variant="outline"
                leftIcon={<IoCart />}
                onClick={_buyNow}
                colorScheme={"accent"}
              >
                Buy Now
              </Button>
            ) : null}
          </Box>

          {/* <ActionButtons /> */}
          {/* 
          <Box mt={10}>
            <Text fontSize="lg">IN THIS PACKAGE</Text>
          </Box> */}
          {currentPkg.description?.en && (
            <Box mb="1rem" mt={10} color="gray.800">
              {currentPkg.description?.en?.startsWith("<") ? (
                <Box>
                  <Text
                    fontSize="sm"
                    dangerouslySetInnerHTML={{
                      __html: currentPkg.description?.en,
                    }}
                  />
                </Box>
              ) : (
                <Box
                  fontSize="md"
                  my={4}
                  fontFamily="Lato"
                  as="pre"
                  sx={{
                    "white-space": "-moz-pre-wrap",
                    "white-space": "-o-pre-wrap",
                    "word-wrap": "break-word",
                    "white-space": "pre-wrap",
                  }}
                >
                  {currentPkg.description?.en}
                </Box>
              )}
            </Box>
          )}

          {currentPkg.timetable ? (
            <Box
              mt={10}
              border="1px solid #D6DBDF"
              borderRadius={3}
              p={3}
              _hover={{ background: "#ECF0F1" }}
              transition="background .6s"
            >
              <HStack justify="space-between">
                <HStack>
                  <AiOutlineFileText fontSize="20px" />
                  <Text>Timetable</Text>
                </HStack>
                <Tooltip label="view">
                  <AiOutlineEye
                    fontSize="20px"
                    onClick={() => window.open(currentPkg?.timetable?.url)}
                    cursor="pointer"
                  />
                </Tooltip>
              </HStack>
            </Box>
          ) : null}

          {!isOfflineTestSeriesPackage && currentPkg.files?.length ? (
            <List p={3} borderRadius="4px" bg="red">
              {currentPkg.files.map((file) => (
                <ListItem
                  border="1px solid #D6DBDF"
                  borderRadius={3}
                  p={3}
                  key={file._id}
                  _hover={{ background: "#ECF0F1" }}
                  transition="background .6s"
                >
                  <HStack justify="space-between">
                    <HStack>
                      <AiOutlineFileText fontSize="20px" />
                      <Text>{file.name}</Text>
                    </HStack>
                    <Tooltip label="view">
                      <AiOutlineEye
                        fontSize="20px"
                        onClick={() => window.open(file.url)}
                        cursor="pointer"
                      />
                    </Tooltip>
                  </HStack>
                </ListItem>
              ))}
            </List>
          ) : null}
          <br />
          <Box></Box>
        </Box>
      </Flex>
      {isOfflineTestSeriesPackage ? (
        <OfflinePackage currentPkg={currentPkg} />
      ) : null}
    </Box>
  );
};

const OfflinePackage = ({ currentPkg }) => {
  const endDate = currentPkg.endDate//"2023-05-20T00:00:00.000Z"
  const isBefore = moment(currentPkg.startDate).isAfter(moment());

  const isAfter = moment(endDate).set("h", 23).set("minute",59).isBefore(moment());
  // console.log({isBefore, isAfter, d: currentPkg.startDate, e: endDate})
  const isAvailable = !isBefore && !isAfter;

  return isAvailable ? (
    <OfflinePackageRegistration currentPkg={currentPkg} />
  ) : (
    <Box p={[4, 10, 20]}>
      <InfoOfflinePackage currentPkg={currentPkg} />

      <OfflinePackageExtra currentPkg={currentPkg} />
    </Box>
  );
};

const OfflinePackageExtra = ({ currentPkg }) => {
  const files = currentPkg?.files?.length ? currentPkg.files : null;

  const answerFiles = filter(files, (f) => {
    return !toLower(f.name).startsWith("answer");
  });
  return (
    <Box>
      <Box mb={10} color="gray.400">
        Registration not available
      </Box>
      <Tabs colorScheme="green">
        <TabList>
          {!answerFiles?.length ? <Tab>Download Admit Card</Tab> : null}
          <Tab>Answer Key</Tab>
          <Tab>Result</Tab>
        </TabList>
        <TabPanels>
          {!answerFiles?.length ? (
            <TabPanel>
              <AdmitCardForm currentPkg={currentPkg} />
            </TabPanel>
          ) : null}

          <TabPanel>
            <AnswerFiles currentPkg={currentPkg} />
          </TabPanel>

          <TabPanel>
            <AnswerFiles currentPkg={currentPkg} mode="result" />
          </TabPanel>
          {/* <TabPanel>
      <p>two!</p>
    </TabPanel> */}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const AnswerFiles = ({ currentPkg, mode = "answer" }) => {
  const files = currentPkg?.files?.length ? currentPkg.files : null;
  const answerFiles = filter(files, (f) => {
    if (mode === "result") {
      return !toLower(f.name).startsWith("answer");
    }
    return toLower(f.name).startsWith("answer");
  });

  console.log({ files, answerFiles });
  return (
    <Box>
      {mode === "result" ? (
        <Text>Result files </Text>
      ) : (
        <Text>Answer key files </Text>
      )}
      {map(answerFiles, (a) => {
        const _view = () => {
          window.open(a.url, "_blank");
        };
        return (
          <Flex key={a._id} p={4} m={1} bg="gray.50">
            <Box flex={1}>
              <Text>{a.name}</Text>
            </Box>
            <Box flex={1}>
              <Button size="sm" colorScheme="green" onClick={_view}>
                view
              </Button>
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};

const AdmitCardForm = ({ currentPkg }) => {

  const [authRolls, setauthRoll] = useState();
  const [loading, setLoading] = useState(false);

  const [inputroll, setinputRoll] = useState("");
  const [inputMobile, setinputMobile] = useState("");
  const toast = useToast();

  const fetchUserRoll = useCallback(
    async (mobile) => {
      if (!mobile) {
        return toast({
          status: "error",
          title: "Not valid mobile no ",
          description: "Please provide a valid mobile no ",
        });
      }
      setLoading(true);
      const res = await apis.checkValidrollNo({
        mobile,
        packageId: currentPkg?._id,
      });
      setLoading(false);
      if (res.ok && res.data?.length) {
        setauthRoll(res.data);
      } else {
        setauthRoll();
        toast({
          status: "error",
          title: "Not valid mobile no ",
          description: "No roll number found for this mobile no ",
        });
      }
    },
    [currentPkg?._id, toast]
  );

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     fetchUserRoll();
  //   }
  // }, [fetchUserRoll, isAuthenticated]);

  const checkRollNo = useCallback(
    async (roll) => {
      if (!roll) {
        toast({
          status: "error",
          title: "not valid input",
          description: "Please provide a valid roll number",
        });

        return;
      }
      const res = await apis.checkValidrollNo({ roll });
      if (res.ok && res.data?.finalRoll === roll) {
        window.open("?dr=" + res.data?.finalRoll);
      } else {
        toast({
          status: "error",
          title: "not valid input",
          description: "Please provide a valid roll number",
        });
      }
    },
    [toast]
  );

  // const checkContactNo = useCallback(
  //   async (mobile) => {
  //     if (!mobile) {
  //       toast({
  //         status: "error",
  //         title: "not valid input",
  //         description: "Please provide a valid mobile number",
  //       });

  //       return;
  //     }
  //     const res = await apis.checkValidrollNo({
  //       mobile,
  //       packageId: currentPkg?._id,
  //     });
  //     if (res.ok && res.data?.finalRoll === mobile) {
  //       window.open("?dr=" + res.data?.finalRoll);
  //     } else {
  //       toast({
  //         status: "error",
  //         title: "not valid input",
  //         description: "Please provide a valid roll number",
  //       });
  //     }
  //   },
  //   [currentPkg?._id, toast]
  // );

  const _downloadAdmitCard = (finalRoll) => {
    window.open("?dr=" + finalRoll);
  };

  return (
    <Box>
      {/* <Box>Download Admit Card</Box> */}

      <Box my={3}>
        <Box>
          <Text mb={2}>Enter your Roll number to download admit card</Text>
          <Input
            value={inputroll}
            onChange={(e) => setinputRoll(e.target.value)}
            placeHolder="Enter Roll number"
            w={["100%", "80%", "40%"]}
          />
        </Box>
        <Button
          onClick={(e) => {
            e.preventDefault()
            checkRollNo(inputroll);
          }}
          mt={3}
          colorScheme="primary"
        >
          Download Admit Card
        </Button>
        <Box my={10} color="gray.500">
          Or
        </Box>
        <Box>
          <Text mb={2}>Enter your mobile number to download admit card</Text>
          <Input
            maxLength={10}
            value={inputMobile}
            onChange={(e) => setinputMobile(e.target.value)}
            placeHolder="Enter mobile number"
            w={["100%", "80%", "40%"]}
          />
        </Box>
        <Button
          onClick={() => {
            fetchUserRoll(inputMobile);
          }}
          mt={3}
          colorScheme="primary"
        >
          Search Roll No
        </Button>
        <br />
        <br />
        {authRolls?.length ? (
          <Table size="sm" width={"50%"} className="table-border">
            <Thead>
              <Tr>
                <Th>Roll No</Th>
                <Th>User Name</Th>
                <Th>Package</Th>
              </Tr>
            </Thead>
            <Tbody>
              {authRolls.map((roll) => (
                <Tr key={roll._id}>
                  <Td>{roll.finalRoll}</Td>
                  <Td>{roll.user.name}</Td>
                  <Td>{bilingualText(roll.package.name)}</Td>
                  <Td>
                    <Button
                      size="sm"
                      onClick={() => _downloadAdmitCard(roll.finalRoll)}
                    >
                      Download Admit Card
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : null}
        {/* <Box my={10} color="gray.500">
          Or
        </Box>
        {isAuthenticated ? (
          <Box>
            {loading ? (
              <Spinner />
            ) : (
              <Box>
                {authRoll?.map((r) => {
                  return (
                    <Box key={r._id} my={3} p={10} bg="green.100">
                      <Text> roll number : {r.finalRoll}</Text>
                      <Text>name : {r?.user?.name}</Text>
                    </Box>
                  );
                }) || <Box>No admit card found for logged in user</Box>}
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Box>Login using mobile number to check for admit card</Box>
            <Button
              onClick={toggleLoginModal}
              my={3}
              colorScheme="primary"
              variant="outline"
            >
              Login Now
            </Button>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

const PurchasedView = ({ pkg }) => {
  const roll = pkg.roll;
  return roll ? (
    <Box mt={3}>
      <Text color="gray.800">
        Roll No :{" "}
        <span>
          <a
            href={"http://" + window?.location?.host + "/?dr=" + roll.finalRoll}
            target="_blank"
            rel="noreferrer"
          >
            <u>{roll.finalRoll}</u>
          </a>
        </span>
      </Text>
    </Box>
  ) : null;
};
