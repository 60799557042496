import {
  Center,
  Box,
  Flex,
  VStack,
  Heading,
  HStack,
  Icon,
  Image,
  Button,
} from "@chakra-ui/react";
import HeadingX from "../common/headingX";
import { BiTime } from "react-icons/bi";
import { map, orderBy } from "lodash";
import dayjs from "dayjs";
import { FromBottomAnimation } from "../common/animation";

export default function LatestNews({ data }) {
  return (
    <Box py={20}>
      <HeadingX textAlign="center">Notice and Upcoming Events</HeadingX>
      <Center mt={2}>
        {/* <Box
          maxW={{ base: "80%", md: "60%" }}
          color="gray.500"
          textAlign="center"
        >
          No one shall be subjected to arbitrary arrest, detention or exile.
          Everyone is entitled in full equality to a fair.
        </Box> */}
      </Center>
      <Flex mt={20} align="center">
        <Box
          mt={-10}
          flex={{ base: 0.75, lg: 1 }}
          w={{ md: "40%" }}
          display={{ base: "none", md: "flex" }}
          bg="gray.350"
          align="center"
        >
          <Image
            ml={4}
            src={"assets/updates.jpeg"}
            // src="assets/latest_news.png"
            alt="updates"
            w="80%"
          />
        </Box>
        <VStack flex={1} spacing={{ base: 20, md: 10 }} px={10}>
          {map(orderBy(data, "createdAt", "desc"), (news) => (
            <Events key={news._id} data={news} />
          ))}
        </VStack>
      </Flex>
    </Box>
  );
}

const Events = ({ key, data }) => {
  return (
    <FromBottomAnimation
      key={key}
      pos="relative"
      minH={100}
      borderRadius={5}
      w="full"
      border="1px solid"
      borderColor="gray.200"
      px={{ base: 3, md: 14 }}
      pt={{ base: 12, md: 6 }}
      pb={{ base: 8, md: 6 }}
    >
      <Box>
        <Box fontSize="lg">{data.title}</Box>
        <Box color="gray.500" mt={2}>
          {data.body}
        </Box>
        {data.startDate ? (
          <Box mt={4} color="gray.600" fontSize={"sm"}>
            <Icon fontSize={25} mr={2}>
              <BiTime />
            </Icon>{" "}
            {dayjs(data.startDate).format("DD-MM-YYYY")} -{" "}
            {dayjs(data.endDate).format("DD-MM-YYYY")}
          </Box>
        ) : null}
        {data.buttonName ? (
          <Flex justifyContent={"flex-end"}>
            <Button
              pos="absolute"
              fontWeight="normal"
              onClick={() => window.open(data.link)}
            >
              {data.buttonName}
            </Button>
          </Flex>
        ) : null}
        <Center
          boxShadow="md"
          pos="absolute"
          left={{ base: "50%", md: 0 }}
          top={{ base: 0, md: "50%" }}
          transform="translate(-50%, -50%)"
          w={20}
          h={20}
          borderRadius={10}
          bg="default.500"
        >
          <Box textAlign="center">
            <Heading color="white">
              {dayjs(data.createdAt).format("DD")}
            </Heading>
            <Box color="gray.50">{dayjs(data.createdAt).format("MMM")}</Box>
          </Box>
        </Center>
      </Box>
    </FromBottomAnimation>
  );
};
