import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Wrap,
} from "@chakra-ui/react";
import { SectionHeader } from "../../Components/SectionHeader";
import {  SearchIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getWishlistAction,
  rmvFromWishlistAciton,
} from "../../redux/reducers/wishlist";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { STATUS } from "../../App/Constants";
import { Empty } from "../../Components/Empty";
import { useHistory } from "react-router";
import { Card } from "../../Components/ItemCard";

export const Wishlist = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getWishlistStatus, wishlistPackages, rmvFromWishlistStatus } =
  useSelector((state) => ({
    getWishlistStatus: state.wishlist.getWishlistStatus,
    wishlistPackages: state.wishlist.wishlistPackages,
    rmvFromWishlistStatus: state.wishlist.rmvFromWishlistStatus,
  }));

  const [pkgList, changePkgList] = useState([])

  useEffect(() => {
    if(wishlistPackages?.packages){
      changePkgList(wishlistPackages.packages)
    }
  }, [wishlistPackages?.packages])

  useEffect(() => {
    dispatch(getWishlistAction());
  }, [dispatch]);

  const removeFromWishlist = (id) => {
    dispatch(rmvFromWishlistAciton({ packageId: id }));
  };

  const searchPkg = (e) => {
    let searchText = e.target.value
    let data = _.filter(wishlistPackages.packages,d => _.includes(_.toLower(d.name?.en), _.toLower(searchText)))
    changePkgList(data)
  }

  return (
    <Box>
      <SectionHeader
        title="My Wishlist"
        breadcrumbs={[
          { title: "Home", link: "/" },
          { title: "My Wishlist", link: "#" },
        ]}
      />

      <Box boxShadow="rgba(0, 0, 0, 0.08) 0px 2px 4px" background='white' p={6}>
            <Box>
              <HStack
                borderBottom="2px solid #EBEDEF"
                paddingBottom={6}
                justifyContent="space-between"
                m="10px 0"
              >
                <Box fontWeight="bold" fontSize="lg">
                  WISHLIST{" "}
                  <span style={{ color: "#AEB6BF" }}>
                    ({pkgList?.length || 0})
                  </span>
                </Box>
                <HStack>
                  {/* <Box>
                    <Select width="250px" placeholder="show">
                      <option></option>
                    </Select>
                  </Box> */}
                  <Box>
                    <InputGroup>
                      <InputLeftElement
                        pointerEvents="none"
                        children={<SearchIcon color="gray.300" />}
                      />
                      <Input onChange={searchPkg} placeholder="Search" />
                    </InputGroup>
                  </Box>
                </HStack>
              </HStack>
              <ErrorChecker status={getWishlistStatus}>
                {getWishlistStatus == STATUS.SUCCESS &&
                  pkgList?.length ? (
                    <Wrap spacing={10} alignItems="stretch" marginTop={6}>
                      {wishlistPackages.packages.map((pkg, i) => (
                        <Card key={i} item={pkg} removePkg={removeFromWishlist} />
                      ))}
                    </Wrap>
                    ) : (
                  <Empty
                    title="Empty wishlist"
                    subtitle="Add Items in wishlist to see here"
                    cta={{ text: "Keep Exploring", action: () => history.push("/") }}
                  />
                )}
              </ErrorChecker>
            </Box>
      </Box>
    </Box>
  );
};


