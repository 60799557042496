import { Box, HStack, Text, VStack, Wrap } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkExpiry } from '../../utils/Helper'
import { VideoCard } from '../Contents/VideoFiles'

export const VideoBookmarks = ({content}) => {
    const {courseList, user} = useSelector(state => ({
        courseList:state.package.packageContents?.courses,
        user:state.user.student
    }))
    //user->student

    let list = _.chain(content).map(d => {
        let course = courseList?.length ? _.find(courseList,c => c._id == d.courseId) : []
        let assignedCourseDetails = user.courses?.length ? _.find(user.courses,c => c.course == d.courseId) : []
        return course ? ({...d, ..._.omit(assignedCourseDetails, ['_id']), course:{name:course.name}}) : d
    }).filter(d => d.course && checkExpiry(d.expireOn)).value()

    return(
        <Box>
                {list?.length ? 
                    <Wrap>
                        {list.map(vid =>
                            <VideoCard key={vid._id} bookmark={list} video={vid}/>
                        )}
                    </Wrap>
                    :
                    <Text>No video added</Text>
                }
        </Box>
    )
}