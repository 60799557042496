import React from 'react'
import { Heading } from '@chakra-ui/react'

export default function HeadingX(props) {
    

    return (
        <Heading fontSize={{ base: "3xl", md: "4xl" }} {...props}>
            {props.children}
        </Heading>
    )
}
