import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem, HStack, IconButton, Image, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack, useToast } from '@chakra-ui/react'
import _, { flatMap, map, size } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom';
import { STATUS } from '../../App/Constants';
import { useIsAuthenticated } from "../../App/Context"; 
import { SectionHeader } from '../../Components/SectionHeader';
import { getPackageDemoAction, getSinglePackageAction } from '../../redux/reducers/packages';
import { bilingualText } from '../../utils/Helper';
import { useQueryParams } from '../../utils/useQueryParams';
import { AudioCard } from '../Contents/AudioFiles';
import { VideoCard } from '../Contents/VideoFiles';
import { EbookModal } from './EbookModal';
import { PDFModal } from './PDFModal';
import { toggleExamWindowAction } from '../../redux/reducers/onlineExam';
import { ExamWindow } from '../../Components/ExamWindow';
import { useLoginModal } from "../../App/useLoginModal";

export const PackageDemoContent = () => {
    const dispatch = useDispatch()
    const isAuthenticated = useIsAuthenticated();
    const {toggleLoginModal} = useLoginModal()
    const params = useParams()
    const packageId = params.packageId
    let query = useQueryParams();
    const slug = query.get("slug");
    const history = useHistory()

    const {currentPackage} =  useSelector(state => ({
        currentPackage:state.package.packageDemoContent?.[0]
    }))

    useEffect(() => {
        dispatch(getPackageDemoAction({ id:packageId}));
    }, [packageId, dispatch])

    const handleBack = () => {
        history.push('/package?id='+currentPackage._id)
    }

    const breadcrumbs = [
        { title: "Home", link: "/" },
        { title:currentPackage ? bilingualText(currentPackage.name) : null, link: "/package?id=" + packageId},
        { title: 'Demo', link: '#'},
    ]

    const demoContent = currentPackage?.demoContent

    const startExam = (test, attemptCheck) => {
        if (isAuthenticated) {
            localStorage?.removeItem("testId");
            localStorage?.removeItem("attemptId");
            localStorage.setItem("packageId", params.packageId);

            const attemptId = attemptCheck
                ? _.head(_.orderBy(test?.userAttempts, ["createdAt"], ["desc"]))._id
                : null;
            const attemptStatus = attemptCheck ? "Resume" : "Start";

            // history.push(`/exam/start/?testId=${test._id}&testAtemptId=${attemptId}&mode=${attemptStatus}`)

            dispatch(
                toggleExamWindowAction({
                    newWindow: true,
                    redirect: false,
                    testId: test._id,
                    attemptId,
                    attemptStatus,
                })
            );
        }
        else {
            toggleLoginModal()
        }
    };
    return (
        <Box p={[5, 50]}>
        <ExamWindow />
            <SectionHeader title='Demo' breadcrumbs={breadcrumbs}/>

            <Box mb={4} bg='white' boxShadow='rgba(149, 157, 165, 0.1) 0px 4px 12px' p={10}>
                    <Button variant='link' leftIcon={<ArrowBackIcon fontSize={20}/>} onClick={handleBack} >
                        {bilingualText(currentPackage?.name)}
                    </Button>
                <br/><br/>
                <Tabs colorScheme='red' >
                    <TabList>
                        <Tab _focus={{ boxShadow: '0 0 0 3px #e02a1f75' }}>Video</Tab>
                        <Tab _focus={{ boxShadow: '0 0 0 3px #e02a1f75' }}>PDF</Tab>
                        <Tab _focus={{ boxShadow: '0 0 0 3px #e02a1f75' }}>eBook</Tab>
                        <Tab _focus={{ boxShadow: '0 0 0 3px #e02a1f75' }}>Audio</Tab>
                        <Tab _focus={{ boxShadow: '0 0 0 3px #e02a1f75' }}>Test</Tab>

                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <br/>
                            <ContentDescription  content={demoContent?.videos?.length} description={demoContent?.description}/>
                            <br/>
                            {demoContent?.videos?.length ?
                                <Flex flexWrap={'wrap'}>
                                    {_.orderBy(demoContent.videos, 'oreder', 'asc').map(vid =>
                                        <VideoCard demo key={vid._id} video={vid} />
                                    )}
                                </Flex>
                                :
                                null
                            }
                        </TabPanel>
                        <TabPanel>
                            <br/>
                            <ContentDescription  content={demoContent?.documents?.length} description={demoContent?.description}/>
                            <br/>
                            {demoContent?.documents?.length ?
                                <Flex wrap={'wrap'} alignItems={'stretch'} spacing={10}>
                                    {_.orderBy(demoContent.documents, 'order', 'asc').map(pdf =>
                                        <PdfCard demo key={pdf._id} doc={pdf} />
                                    )}
                                </Flex>
                                :
                                null
                            }
                        </TabPanel>
                        <TabPanel>
                            <br/>
                            <ContentDescription  content={demoContent?.texts?.length} description={demoContent?.description}/>
                            <br/>
                            {demoContent?.texts?.length ?
                                <Flex flexWrap={'wrap'}>
                                    {_.orderBy(demoContent.texts, 'order', 'asc').map(pdf =>
                                        <EbookCard demo key={pdf._id} doc={pdf} />
                                    )}
                                </Flex>
                                :
                                null
                            }
                        </TabPanel>
                        <TabPanel>
                            <br/>
                            <ContentDescription  content={demoContent?.audios?.length} description={demoContent?.description}/>
                            <br/>
                            {demoContent?.audios?.length ?
                                <Flex flexWrap={'wrap'}>
                                    {_.orderBy(demoContent.audios, 'order', 'asc').map(aud =>
                                        <Box key={aud._id} pb={8}>
                                            <AudioCard demo audio={aud} />
                                        </Box>
                                    )}
                                </Flex>
                                :
                                null
                            }
                        </TabPanel>
                        <TabPanel>
                            <br />
                            <ContentDescription content={demoContent?.tests?.length} description={demoContent?.description} />
                            <br />
                            {demoContent?.tests?.length ?
                                <Table size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Name</Th>
                                            <Th>Duration</Th>
                                            <Th>No. of questions</Th>
                                            <Th>Action</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {map(demoContent?.tests, test => {
                                            return (
                                                test.data ?
                                                    <Tr>
                                                        <Td>{test.data.name?.en}</Td>
                                                        <Td>{test.data.totalTime}</Td>
                                                        <Td>{size(flatMap(test.data.sections, 'questions'))}</Td>
                                                        <Td><Button size="xs" variant='outline' colorScheme='blue' onClick={() => startExam(test.data, false)}>Attempt</Button></Td>
                                                    </Tr>
                                                    :
                                                    null
                                            )
                                        })}
                                    </Tbody>
                                </Table>
                                :
                                null
                            }
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            
        </Box>
    )
}

const ContentDescription = ({description, content}) => {
    const {currentPackage} =  useSelector(state => ({
        currentPackage:state.package.packageDemoContent?.[0]
    }))

    return(
        <Box p={5} boxShadow={'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}>
            <Text fontSize={20}>{currentPackage ? bilingualText(currentPackage.name) : null}</Text>
            {content ? 
                <Box borderRadius='6px' fontSize={16} py={3}
                    fontFamily="Lato"
                    as="pre"
                    sx={{
                        "white-space": "-moz-pre-wrap",
                        "white-space": "-o-pre-wrap",
                        "word-wrap": "break-word",
                        "white-space": "pre-wrap",
                    }}
                >
                    {description}
                </Box>
                :
                <Box py={3}>
                    <Text>Content will be available soon...</Text>
                </Box>
                
            }
        </Box>
    )
}

const PdfCard = ({doc}) => {
    const [showModal, openShowModal] = useState()

    const handleOpen = () => {
        openShowModal(d => !d)
    }

    return(
        <>
            <Box width={200} p={'10px 10px 10px 0'} cursor='pointer' onClick={handleOpen} >
                <VStack>
                    <Image src={doc.thumbnail || '/prayasEduhub.png'} border={"1px solid"} borderColor={"gray.300"} objectFit='cover' width={100} height={150} />
                    <Tooltip label={doc.name}>
                        <Text textAlign={'center'} fontSize={13} >
                            {doc.name.length > 60 ? `${doc.name.substring(0, 60)}...` : doc.name}
                        </Text>
                    </Tooltip>
                </VStack>
            </Box>
            {showModal ? <PDFModal visible={showModal} closeModal={handleOpen} document={doc} /> : null}
        </>
    )
}

const EbookCard = ({doc}) => {
    const [showModal, openShowModal] = useState()

    const handleOpen = () => {
        openShowModal(d => !d)
    }

    return(
        <>
            <Box width={200} p={'10px 10px 10px 0'} cursor='pointer' onClick={handleOpen} >
                <VStack>
                    <Image src={doc.thumbnail} objectFit='cover' width={100} height={150} />
                    <Text textAlign={'center'} fontSize={13} >{doc.name}</Text>
                </VStack>
            </Box>
            {showModal ? <EbookModal visible={showModal} closeModal={handleOpen} document={doc} /> : null}
        </>
    )
}