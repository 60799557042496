import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import { ConponentContainer } from '../../Components/Container'
import { SectionHeader } from '../../Components/SectionHeader'

export const PrivacyPolity = () => {
    return (
        <Box p={[5, 10, 20]} whiteSpace='pre-line'>
            <Text fontSize='30px' fontWeight='bold'>{'Privacy Policy'}</Text>
            <br />
            Please read these terms and conditions of use carefully before using this site. Your use of this Web site is expressly conditioned on your acceptance of the following terms of use. By using this Web site, you signify your assent to these terms of use and privacy policy. If you do not agree with any part of these terms of use, then feel free contact us.
            The 'Privacy Policy' of Prayas Sikar is intended to provide the users of the website the terms associated with the usage and protection of all the personal information that has been collected from them. By 'personal information', we mean any kind of information that can be associated with a particular individual. This includes his/her name, address, telephone number, e-mail ID, credit card number, and other such details. All images, Web site design elements, text, graphics, logos, buttons, icons, brochures, forms, software and other content, the presentation, selection and arrangement thereof, Copyright 2019 © 2019, Prayas Sikar . All rights reserved.
            <Text mt={20} fontSize='30px' fontWeight='bold'>{'Refund Policy'}</Text>
            <br />
            Our policy on refund and cancellation received for Prayas Sikar foundation on secure online payment gateway as under: Any request for cancellations and refund of online Payment once duly placed on the website, shall not be entertained under any circumstances. No cash or refund of money will be allowed after completing the online Payment as it is an extremely cumbersome process. We therefore request you to be sure before you Pay.
        </Box>
    )
}