import React, { useReducer, useState, useEffect } from "react";
import { Box, Button, Image, Text, Flex, Stack, Divider } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { SectionHeader } from "../../Components/SectionHeader";
import { DownloadIcon, TimeIcon } from "@chakra-ui/icons";
import { Empty } from "../../Components/Empty";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { toReadableDate } from "../../utils/datehelper";
import { ButtonX } from '../../Components/ButtonX'
import { BsEye } from "react-icons/bs";
import { PDFModal } from "../DemoContent/PDFModal";

export const Magazines = (props) => {
  const history = useHistory();
  const { packages } = useSelector((s) => ({
    packages: s.user?.student?.packages,
  }));

  const [showModal, openShowModal] = useState()

  const [doc, setDoc] = useState()
  const handleOpen = (file) => {
    setDoc(file)
      openShowModal(d => !d)
  }

  let magazines = _.filter(
    _.flatMap(packages, (p) =>
      map(p?.package?.magazines, (m) =>
        Object.assign({}, m, {
          package: p.package?.name?.en || p.package?.name.hn,
          assignedOn: p.assignedOn,
        })
      )
    ),
    (mg) => mg.mode === "online" && mg.type === "MAGAZINE"
  );

  const breadcrumbs = [
    { title: "Home", link: "/" },
    { title: "Magazines", link: "#" },
  ]

  return (
    <Box>
      <SectionHeader
        title="Magazines"
        breadcrumbs={breadcrumbs}
      />
      <Box borderRadius="xl" p="1rem">
        {true ? (
          <div>
            <Flex direction="column">
              {_.map(magazines, (mg, index) => {
                return (
                  <Flex
                    key={index}
                    boxShadow="sm"
                    alignItems="center"
                    borderRadius="lg"
                    m={3}
                    background="white"
                  >
                    <Stack direction="row" flex={1}>
                      {mg.media.length && mg.media[0]?.url ? (
                        <Image
                          mt={5}
                          ml={3}
                          borderRadius="lg"
                          height="100px"
                          width="100px"
                          objectFit="cover"
                          src={mg.media[0].url}
                        />
                      ) : null}
                      <Box p="1rem">
                        <div style={{ minHeight: "70px" }}>
                          <h3>
                            <b>{mg?.name?.en}</b>
                          </h3>
                          <Text noOfLines={2} color="gray.500" isTruncated>
                            {mg?.description?.en}
                          </Text>
                          <Divider my={3}/>
                          <Text color="gray.400" fontSize="xs">Package: {mg.package}</Text>
                          <Text color="gray.400" fontSize="xs">Purchased on: {toReadableDate(mg.assignedOn)}</Text>

                        </div>
                      </Box>
                    </Stack>
                    <Box m={3}>
                      {mg.content?.length ? (
                        <ButtonX
                          leftIcon={<BsEye />}
                          onClick={() => handleOpen({
                          _id: mg.content[0]._id,
                          name: mg.name?.en,
                          data: {
                            url: mg.content[0]?.url
                          }
                          })}
                          // onClick={() => window.open(mg.content[0].url)}
                        >
                          View
                        </ButtonX>
                      ) : (
                        <ButtonX colorScheme="orange" leftIcon={<TimeIcon />}>
                          Coming Soon
                        </ButtonX>
                      )}
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </div>
        ) : (
          <Empty
            title="NO MAGAZINES AVAILABLE"
            style={{ background: "white", margin: "0px" }}
          />
        )}
      </Box>
      {showModal ? <PDFModal visible={showModal} closeModal={handleOpen} document={doc} /> : null}

    </Box>
  );
};
