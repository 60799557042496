import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Heading,
  AspectRatio,
  Button,
  Text,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ModalFooter,
  useDisclosure,
  Select,
  useToast,
  List,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import { map } from "jquery";
import { find } from "lodash";
import { forEach } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { BiBook } from "react-icons/bi";
import { BsPeople } from "react-icons/bs";
import { FaChalkboardTeacher } from "react-icons/fa";
import { IoSchoolOutline } from "react-icons/io5";
import { MdContentCopy } from "react-icons/md";
import { SiGoogleclassroom, SiKitsu } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { FromBottomAnimation } from "../common/animation";
import ContainerX from "../common/containerX";
import HeadingX from "../common/headingX";
import { STATUS } from "../Constants";
import {
  addVisiterRequest,
  resetFormStatus,
} from "../redux/reducers/formSubmit";
import { getWebsiteDataAction } from "../redux/reducers/website";
import { useCheckStatus } from "../utils/useCheckStatus";
import { useQueryParams } from "../utils/useQueryParams";
import { SingleCard, SingleCardH } from "./headerBottom";
export default function WhyPrayas(props) {
  const course = props.data;

  return (
    <Box mt={[10]} bg="gray.800">
      <Flex display={{ base: "block", lg: "flex" }} p={{ base: 5, md: 10 }}>
        <Box flex={1}>
          <Box px={[3, 5, 10, 20]} py={[3, 5, 10, 10]} pb={{ base: 0, lg: 20 }}>
            <HeadingX color="white">Prayas Career Institute Sikar</HeadingX>
            <FromBottomAnimation>
              <Heading color="white" fontSize={"lg"} mt={4}>
                Unlock your full potential with our offline coaching institute
              </Heading>
              <Box color="white" align="justify" fontSize={"md"} mt={4}>
                If you are looking for a reliable & comprehensive offline
                institute for acing government competitive exams, then Prayas
                Career Institute in Sikar is the best place to be. We serve a
                vast number of students who have aced exams like RPSC Teacher,
                CET, LDC, SI, etc which is reflected in our results. We believe
                that with the right tools and guidance, anyone can achieve their
                academic dreams. So if you're ready to take your learning to the
                next level and see real results, join us at our offline coaching
                institute today!
              </Box>
            </FromBottomAnimation>
          </Box>
          <Center>
            <AspectRatio
              overflow="hidden"
              borderRadius={5}
              ratio={"16:9"}
              w={"500px"}
              h={"300px"}
            >
              <iframe
                src="https://www.youtube.com/embed/WpGjkmnq540"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </AspectRatio>
          </Center>
        </Box>
        <Box flex={1}>
          <Box p={[3, 5, 10, 10]}>
            <Flex align={"end"}>
              <HeadingX color="white">Why Prayas</HeadingX>
            </Flex>
            <Flex flexWrap={"wrap"} my={5}>
              <SingleCardH
                align="start"
                py={5}
                width={"90%"}
                icon={<MdContentCopy />}
                heading="Test Series"
                desc="One of the key features of our institute is our offline test series, which allows students to 
                practice and prepare for exams in a simulated test environment. These tests are carefully 
                designed to closely mimic the format and difficulty level of actual exams, giving students a 
                realistic sense of what to expect on test day."
              />
              <SingleCardH
                align="start"
                py={5}
                width={"90%"}
                icon={<SiGoogleclassroom />}
                heading="Experienced Teachers and Regular Classes"
                desc="Our offline classes offer a regular schedule, which can be helpful for students who thrive on 
                routine or need a set schedule to stay motivated and focused. Our faculties are experts in their 
                respective fields and are available to answer questions and offer support in real-time, making it 
                easier for students to get the help they need."
              />
              <SingleCardH
                align="start"
                py={5}
                width={"90%"}
                icon={<FaChalkboardTeacher />}
                heading="Campus"
                desc="We also have a state-of-the-art campus that provides students with all the resources and 
                facilities they need to succeed. Our classrooms are spacious and well-equipped with modern 
                infrastructure."
              />
              <SingleCardH
                align="start"
                py={5}
                width={"90%"}
                icon={<IoSchoolOutline />}
                heading="Results"
                desc={
                  <Box>
                    <Text>
                      Our institute has a proven track record of helping
                      students achieve their goals and succeed in their academic
                      or professional endeavors. We have given
                    </Text>
                    <UnorderedList>
                      <ListItem>04 1st Rankers In RPSC Exam</ListItem>
                      <ListItem>130+ Top 10 Ranks</ListItem>
                      <ListItem>
                        3700+ Total Selection In Various Exams
                      </ListItem>
                    </UnorderedList>
                  </Box>
                }
              />
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Counts courses={course} instituteId={props?.instituteId} />
    </Box>
  );

  // return (
  //   <Box mt={{ base: 10, lg: 10 }}>
  //     <Box pos="relative" backgroundPosition="top" backgroundSize="cover">
  //       <Box
  //         opacity="0.4"
  //         bg="linear-gradient(45deg, #0d1128 0%, #fd6100 100%)"
  //         pos="absolute"
  //         top={0}
  //         w="full"
  //         h="full"
  //         left={0}
  //       ></Box>
  //       <Box>
  //         <HeadingX color="white">Prayas Eduhub</HeadingX>
  //         <FromBottomAnimation>
  //           <Box color="white" align="justify" fontSize={"md"} mt={4}>
  //             Prayas eduhub is the Best Learning Platform to prepare for
  //             Government Competitive Exams along with Top Educators and
  //             structured crash courses, mock tests, and practice sections We
  //             provide Video Lectures and Quiz Video Solutions. Prayas Eduhub
  //             Educational Services is a leading educational institution in Sikar
  //             that provides comprehensive test preparatory services to students
  //             preparing for CET, LDC, and TEACHER entrance exams. Today, we are
  //             highly acknowledged for offering niche test preparatory services
  //             for Competitive exam
  //           </Box>
  //         </FromBottomAnimation>
  //       </Box>
  //       <Box zIndex={2} pos="relative">
  //         <Flex minH="400px" flexDir={{ base: "column", md: "row" }} py={10}>
  //           <Flex flex={1} align="center" justify={"center"}>
  //             <AspectRatio
  //               overflow="hidden"
  //               borderRadius={5}
  //               ratio={"16:9"}
  //               w="90%"
  //               h={{ base: "100%", md: "50%" }}
  //             >
  //               <iframe
  //                 src="https://www.youtube.com/embed/WpGjkmnq540"
  //                 title="YouTube video player"
  //                 frameBorder="0"
  //                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //                 allowFullScreen
  //               ></iframe>
  //             </AspectRatio>
  //           </Flex>
  //           <Box
  //             my={10}
  //             flex={1}
  //             px={10}
  //             flexDir="column"
  //             display={"flex"}
  //             justifyContent={"space-between"}
  //           >
  //             <Flex mt={10} align={"end"}>
  //               <HeadingX color="white">Why Prayas</HeadingX>
  //             </Flex>
  //             <Flex flexWrap={"wrap"} my={5}>
  //               <SingleCard
  //                 py={5}
  //                 width={"50%"}
  //                 icon={<MdContentCopy />}
  //                 heading="Test Series"
  //                 desc="We conduct offline tests in different districts of Rajasthan to provide students with a real-time experience. "
  //               />
  //               <SingleCard
  //                 py={5}
  //                 width={"50%"}
  //                 icon={<SiGoogleclassroom />}
  //                 heading="Experience and Consultant Classes"
  //                 desc={
  //                   "Prayas Eduhub is the best for CET, LDC, Rajasthan Police, P-CET, RPSC 1st Grade, RPSC 2nd Grade, and RPSC 3rd Grade Teachers and is the most trusted brand when it comes to providing quality education."
  //                 }
  //               />
  //               <SingleCard
  //                 py={5}
  //                 width={"50%"}
  //                 icon={<FaChalkboardTeacher />}
  //                 heading="Campus"
  //                 desc="No one shall be subjected to arbitrary arrest, detention or exile. Everyone is entitled in full equality to a fair."
  //               />
  //               <SingleCard
  //                 py={5}
  //                 width={"50%"}
  //                 icon={<IoSchoolOutline />}
  //                 heading="Results"
  //                 desc="We have given 04 RPSC 1st Rank, 130+ Top 10 Ranks and around 3700 total selection. You can be next, join Prayas now."
  //               />
  //             </Flex>
  //           </Box>
  //         </Flex>

  //         <Counts courses={course} />
  //       </Box>
  //     </Box>
  //   </Box>
  // );
}

const Counts = ({ courses, instituteId }) => {
  const date = new Date();
  const dispatch = useDispatch();
  let query = useQueryParams();

  const visitQuery = query.get("visit");

  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: visitQuery == "true" ? true : false,
  });

  const [allUniqueExams, setAllUniqueExams] = useState([]);
  const [userInput, setInput] = useState();

  useEffect(() => {
    dispatch(getWebsiteDataAction({ instituteId }));
  }, [dispatch, instituteId]);

  const { websiteData } = useSelector((s) => s.website);

  const { addVisiterRequestStatus } = useSelector((s) => ({
    addVisiterRequestStatus: s.form.addVisiterRequestStatus,
  }));

  const inputHandler = (name, value) => {
    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const _submit = (e) => {
    e.preventDefault();
    dispatch(addVisiterRequest(userInput));
  };

  useEffect(() => {
    const newUniqueExam = [
      // { name: { en: "Select Interested Courses" }, _id: "" },
    ];
    if (courses?.length > 0) {
      forEach(courses, (packageData) => {
        forEach(packageData.exams, (exam) => {
          if (!find(newUniqueExam, (e) => e._id === exam._id)) {
            newUniqueExam.push(exam);
          }
        });
      });
    }
    setAllUniqueExams(newUniqueExam);
  }, [courses]);

  const toast = useToast();

  useCheckStatus({
    status: addVisiterRequestStatus,
    onSuccess: () => {
      toast({
        title: "Visit Added",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      document.getElementById("visitorsForm").reset();
      setInput();
      resetFormStatus();
      onClose();
    },
  });

  const [selectedDate, setSelectedDate] = useState();

  const dateHandler = (d) => {
    setSelectedDate(d);
    if (d === "today") {
      inputHandler("visitDate", moment(date).format("YYYY-MM-DD"));
    } else if (d === "tomorrow") {
      inputHandler(
        "visitDate",
        moment(date.setDate(date.getDate() + 1)).format("YYYY-MM-DD")
      );
    } else {
      inputHandler("visitDate", moment(d));
    }
  };

  return (
    <Box w="100%" bg="rgba(0,0,0,0.2)" align="center" py={20}>
      <Heading zIndex={10} color="white" fontSize={"5xl"} fontWeight="semibold">
        Some counts that matters
      </Heading>
      <ContainerX>
        <Flex
          w="100%"
          flexWrap={"wrap"}
          py={10}
          color="white"
          justify="center"
          align={"center"}
        >
          <CountItem
            icon={<BiBook />}
            count="115+"
            title="Certified teachers"
          />
          <CountItem icon={<BsPeople />} count="6500+" title="Students" />
          <CountItem icon={<BiBook />} count="40+" title="Courses" />
          <CountItem
            icon={<IoSchoolOutline />}
            count="3700+"
            title="Selection"
          />
        </Flex>
      </ContainerX>
      <Flex justifyContent={"space-evenly"}>
        <Button color="white" variant="outline" role="group" onClick={onOpen}>
          <Text mr={3} _groupHover={{ color: "default.500" }}>
            Book a visit{" "}
          </Text>{" "}
          <ArrowForwardIcon _groupHover={{ color: "default.500" }} />
        </Button>
        <Button color="white" variant="outline" role="group">
          <Text mr={3} _groupHover={{ color: "default.500" }}>
            Demo Class{" "}
          </Text>{" "}
          <ArrowForwardIcon _groupHover={{ color: "default.500" }} />
        </Button>
        <Button
          color="white"
          variant="outline"
          role="group"
          onClick={() =>
            document
              .getElementById("footer")
              .scrollIntoView({ behavior: "smooth" })
          }
        >
          <Text mr={3} _groupHover={{ color: "default.500" }}>
            Contact us{" "}
          </Text>{" "}
          <ArrowForwardIcon _groupHover={{ color: "default.500" }} />
        </Button>
      </Flex>
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form id="visitorsForm" onSubmit={_submit}>
            <ModalHeader>Book a visit</ModalHeader>
            <ModalCloseButton onClose={onClose} />
            <ModalBody>
              <FormControl isRequired>
                <Flex my={5} w="100%" align="center">
                  <FormLabel flex={1}>Name</FormLabel>
                  <Input
                    name="name"
                    flex={1}
                    type="text"
                    placeholder="Enter Name"
                    onChange={(e) => inputHandler("name", e.target.value)}
                  />
                </Flex>
              </FormControl>
              <FormControl isRequired>
                <Flex my={5} w="100%" align="center">
                  <FormLabel flex={1}>Contact</FormLabel>
                  <Input
                    name="contact"
                    flex={1}
                    type="tel"
                    pattern="[6-9]{1}[0-9]{9}"
                    placeholder="Enter Contact"
                    onChange={(e) => inputHandler("contact", e.target.value)}
                  />
                </Flex>
              </FormControl>
              <FormControl>
                <Flex my={5} w="100%" align="center">
                  <FormLabel flex={1}>Email</FormLabel>
                  <Input
                    name="email"
                    flex={1}
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$"
                    placeholder="Enter Email"
                    onChange={(e) => inputHandler("email", e.target.value)}
                  />
                </Flex>
              </FormControl>
              <FormControl isRequired>
                <Flex my={5} w="100%" align="center">
                  <FormLabel w="47.5%">Interested Course</FormLabel>
                  <Select
                    w="52.5%"
                    name="interestedCourse"
                    onChange={(e) =>
                      inputHandler("interestedCourse", e.target.value)
                    }
                    placeholder="Select Interested Course"
                  >
                    {map(websiteData?.courseCategories, (c) => (
                      <option
                        key={c._id}
                        style={{ color: "black" }}
                        value={c._id}
                      >
                        {c.name.en || c.name.hn}
                      </option>
                    ))}
                  </Select>
                </Flex>
              </FormControl>
              <FormControl>
                <Flex my={5} w="100%" align="center">
                  <FormLabel flex={1}>Address</FormLabel>
                  <Textarea
                    name="address"
                    flex={1}
                    type="text"
                    placeholder="Enter Address"
                    rows={3}
                    onChange={(e) => inputHandler("address", e.target.value)}
                  />
                </Flex>
              </FormControl>
              <FormControl>
                <Flex my={5} w="100%" align="center">
                  <FormLabel w="47.5%">Visit Date</FormLabel>
                  <Box w="52.5%">
                    <Flex mb={2}>
                      <Button
                        colorScheme={
                          selectedDate === "today" ? "default" : null
                        }
                        onClick={() => dateHandler("today")}
                      >
                        Today
                      </Button>
                      <Button
                        colorScheme={
                          selectedDate === "tomorrow" ? "default" : null
                        }
                        ml={3}
                        onClick={() => dateHandler("tomorrow")}
                      >
                        Tomorrow
                      </Button>
                    </Flex>
                    <Input
                      type="date"
                      value={
                        selectedDate === "today" || selectedDate === "tomorrow"
                          ? ""
                          : selectedDate
                      }
                      onChange={(e) => dateHandler(e.target.value)}
                    />
                  </Box>
                </Flex>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                isDisabled={
                  addVisiterRequestStatus === STATUS.FETCHING ? true : false
                }
                colorScheme="default"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const CountItem = ({ icon, count, title }) => {
  return (
    <Box
      w={{ base: "50%", md: "25%" }}
      zIndex="2"
      color="white"
      px={4}
      py={10}
      align="center"
    >
      <Center fontSize={48} color="white" w={20} h={20} borderRadius="50%">
        {icon}
      </Center>

      {/* <  fontSize={40} w={20} h={20} /> */}
      <Heading size="lg" w="fit-content" p={2}>
        {count}
      </Heading>
      <Box>{title}</Box>
    </Box>
  );
};
