import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { FromRightAnimation } from "../common/animation";

export default function FAQS() {
    return (
        <Box align="center">
            <Text pt={10} fontSize={{ base: "3xl", md: "4xl" }} ><strong>Frequently Asked</strong> Questions</Text>
            <Text w={{ base: "80%", md: "60%" }} mt={2} mb={8}>
                Still have doubts? We will clear them all.
            </Text>
            {/* <Flex w={"70px"} align="center" justifyContent={"center"} position="relative">
                <Flex px={5} py={3} zIndex={3} bg="green.400" color="white" borderRadius={100}>?</Flex>
                <Flex w="100px" borderBottom={"3px solid #68D391"} position="absolute" />
            </Flex> */}
            <Flex>
                <Flex display={{ base: "none", md: "initial" }} w="40%" align={'start'} justify="left">
                    <Image boxSize='450px' src="/assets/faqs.webp" alt="" />
                </Flex>
                <VStack spacing={4} w={{ base: "100%", md: "60%" }} p={{ base: 4, sm: 10 }}>
                    <Questions
                        question="Is there a residence facility available?"
                        answer="Yes, we do have a hostel facility available near the campus for students with all basic amenities. For more details, you can contact 9929254001."
                    />
                    <Questions
                        question="Are booklets/pdf notes provided?"
                        answer="With every course, we provide students with ebooks/pdf notes for revisions."
                    />
                    <Questions
                        question="Is there a regular test?"
                        answer="We conduct periodic tests for all courses and after the test, you will get an in-depth analysis of your result."
                    />
                    <Questions
                        question="Are there topic-wise tests?"
                        answer="Yes, we do provide a concept or chapter-wise test to achieve intangible knowledge of various topics."
                    />
                    <Questions
                        question="Can I get the offline test in my district?"
                        answer="We conduct PRE-EXAM for 1st Grade, RAS, SI, Patwar, ERO, VDO, RP & other Gov. Vacancies in all over Rajasthan. If you don't find your district, you can contact us on 9468645748."
                    />
                </VStack>
            </Flex>

        </Box>
    )
}

const Questions = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(p => !p)
    }

    return (
        <FromRightAnimation borderRadius={10} overflow='hidden' w="100%" align="center" bg="white" border='1px solid' borderColor='gray.200'>
            <Box>
                <Flex onClick={toggleOpen} p={4} py={2} justifyContent="space-between" align="center" bg={isOpen ? "green.400" : 'gray.100'} color={isOpen ? "white" : 'gray.700'} cursor="pointer">
                    {/* <Flex px={3} py={1} zIndex={3} color={isOpen ? 'white' : "gray.800"} border="3px solid" borderColor={isOpen ? 'white' : "gray.600"} borderRadius={'50%'}>?</Flex> */}
                    <Text py={1} ml={3}>{question}</Text>
                    {isOpen ? <ChevronUpIcon fontSize={24} /> : <ChevronDownIcon fontSize={24} />}
                </Flex>
                {
                    isOpen &&
                    <Flex mt={1} py={4}>
                        <Text textAlign={"left"} ml={3}>{answer}</Text>
                    </Flex>
                }
            </Box>
        </FromRightAnimation>
    )
}