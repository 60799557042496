import { filter } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";

import NavBar from "../../HomePageComponents/navbar";
import Footer from "../../HomePageComponents/footer";
import HeaderCarousel from "../../HomePageComponents/headerCarousel";
import HeaderBottom from "../../HomePageComponents/headerBottom";
import LatestNews from "../../HomePageComponents/latestNews";
import OurCourses from "../../HomePageComponents/ourCourses";
import WhyPrayas from "../../HomePageComponents/whyPrayas";
import OfferDiscount from "../../HomePageComponents/offerDiscount";
import Instructors from "../../HomePageComponents/instructors";
import StudentSay from "../../HomePageComponents/studentSay";
import CVSubmission from "../../HomePageComponents/cvSubmission";
import FAQS from "../../HomePageComponents/faqs";
import Gallery from "../../HomePageComponents/gallery";

import { Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";
export default function HomePage() {
  const { packagesList, config, notices, events } = useSelector((s) => ({
    packagesList: s.package.packagesList,
    config: s.package.config,
    notices: s.package.notices,
    events: s.package.events,
  }));

  const getPackages = useCallback(
    (type) => {
      return filter(packagesList, (d) => d.type === type && !d.isOfflineTestSeriesPackage);
    },
    [packagesList]
  );
  const CoursesData = useMemo(() => {
    return getPackages("COURSE");
  }, [getPackages]);

  const TestData = useMemo(() => {
    return getPackages("TEST");
  }, [getPackages]);

  const ExamData = useMemo(() => {
    return getPackages("BOOK").concat(getPackages("MAGAZINE"));
  }, [getPackages]);

  const packages = filter(CoursesData, (course) => course?.offers?.length);
  return (
    <Box maxW="100vw" overflow="hidden">
      <NavBar />
      <HeaderCarousel carouselData={config?.carousel} />
      <HeaderBottom />
      <LatestNews data={events} />
      <OurCourses id="courses" data={CoursesData} title="Our courses" />
      <Box mt={20}>
        <OurCourses id="test-series" data={TestData} title="Our Test Series" />
      </Box>
      <WhyPrayas data={CoursesData} />
      {packages.length ?
        <OfferDiscount data={packages} />
        :
        <br />
      }
      <OurCourses
        id="book-library"
        data={ExamData}
        title="Our E-Book and Library"
      />
      <Instructors />
      <StudentSay data={config?.testimonials} />
      <FAQS />
      <CVSubmission />
      <Gallery />
      <Footer />
    </Box>
  );
}

// HomePage.getLayout = function getLayout(page) {
//     return (
//         <>
//             <NavBar />
//             {page}
//             <Footer />
//         </>
//     )
// }
