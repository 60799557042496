import {
  Box,
  Flex,
  Text,
} from "@chakra-ui/react";
import { chunk, filter, find, forEach, map, orderBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import ContainerX from "../common/containerX";
import HeadingX from "../common/headingX";
import { Card } from "../Components/ItemCard";
import './homepage.css'

export default function OurCourses({ id, title, data }) {
  const [allUniqueExams, setAllUniqueExams] = useState([]);
  const [curSelectedTab, setCurSelectedTab] = useState({ _id: "" });

  useEffect(() => {
    const newUniqueExam = [{ name: { en: "All" }, _id: "" }];
    if (data?.length > 0) {
      forEach(data, (packageData) => {
        forEach(packageData.exams, (exam) => {
          if (!find(newUniqueExam, (e) => e._id === exam._id)) {
            newUniqueExam.push(exam);
          }
        });
      });
    }
    setAllUniqueExams(newUniqueExam);
  }, [data]);


  const CurPackagesData = useMemo(() => {
    return filter(
      data,
      (packageData) =>
        !curSelectedTab._id ||
        find(packageData.exams, (e) => e._id === curSelectedTab._id)
    );
  }, [data, curSelectedTab]);

  return (
    <Box align="center" id={id}>
      <HeadingX>{title}</HeadingX>
      <Flex my={{ base: 10, lg: 8 }} justify="center" wrap="wrap">
        {map(allUniqueExams, (c) => {
          const isSelected = c._id === curSelectedTab._id;
          return (
            <Box key={"course" + c._id} p={{ base: 2, lg: 2 }}>
              <Text
                px={2}
                cursor="pointer"
                fontSize={"lg"}
                borderBottom={isSelected ? "3px solid #fd5f00" : null}
                onClick={() => setCurSelectedTab(c)}
              >
                {c.name.en || c.name.hn}
              </Text>
            </Box>
          );
        })}
      </Flex>

      <TestList data={CurPackagesData} />

    </Box>
  );
}

const TestList = ({ data }) => {
  const packages = chunk(orderBy(data, "priority", "asc"), 2)
  return (
    <ContainerX>
      <Box display={{ base: "none", md: "initial" }}>
        <Flex  className="scrollbar-1" overflowX={"scroll"}>
          {data.length < 6 ?
            map(orderBy(data, "priority", "asc"), (pkg) => (
              <Card width="380px" item={pkg} key={pkg._id} />
            ))
            :
            map(packages, (pkg, i) => (
              <Box key={i}>
                <Card width={{ base: "200px", md: "380px" }} item={pkg?.[0]} key={pkg?.[0]?._id} />
                {pkg.length === 2 ?
                  <Card width={{ base: "200px", md: "380px" }} item={pkg?.[1]} key={pkg?.[1]?._id} />
                  :
                  null
                }
              </Box>
            ))
          }
        </Flex>
      </Box >
      <Box display={{ md: "none" }}>
        <Flex overflowX="auto">
          {map(data, (pkg) => (
            <Box width="330px" mx={3} key={pkg._id}>
              <Card item={pkg} />
            </Box>
          ))}
        </Flex>
      </Box>
    </ContainerX >
  );
};
