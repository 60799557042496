import {
  Box,
  HStack,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Center,
  Badge,
  Text,
} from "@chakra-ui/react";
import { SiMinutemailer } from "react-icons/si";
import { map } from "lodash";
import React, { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { ImPhone } from "react-icons/im";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAppContext, useIsAuthenticated } from "../App/Context";
import { useLoginModal } from "../App/useLoginModal";
import { SIGNIN_MODAL_TYPE } from "../Components/CommonHeader";
import { SignInFlow } from "../Components/CommonHeader";
import { LogoutButton, ProfileDropdown } from "../Components/ProfileDropdown";
import {
  AiOutlineBell,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineSolution,
} from "react-icons/ai";

export default function Nabar(props) {
  const [showNav, setShowNav] = useState(false);
  const params = useLocation();
  const history = useHistory();

 
  const navItems = [
    { title: "Home", href: "/" },
    { title: "About us", href: "/about-us" },
    { title: "Exam Registration", href: "/pre-exam" },
  ];

  const toggleNav = () => {
    setShowNav((p) => !p);
  };

  const { setModalType, toggleLoginModal } = useLoginModal();
  const isAuthenticated = useIsAuthenticated();
  const { logout } = useAppContext();

  const _signInOut = () => {
    if (isAuthenticated) {
      logout();
    } else {
      setModalType(SIGNIN_MODAL_TYPE.SIGNIN);
      toggleLoginModal();
    }
  };

  return (
    <Center px={{ base: 0, sm: 4 }}>
      <Box bg="white" w="100%">
        <Box>
          <Flex py={4} px={[2, 10, 20]} justify="space-between" align="center">
            <HStack>
              <Image loading="lazy" w={58} alt="logo" src="/prayasEduhub.png" />
              <Box>
                <Heading color="gray.700" fontSize={"md"}>
                  Prayas Eduhub
                </Heading>
              </Box>
            </HStack>
            <Flex align="center">
              {isAuthenticated ? (
                <Flex>
                  <Flex
                    w="fit-content"
                    display={{ base: "none", md: "initial" }}
                  >
                    <ProfileDropdown />
                  </Flex>
                  <HStack ml={2} spacing={3}>
                    <Box>
                      <Box
                        pos="relative"
                        onClick={() => history.push("/dashboard/wishlist")}
                        cursor="pointer"
                      >
                        <Box color="brand.redAccent">
                          <AiOutlineHeart fontSize="18px" />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        pos="relative"
                        onClick={() => history.push("/notifications")}
                        cursor="pointer"
                      >
                        <Box color="brand.redAccent">
                          <AiOutlineBell fontSize="18px" />
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      {/* <Box
                        pos="relative"
                        onClick={() =>
                          history.push(
                            isAuthenticated ? ROUTES.CART : ROUTES.PUBLIC_CART
                          )
                        }
                        cursor="pointer"
                      > */}
                        {/* {context?.cart?.packages?.length ? (
                          <Badge
                            position="absolute"
                            top={-2}
                            right={-1}
                            zIndex={9}
                            background="#38A169"
                            variant="solid"
                            fontSize="10px"
                          >
                            {context.cart.packages.length}
                          </Badge>
                        ) : null} */}

                        {/* <Box color="brand.redAccent">
                          <AiOutlineShoppingCart fontSize="18px" />
                        </Box>
                      </Box> */}
                    </Box>
                  </HStack>
                </Flex>
              ) : (
                <HStack>
                  <Button size="sm" colorScheme="default" onClick={_signInOut}>
                    Login
                  </Button>
                  <Button size="sm" colorScheme="default" onClick={_signInOut}>
                    Register
                  </Button>
                  <Image
                    display={{ base: "none", md: "initial" }}
                    h="50px"
                    src="assets/google-play.png"
                    alt=""
                    cursor="pointer"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.prayascareerinstitute"
                      )
                    }
                  />
                </HStack>
              )}
              <Box ml={2} display={{ base: "block", lg: "none" }}>
                <FiMenu onClick={toggleNav} />
              </Box>
            </Flex>
          </Flex>
        </Box>
        <Flex
          display={{ base: showNav ? "none" : "flex", lg: "none" }}
          h="auto"
          overflow="hidden"
          align="center"
          flexDir="row"
          justify="space-between"
          px={[0, 5, 10]}
          //   borderTopRightRadius={5}
          //   borderTopLeftRadius={5}
          py={4}
          bg="gray.700"
        >
          <Flex
            flexDir="row"
            color="white"
            fontSize={{ base: "xs", sm: "sm", md: "md" }}
          >
            {map(navItems, (nav) => {
              const isSelected = params.pathname === nav.href;
              return (
                <Box
                  key={nav.href}
                  py={{ base: 3, lg: 0 }}
                  px={{ base: 3, lg: 4 }}
                >
                  <Link to={nav.href}>
                    <Box color={isSelected ? "default.500" : "white"}>
                      {nav.title}
                    </Box>
                  </Link>
                </Box>
              );
            })}

            {isAuthenticated && (
              <Flex
                display={{ base: "none", sm: "initial", lg: "none" }}
                py={{ base: 3, lg: 0 }}
                px={{ base: 3, lg: 4 }}
              >
                <Button
                  size="xs"
                  onClick={() => history.push("/doubts")}
                  bg="default.500"
                  _hover={{ bg: "default.500" }}
                >
                  <AiOutlineSolution color="white" />
                  <Text ml={1} style={{ color: "white" }} fontSize="sm">
                    Connect to Teacher
                  </Text>
                </Button>
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          h={{ base: showNav ? "auto" : 0, lg: "auto" }}
          overflow="hidden"
          align={{ base: "start", lg: "center" }}
          flexDir={{ base: "column", lg: "row" }}
          justify="space-between"
          px={[0, 10, 20]}
          //   borderTopRightRadius={5}
          //   borderTopLeftRadius={5}
          py={{ base: showNav && 6, lg: 4 }}
          bg="gray.700"
        >
          <Flex flexDir={{ base: "column", lg: "row" }} color="white">
            {map(navItems, (nav) => {
              const isSelected = params.pathname === nav.href;
              return (
                <Box
                  key={nav.href}
                  py={{ base: 3, lg: 0 }}
                  px={{ base: 3, lg: 4 }}
                >
                  <Link to={nav.href}>
                    <Box color={isSelected ? "default.500" : "white"}>
                      {nav.title}
                    </Box>
                  </Link>
                </Box>
              );
            })}
            {isAuthenticated && (
              <Box
                py={{ base: 3, lg: 0 }}
                px={{ base: 3, lg: 4 }}
                cursor="pointer"
                onClick={() => history.push("/doubts")}
              >
                Connect to Teacher
              </Box>
            )}
          </Flex>
          <Box mt={{ base: 4, lg: 0 }} px={{ base: 3, lg: 0 }}>
            <Flex justify={"start"} flexDir={{ base: "column", lg: "row" }}>
              <HStack mb={{ base: 4, lg: 0 }} align="center">
                <Icon color="default.400" fontSize={20}>
                  <SiMinutemailer />
                </Icon>
                <Box>
                  <Box color="gray.300" fontSize="sm">
                    Email
                  </Box>
                  <Link href="mailto: prayassikar@gmail.com">
                    <Box cursor="pointer" color="white">
                      prayassikar@gmail.com
                    </Box>
                  </Link>
                </Box>
              </HStack>
              <HStack ml={{ lg: 4 }} align="center">
                <Icon color="default.400" fontSize={20}>
                  <ImPhone />
                </Icon>
                <Box>
                  <Box color="gray.300" fontSize="sm">
                    Contact
                  </Box>
                  <Link href="tel:+91 8202929299">
                    <Box cursor="pointer" color="white">
                      8290645748
                    </Box>
                  </Link>
                </Box>
              </HStack>
              <Flex
                mt={6}
                display={{ base: "initial", md: "none" }}
                w="100%"
                bg="default.500"
                color="white"
              >
                <LogoutButton color="default.500" />
              </Flex>
            </Flex>
          </Box>
        </Flex>
        <SignInFlow />
        {/* <LoginRoute modalType={SIGNIN_MODAL_TYPE.COUPON_LOGIN} isLoginModalOpen={showLoginModal} closeLoginModal={ () => setShowLoginModal(false) }/> */}
      </Box>
    </Center>
  );
}
