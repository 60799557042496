import {
  Box,
  HStack,
  Image,
  Progress,
  Text,
  VStack,
  Alert,
  AlertIcon,
  Flex,
  IconButton,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  Icon,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AiFillFilePdf,
  AiFillFileText,
  AiFillVideoCamera,
  AiOutlineBell,
  AiOutlineFileText,
  AiOutlineRight,
} from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { SectionHeader } from "../../Components/SectionHeader";
import _ from "lodash";
import { BsFillInfoCircleFill, BsMusicNote } from "react-icons/bs";
import { ROUTES } from "../../Constants/Routes";
import { CONTENT_TYPE, STATUS } from "../../Constants";
import { checkExpiry, mobileView, webView } from "../../utils/Helper";
import { Truncate } from "../../Components/Truncate";
import { getLiveClasses, setActiveVideoPlayer } from "../../redux/reducers/courses";
import { map } from "lodash";
import { filter } from "lodash";
import { find } from "lodash";
import { useCallback } from "react";
import dayjs from "dayjs";

export const CourseList = () => {
  const history = useHistory();

  const { list, trackings, student, config, liveClassesData } = useSelector((state) => ({
    list: state.package.packageContents?.courses,
    trackings: state.tracking.trackings,
    student: state.user.student,
    config: state.package.config,
    liveClassesData: state.course.liveClassesData,
  }));
  const [courseList, changeCourseList] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (list?.length) {
      let data = list;
      data = list.map((l) =>
        _.findIndex(student?.courses, (c) => c.course == l._id) != -1
          ? { ..._.find(student.courses, (c) => c.course == l._id), ...l }
          : l
      );
      changeCourseList(data);
    }
  }, [list, student?.courses]);

  const getLiveClassesData = useCallback(() => {
    if(courseList?.length > 0){
        const courseIds = map(courseList, c => c._id);
        dispatch(getLiveClasses({ courseIds }));
    }
  },[courseList, dispatch])

  useEffect( () => {
    getLiveClassesData();
  },[getLiveClassesData])

  const showContents = (type, course) => {
    history.push("/dashboard/courses/" + course._id + "/" + type);
  };

  let breadcrumbs = [
    { title: "Home", link: "/" },
    { title: "Courses", link: ROUTES.COURSES },
  ];

  const [filesModal, showFiles] = useState();

  const handleOpenFiles = (course) => {
    showFiles((d) => (d ? null : course));
  };

  const getPkgContentsStatus = useSelector(
    (s) => s.package.getPkgContentsStatus
  );

  const handleViewLiveClass = (video) => {
    dispatch(setActiveVideoPlayer({ type: 'vimeo', url: video.url }));
    history.push(ROUTES.VIDEO_PLAYER);
  }

  return (
    <Box>
      <SectionHeader title="Courses" breadcrumbs={breadcrumbs} />

      <Box {...mobileView}>
        <Alert alignItems="start" status="info">
          <AlertIcon />
          Please download PRAYAS mobile app to view courses in mobile.
        </Alert>
        <Flex
          p={5}
          bg="gray.50"
          mt={10}
          as="a"
          href="https://bit.ly/prayasmobileapp"
          target={"_blank"}
          border="0.5px solid #dadada"
          borderRadius={"md"}
          alignItems="center"
        >
          <Image
            style={{ height: "40px", width: "40px" }}
            src={require("../../Images/play.png")}
          />
          <Text ml={3}>Click here to download android app</Text>
        </Flex>
      </Box>

      <Box {...webView}>
        {config.courseOffers?.length &&
        _.filter(config.courseOffers, (t) => t.html).length ? (
          <Box background={"white"} p={6} borderTopRadius="20px">
            <div
              dangerouslySetInnerHTML={{
                __html: _.find(config.courseOffers, (t) => t.html).html,
              }}
            />
          </Box>
        ) : null}
        <Box
          w="100%"
          style={{
            display: "flex",
            alignItems: "stretch",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {getPkgContentsStatus === STATUS.FETCHING ? (
            <Box p={10}>
              <Spinner />
            </Box>
          ) : null}

          {courseList?.length ? (
            _.orderBy(courseList, "assignedOn", "desc").map((course) => {
              const courseTrackings = trackings?.[course._id] || {};
              let countAudios = course.countAudios;
              let countVideos = course.countVideos;
              let countDocs = course.countDocs;
              let countTexts = course.countTexts;
              let countTests = course.countTests;
              const liveVideo = find(liveClassesData?.data, l => l._id === course._id )?.liveVideo;
              const isLiveVideoVisible = liveVideo && dayjs(liveVideo.startTime).isBefore(dayjs()) && dayjs(liveVideo.endTime).isAfter(dayjs());
              let totalCount = _.sum([
                countTexts,
                countVideos,
                countAudios,
                countDocs,
              ]);
              let progress = _.chain(courseTrackings)
                .flatMap((d) => _.size(d))
                .sum()
                .value();
              let totalProgress = _.chain(courseTrackings)
                .flatMap((d) => _.size(d))
                .sum()
                .value();
              let daysLeft = course.expireOn
                ? checkExpiry(course.expireOn, course.duration)
                : "nonExpired";
              let expired = daysLeft ? false : true;

              return totalCount ? (
                <HStack pos="relative" align="stretch" p={3}>
                  {expired ? (
                    <Box
                      pos="absolute"
                      paddingBottom="20px"
                      paddingRight="20px"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      top={0}
                      left={0}
                      bottom={0}
                      right={0}
                      zIndex={9}
                      onClick={null}
                    >
                      <Image
                        src="/images/expired.jpg"
                        style={{ opacity: 0.1 }}
                        width="50%"
                      />
                    </Box>
                  ) : null}
                  <VStack
                    key={course._id}
                    filter={expired && "grayscale(1)"}
                    align="stretch"
                    w="21.14vw"
                    borderRadius="8px"
                    border="1px solid #E9E9ED"
                    bg="white"
                  >
                    <HStack
                      justifyContent={"space-between"}
                      borderBottom="1px solid #E4E4E4C3"
                      p={3}
                    >
                      <Text fontSize="lg">{course.name}</Text>
                      {course.files?.length && (
                        <Tooltip label="Files">
                          <IconButton
                            onClick={() => handleOpenFiles(course)}
                            variant="outline"
                            color="blue.500"
                            icon={<AiOutlineFileText />}
                            fontSize={25}
                          />
                        </Tooltip>
                      )}
                      {/* <Text isTruncated noOfLines='5'>{course.description}</Text>     */}
                    </HStack>
                    <Box px={4} borderBottom="1px solid #E4E4E4C3" py={1}>
                      <HStack justifyContent="space-between" w="100%">
                        <Text fontSize="sm" color="grey">
                          <b>
                            {totalCount && progress
                              ? parseInt((progress / totalCount) * 100)
                              : 0}
                            %{" "}
                          </b>
                        </Text>
                        <Text fontSize="sm" color="green.500">
                          {!totalCount || !progress
                            ? "Not Started"
                            : parseInt(progress) < totalCount
                            ? "In Progress"
                            : "Completed"}
                        </Text>
                        <Text fontSize="sm" color="grey">
                          <b>Progress</b>
                        </Text>
                      </HStack>
                      <Progress
                        borderRadius="10px"
                        colorScheme="green"
                        my={2}
                        size="xs"
                        value={totalCount ? (progress / totalCount) * 100 : 0}
                      />
                      <HStack justifyContent="space-between" w="100%">
                        <Text fontSize="sm" color="grey">
                          {/* 20-08-2020 | 01:30:00 */}
                        </Text>
                        <Text fontSize="sm" color="green.500">
                          {expired ? (
                            "Expired"
                          ) : daysLeft ? (
                            daysLeft === "nonExpired" ? (
                              <br />
                            ) : (
                              daysLeft
                            )
                          ) : (
                            <br />
                          )}
                        </Text>
                      </HStack>
                    </Box>
                    <Box>
                      {
                        liveVideo && isLiveVideoVisible && 
                        <Flex px={4} justify='flex-end'>
                          <Button onClick={ () => handleViewLiveClass(liveVideo) } size='xs' leftIcon={<AiFillVideoCamera />} colorScheme="red">Join Live Class</Button>
                        </Flex>
                      }
                      {countAudios ? (
                        <ContentItem
                          icon={<BsMusicNote />}
                          trackings={courseTrackings[CONTENT_TYPE.AUDIO]}
                          colorScheme={"default"}
                          duration={""}
                          type="audios"
                          item={{
                            type: CONTENT_TYPE.AUDIO,
                            total: countAudios,
                          }}
                          showContents={(type) => showContents(type, course)}
                        />
                      ) : null}

                      {countVideos ? (
                        <ContentItem
                          icon={<AiFillVideoCamera />}
                          trackings={courseTrackings[CONTENT_TYPE.VIDEO]}
                          colorScheme={"yellow"}
                          duration={""}
                          type="videos"
                          item={{
                            type: CONTENT_TYPE.VIDEO,
                            total: countVideos,
                          }}
                          showContents={(type) => showContents(type, course)}
                        />
                      ) : null}

                      {countDocs ? (
                        <ContentItem
                          icon={<AiFillFilePdf />}
                          trackings={courseTrackings[CONTENT_TYPE.DOCUMENT]}
                          colorScheme={"red"}
                          type="documents"
                          item={{
                            type: CONTENT_TYPE.DOCUMENT,
                            total: countDocs,
                          }}
                          showContents={(type) => showContents(type, course)}
                        />
                      ) : null}

                      {countTexts ? (
                        <ContentItem
                          icon={<AiFillFileText />}
                          trackings={courseTrackings[CONTENT_TYPE.TEXT]}
                          colorScheme={"green"}
                          type="texts"
                          item={{
                            type: CONTENT_TYPE.TEXT,
                            total: countTexts,
                          }}
                          showContents={(type) => showContents(type, course)}
                        />
                      ) : null}

                      
                      {countTests ? (
                        <ContentItem
                          icon={<AiFillFileText />}
                          trackings={courseTrackings[CONTENT_TYPE.TEST]}
                          colorScheme={"green"}
                          type="tests"
                          item={{
                            type: CONTENT_TYPE.TEST,
                            total: countTests,
                          }}
                          showContents={(type) => showContents(type, course)}
                        />
                      ) : null}
                      <br />
                    </Box>
                  </VStack>
                  {/* {expired ? 
                                                <Box pos='absolute' paddingBottom='20px' paddingRight='20px' bg="rgba(255,255,255,0.4)" display='flex' justifyContent='center' alignItems='center' backgroundColor='red' 
                                                    top={0} left={0} bottom={"20px"} borderRadius="10px" right={"20px"} zIndex='9999' onClick={null}
                                                >
                                                <img src='/images/expired.jpg' style={{opacity: 0.1}} width='50%'/>
                                                </Box>
                                                :
                                                null
                                            } */}
                </HStack>
              ) : null;
            })
          ) : getPkgContentsStatus !== STATUS.FETCHING ? (
            <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
              No courses available
            </Text>
          ) : null}
        </Box>
      </Box>
      {filesModal ? (
        <FilesModal
          visible={filesModal}
          course={filesModal}
          closeModal={handleOpenFiles}
        />
      ) : null}
    </Box>
  );
};

const FilesModal = ({ visible, closeModal, course }) => {
  return (
    <Modal onClose={closeModal} size="4xl" isOpen={visible}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader> {course.name} </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack alignItems={"stretch"}>
            {/* {course.files.map(file =>
                            <Box padding={'0px 10px 20px 0px'} key={file._id}>
                                <VStack onClick={() => window.open(file.data?.url, '_blank')} cursor='pointer' width={100} _hover={{color:'#3498DB'}} transition='all .2s'>
                                    <AiOutlineFileText color='#515A5A' fontSize={50}/>
                                    <Tooltip label={file.name}>
                                        <Text fontSize={12} color='brand.secondary'>{file.name.length > 20 ? `${file.name.substring(0, 20)}...` : file.name}</Text>
                                    </Tooltip>
                                </VStack>
                            </Box>
                        )} */}
            {course.files.map((file) => (
              <Box
                border={"1px solid #D6DBDF"}
                borderRadius={4}
                _hover={{ borderColor: "#5DADE2" }}
                transition="all .2s"
                padding={1}
                key={file._id}
              >
                <HStack
                  width="100%"
                  onClick={() => window.open(file.url, "_blank")}
                  cursor="pointer"
                >
                  <AiOutlineFileText color="#515A5A" fontSize={30} />
                  <Text fontSize={13} color="brand.secondary">
                    {file.name}
                  </Text>
                </HStack>
              </Box>
            ))}
          </VStack>
          <br />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ContentItem = ({
  icon,
  duration,
  colorScheme,
  trackings = [],
  item,
  type,
  showContents,
}) => {
  let colorSchemes = [
    { colorScheme: "default", iconColor: "#4285F4", barColor: null },
    { colorScheme: "green", iconColor: "#27AE60", barColor: "green" },
    { colorScheme: "yellow", iconColor: "#FECD52", barColor: "yellow" },
    { colorScheme: "red", iconColor: "#DC4955", barColor: "red" },
  ];

  let scheme = _.find(colorSchemes, (c) => c.colorScheme == colorScheme);

  let total =
    type == "audios"
      ? "Audio"
      : type == "videos"
      ? "Video"
      : type == "documents"
      ? "PDF"
      : type == "texts"
      ? "Online Book"
      : type == "tests" 
      ? "Tests"
      : null;
  return (
    <HStack p={3} onClick={() => showContents(type)} cursor="pointer">
      <Box pr={2}>
        <HStack
          align="center"
          justify="center"
          bg={scheme.iconColor}
          fontSize="md"
          color={"white"}
          w="1.75vw"
          h="1.75vw"
          borderRadius="50%"
        >
          {icon}
        </HStack>
      </Box>
      <Box flexGrow="1">
        <HStack justifyContent="space-between" w="100%">
          <Text fontSize="sm">
            <b>{total}: </b>
            { type == "tests"  ? item.total :  _.size(trackings) + "/" + item.total}
          </Text>
          {duration && (
            <Text fontSize="sm">
              <b>Duration: </b> {duration}
            </Text>
          )}
        </HStack>
        {
          type !== "tests" &&
          <Progress
            size="xs"
            colorScheme={scheme.barColor}
            value={item.total ? (_.size(trackings) / item.total) * 100 : 0}
          />
        }
      </Box>
    </HStack>
  );
};

const Notes = () => {
  return (
    <VStack align="stretch" spacing={0} bg="#D0D0D04D" borderRadius="xl">
      <HStack p={4} cursor="pointer" fontSize="lg">
        <Box pr={2} alignItems="center">
          <Box p={2} bg={"primaryBlue.400"} color={"white"} borderRadius="50%">
            <AiFillFileText />
          </Box>
        </Box>
        <Box flexGrow="1">
          <Text fontWeight="bold">NOTE</Text>
        </Box>
      </HStack>
      <Box bg="#FFFFFF" borderRadius="xl" p={2}>
        <HStack
          my={3}
          p={4}
          borderRadius="15px"
          w="100%"
          cursor="pointer"
          bg="#6C61D0"
          fontSize="lg"
        >
          <Box pr={2} alignItems="center">
            <Box p={2} bg={"white"} color={"#DC4955"} borderRadius="50%">
              <BsMusicNote fontSize="25px" />
            </Box>
          </Box>
          <Box flexGrow="1" color="white">
            <Text fontWeight="bold">
              Audio Icon will be used to visit the related page
            </Text>
          </Box>
          <Box>
            <AiOutlineRight color="white" />
          </Box>
        </HStack>
        <HStack
          my={3}
          p={4}
          borderRadius="15px"
          w="100%"
          cursor="pointer"
          bg="lightGrayBlue"
          fontSize="lg"
        >
          <Box pr={2} alignItems="center">
            <Box p={2} bg={"white"} color={"#DC4955"} borderRadius="50%">
              <AiFillFilePdf fontSize="25px" />
            </Box>
          </Box>
          <Box flexGrow="1">
            <Text fontWeight="bold">
              Documents Icon will be used to visit the related page
            </Text>
          </Box>
          <Box>
            <AiOutlineRight />
          </Box>
        </HStack>
        <HStack
          my={3}
          p={4}
          borderRadius="15px"
          w="100%"
          cursor="pointer"
          bg="lightGrayBlue"
          fontSize="lg"
        >
          <Box pr={2} alignItems="center">
            <Box p={2} bg={"white"} color={"#DC4955"} borderRadius="50%">
              <AiFillVideoCamera fontSize="25px" />
            </Box>
          </Box>
          <Box flexGrow="1">
            <Text fontWeight="bold">
              Video Icon will be used to visit the related page
            </Text>
          </Box>
          <Box>
            <AiOutlineRight />
          </Box>
        </HStack>
      </Box>
    </VStack>
  );
};

const Notification = () => {
  return (
    <VStack align="stretch" spacing={0} bg="#D0D0D04D" borderRadius="xl">
      <HStack p={4} cursor="pointer" fontSize="lg">
        <Box pr={2} alignItems="center">
          <Box p={2} bg={"primaryBlue.400"} color={"white"} borderRadius="50%">
            <AiOutlineBell />
          </Box>
        </Box>
        <Box flexGrow="1">
          <Text fontWeight="bold">Notification</Text>
        </Box>
      </HStack>
      <Box bg="#FFFFFF" borderRadius="xl" p={2}>
        <VStack fontSize="lg" p={2} align="stretch">
          <HStack alignItems="center">
            <Box>
              <BsFillInfoCircleFill fontSize="22px" color="#FD9F27" />
            </Box>
            <HStack borderBottom="1px solid #E4E4E4C3" w="100%" pb={3}>
              <Box px={2}>
                <Text fontWeight="bold">March 2021</Text>
                <Text fontSize="sm" color="#3C4043B2">
                  National international current affair...
                </Text>
              </Box>
              <Box pl={2}>
                <AiOutlineRight />
              </Box>
            </HStack>
          </HStack>
        </VStack>
        <VStack fontSize="lg" p={2} align="stretch">
          <HStack alignItems="center">
            <Box>
              <BsFillInfoCircleFill fontSize="22px" color="#FD9F27" />
            </Box>
            <HStack borderBottom="1px solid #E4E4E4C3" w="100%" pb={3}>
              <Box px={2}>
                <Text fontWeight="bold">March 2021</Text>
                <Text fontSize="sm" color="#3C4043B2">
                  National international current affair...
                </Text>
              </Box>
              <Box pl={2}>
                <AiOutlineRight />
              </Box>
            </HStack>
          </HStack>
        </VStack>
        <VStack fontSize="lg" p={2} align="stretch">
          <HStack alignItems="center">
            <Box>
              <BsFillInfoCircleFill fontSize="22px" color="#FD9F27" />
            </Box>
            <HStack borderBottom="1px solid #E4E4E4C3" w="100%" pb={3}>
              <Box px={2}>
                <Text fontWeight="bold">March 2021</Text>
                <Text fontSize="sm" color="#3C4043B2">
                  National international current affair...
                </Text>
              </Box>
              <Box pl={2}>
                <AiOutlineRight />
              </Box>
            </HStack>
          </HStack>
        </VStack>
      </Box>
    </VStack>
  );
};
