import {
    createSlice,
    createAsyncThunk,
} from "@reduxjs/toolkit";
import { STATUS } from "../../../App/Constants";
import { apis } from "../../../services/api/apis";

export const getOrderDetailsAction = createAsyncThunk(
    "orderDetails/get",
    async (payload, thunkAPI) => {
        const response = await apis.getOrderDetails(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);

export const getPackageAction = createAsyncThunk(
    "package/get",
    async (payload, thunkAPI) => {
        const response = await apis.getPackageApi(payload);
        const { ok, problem, data } = response;
        if (ok) {
            return data;
        } else {
            return thunkAPI.rejectWithValue(problem);
        }
    }
);
const initialState = {};
const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
    },
    extraReducers: {
        // Cart
        [getOrderDetailsAction.pending]: (state, action) => {
            state.getOrderDetails = STATUS.FETCHING;
        },

        [getOrderDetailsAction.rejected]: (state, action) => {
            state.getOrderDetails = STATUS.FAILED;
        },

        [getOrderDetailsAction.fulfilled]: (state, action) => {
            state.getOrderDetails = STATUS.SUCCESS;
            state.orderDetails = action.payload;
        },

        [getPackageAction.pending]: (state, action) => {
            state.getpackageStatus = STATUS.FETCHING;
        },

        [getPackageAction.rejected]: (state, action) => {
            state.getpackageStatus = STATUS.FAILED;
        },

        [getPackageAction.fulfilled]: (state, action) => {
            state.getpackageStatus = STATUS.SUCCESS;
            state.pkg = action.payload;
        },
    },
});

export const checkoutReducer = checkoutSlice.reducer;
