import { Box, Text } from '@chakra-ui/layout'
import React from 'react'
import { Contact } from '../TermsService'

export const AboutUs = () => {
    return(
        <Box p={[5,10,20]} whiteSpace=''>
            <Text fontSize='30px' fontWeight='bold'>{'About Us'}</Text>
            <br/>
            <Box px={1}>
            Prayas Career Institute is one of the oldest Coaching Centres in Sikar, well known for its coaching and training game plans, Which helps students to do their best in most of the exams and in studies.We are here to provide you the best staffed coaching centre.
            </Box>
            {/* <Box dangerouslySetInnerHTML={{__html:`
                <p style="font-family:Times New Roman; font-size:18px; line-height:1.4"><strong>Prayas Sikar</strong>&nbsp;is an innovative initiative of&nbsp;<strong>FIRSTMAN EDUCATION PVT. LTD.</strong>, a registered organization with its vision and mission focused on building a better India by providing quality education at an affordable price to all the eager minds in need. We are a team of zealous people backed by complete dedication towards developing the essentials of the entire preparation cycle. COCO provides the best in class teaching, with excellent study material carefully crafted by our team of experts to leverage aspirants to excel in Civil Services and other competitive exams. We are pioneer in providing Classroom Coaching, Online/Offline Study material and Test-Series, which delivers an edge to our students over others. Our comprehensive and meticulously designed study material gives students conceptual clarity and command over the most difficult topics with easy explanations, simple examples and research-proven techniques. Our in-house R&amp;D Team revises and updates the study material on regular intervals and stays in line with the recent changes in the pattern of various competitive examinations.</p>
                <br/>
                <h2><strong>Vision &amp; Mission</strong></h2><br/>
                <h2 style="font-family:&quot;Times New Roman&quot;; font-size:18px; line-height:1.4"><strong>&ldquo;Talent nurtured today, shall add strength to our nation tomorrow&rdquo;</strong></h2>
                <br/>
                <p style="font-family:Times New Roman; font-size:18px; line-height:1.4">Our vision is to become a leading education service provider that can reach out to every deserving candidate in urban/rural/remote areas, to provide them a road-map for effective learning, and to deliver an equal platform for all to turn their dreams into reality.</p>
                <br/>
                <ul>
                    <li>To provide a competitive platform by means of technology enabled digital learning system for Civil Services aspirants, hence contributing to Digital India initiative of Government of India.</li>
                    <li>To gather the best team of experienced subject experts to develop best-in-class study material and test material for students to equip them better towards their goals and voyage to success.</li>
                    <li>To facilitate all deserving candidates from every corner of Chhattisgarh with our cost effective services to make them more capable and confident for various competitive exams.</li>
                </ul>
                <br/>
                <h2><strong>Core Values</strong></h2>
                <br/>
                <ul>
                    <li><h2>To remain focused on the needs of our students.</h2></li>
                    <li>To be true to all our commitments and services.</li>
                    <li>To be responsive to all changes and ways of improvement.</li>
                    <li>To facilitate preparation for our students in every way possible.</li>
                </ul>
            `}}/> */}
            <Contact/>
        </Box>
    )
}