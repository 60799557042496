import { Box, Text } from "@chakra-ui/layout";
import { Center, Flex, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { CONTACT_IT_HELP, CONTACT_OTHER } from "../../Constants/Config";

export const ContactUs = () => {
  return (
    <Box w="full" h="full" p={[5, 5, 10, 10]} px={[5, 5, 40, 40]}>
      <Text fontSize="30px" fontWeight="bold">
        {"Contact Us"}
      </Text>
      <br />

      <Center whiteSpace="">
        <Box>
          <Flex
            alignItems="center"
            m={1}
            flex={1}
            p={5}
            bg="transparent"
            _hover={{ boxShadow: "lg" }}
            boxShadow="sm"
          >
            <Image src="assets/phone-call.png" width="30px" height="30px" />
            <Box ml={[3, 5, 10]}>
              <Heading as={"h6"} color="primary.400" fontSize="lg">
                IT Help
              </Heading>
              <Text as="a" href={"tel:" + CONTACT_IT_HELP} fontSize="xl">
                {CONTACT_IT_HELP}
              </Text>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            m={1}
            flex={1}
            p={5}
            bg="transparent"
            _hover={{ boxShadow: "lg" }}
            boxShadow="sm"
          >
            <Image src="assets/phone-call.png" width="30px" height="30px" />
            <Box ml={[3, 5, 10]}>
              <Heading as={"h6"} color="primary.400" fontSize="lg">
                Other Help
              </Heading>
              <Text as="a" href={"tel:" + CONTACT_OTHER} fontSize="xl">
                {CONTACT_OTHER}
              </Text>
            </Box>
          </Flex>

          <Flex
            alignItems="center"
            m={1}
            flex={1}
            p={5}
            bg="transparent"
            _hover={{ boxShadow: "lg" }}
            boxShadow="sm"
          >
            <Image src="assets/email.png" width="30px" height="30px" />
            <Box ml={[3, 5, 10]}>
              <Heading as={"h6"} color="primary.400" fontSize="lg">
                Email us
              </Heading>
              <Text fontSize="xl" as="a" href={"mailto:prayassikar@gmail.com"}>
                prayassikar@gmail.com
              </Text>
            </Box>
          </Flex>
        </Box>
      </Center>
    </Box>
  );
};
