import { Box, Text, Heading, Link } from '@chakra-ui/react'
import React from 'react'

export const TermsService = () => {
    return (
        <Box p={[5, 10, 20]} whiteSpace=''>
            <Text fontSize='30px' fontWeight='bold'>{'Terms & Service'}</Text>
            <br />
            <Text>
                By accessing services and participating in an exam with Prayas, you confirm that you are in agreement with and bound by the terms of service contained in the Terms & Conditions outlined below. These terms apply to the entire application/website and any email or other type of communication between you and Prayas Career Institute. Under no circumstances shall Prayas Career Institute team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profit, arising out of the use, or the inability to use, the materials on this site, even if Prayas Career Institute team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof. Prayas Career Institute will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy at any moment.

            </Text>
            <Heading fontSize="lg" mt={10}>
                Your Consent
            </Heading>
            <Text mt={4}>
                We've updated our Terms & Conditions to provide you with complete transparency into what is being set when you visit our site and how it's being used. By using our website/app, registering an account, or participating in an exam contest, you hereby consent to our Terms & Conditions.
            </Text>
            <Heading fontSize="lg" mt={10}>
                Changes To Our Terms & Conditions
            </Heading>
            <Text mt={4}>
                You acknowledge and agree that Prayas Career Institute may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at Prayas Career Institute’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform Prayas Career Institute when you stop using the Service. You acknowledge and agree that if Prayas Career Institute disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which are contained in your account. If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the Terms & Conditions modification date below.
            </Text>
            <Contact />

        </Box>
    )
}


export const Contact = () => {
    return (
        <Box mb={20}>
            <Heading fontSize="lg" mt={20}>
                Contact Us
            </Heading>

            <Box mt={5}>
                Don't hesitate to contact us if you have any questions.
            </Box>

            <Heading fontSize="md" mt={10}>
                Let’s connect, right now

            </Heading>

            Address: WARD NO 33, NAWALGARH ROAD, SIKAR, Sikar, Rajasthan, 332001

            <Heading fontSize="md" as="pre" mt={10}>
                Via Email: <Link color="blue.500" href="mailto:support@prayassikar.com">support@prayassikar.com</Link>
                <br />
                <br />
                Via Website: <Link color="blue.500" href="https://prayassikar.com">https://prayassikar.com</Link>
            </Heading>

        </Box>
    )
}