import { Box } from "@chakra-ui/layout";
import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useIsAuthenticated } from "../../App/Context";
import { CommonHeader } from "../../Components/CommonHeader";

export const Blogs = (props) => {
  return (
    <Box>
      <CommonHeader />
      <Flex>
        {useIsAuthenticated ? <BlogsSidebar /> : null}
        
        <BlogContent />
      </Flex>
    </Box>
  );
};

const BlogsSidebar = (props) => {
  return (
    <Box flex={2}>
        <SidebarItem label="Add New Blog" /> 
        <SidebarItem label="Tags" />
        <SidebarItem label="Categories" /> 

    </Box>
  );
};

const SidebarItem = ({label}) => {
    return (
        <Box _hover={{bg:"gray.100"}} m={1} p={2} br="sm" w="90%" >
        {label}
      </Box>

    )
}

const BlogContent = (props) => {
  return (
    <Box flex={8}>
     
    </Box>
  );
};
