import React, { Suspense } from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import AppRouter from "../routes";
import { AppProvider } from "./AppProvider";
import { defaultTheme } from "../theme/default";
import { CartProvider } from "../Cart/CartProvider";
import "../routes/ProfileComponent/styles.css";
import { URLSearchParams } from "url";
import { useRouteAdapter } from "../clientExamAdapters";

if (typeof window === "undefined") {
  global.URLSearchParams = URLSearchParams;
}

export const App = (props) => {

  useRouteAdapter()


  return (
    <ChakraProvider theme={defaultTheme}>
      <AppProvider>
        <CartProvider>
          <Suspense fallback={<Box>
            Loading
          </Box>}>
            <AppRouter />

          </Suspense>
        </CartProvider>
      </AppProvider>
    </ChakraProvider>
  );
};
