import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { HomeRoute } from "./Home";
import { useAppContext, useIsAuthenticated } from "../App/Context";
import { AppRoutes } from "./ProtectedApp";
import { ROUTES } from "../Constants/Routes";
import { Cart } from "./Cart";
import { ExamView } from "./ExamView";
import { ExamPreviewScreen } from "./ExamPreview";
import { Box } from "@chakra-ui/layout";
import { isSSR } from "../utils/ssrHelper";
import { Center, Spinner } from "@chakra-ui/react";
import Survey from "./Survey";
import { Blogs } from "./Blogs";
import { useSelector } from "react-redux";
import { STATUS } from "../Constants";
import { useHistory } from "react-router-dom";
import { intersection, map, size } from "lodash";
import { AltHome } from "./Home/AltHome";
import HomePage from "./HomePage";
import AboutUs from "../HomePageComponents/aboutUs";
import { PrivacyPolity } from "./PrivacyPolicy.js";
import { TermsService } from "./TermsService";
import VideoPlayer from "./VideoPlayer";
import { Checkout } from "./Checkout";

const AppRouter = (props) => {
  const auth = useIsAuthenticated();
  const student = useSelector((s) => s.user.student);
  const history = useHistory();
  const surveyData = useSelector((s) => s.user.userSurvey);
  const getWebsitePackageStatus = useSelector(
    (s) => s.package.getWebsitePackageStatus
  );

  useEffect(() => {
    function goToSurvey(s, type) {
      const url = ROUTES.SURVEY + "?survey=" + s._id;
      history.push(url);
    }

    if (surveyData && student) {
      if (surveyData.accessibility) {
        if (surveyData.accessibility === "all") {
          goToSurvey(surveyData, "all");
        } else if (surveyData.accessibility === "any") {
          if (size(student?.packages)) {
            goToSurvey(surveyData, "any");
          }
        } else if (surveyData.accessibility === "specific") {
          const allowedPackages = surveyData.specific_packages;
          const studentPackages = map(student?.packages, (p) => p.package._id);

          const intersected = intersection(studentPackages, allowedPackages);

          if (size(intersected)) {
            goToSurvey(surveyData, "specific");
          }
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyData, student]);

  // useEffect(() => {
  //   console.log("userSurvey",userSurvey)
  //   if(userSurvey){
  //     const url = ROUTES.SURVEY+"?survey="+userSurvey._id;
  //     history.push(url)
  //   }
  // }, [userSurvey])

  // return getWebsitePackageStatus
  // if(userSurvey) return (
  // <Switch>
  //     <Route exact path={"/survey"}>
  //       <ExamView />
  //     </Route>
  //    <Redirect path="*" to="/survey" />
  // </Switch>
  // )
  // const hostname = window.location.host;
  // const isTestRoute = hostname.includes("prayaseduhub.com");

  return getWebsitePackageStatus === STATUS.SUCCESS ? (
    <>
      <Box>
        <Switch>
          <ProtectedRoute path="/dashboard" component={AppRoutes} />
          <Route exact path={"/exam/start"}>
            {auth ? <ExamView /> : <Redirect to="/" />}
          </Route>
          <Route exact path={"/exam/report"}>
            {auth ? <ExamPreviewScreen /> : <Redirect to="/" />}
          </Route>
          <Route exact path={"/blogs"}>
            <Blogs />
          </Route>
          <Route path={ROUTES.PUBLIC_CART}>
            <Cart />
          </Route>
          <Route path={ROUTES.CHECKOUT}>
            <Checkout />
          </Route>
          <Route path={ROUTES.SURVEY} key="330">
            <Survey />
          </Route>

          {/* <Route path="/">{isTestRoute ? <AltHome /> : <HomeRoute />}</Route> */}
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolity />
          </Route>
          <Route exact path="/terms-conditions">
            <TermsService />
          </Route>
          <Route exact path="/pre-exam">
            <AltHome />
          </Route>
          <Route exact path={ROUTES.VIDEO_PLAYER}>
            <VideoPlayer />
          </Route>
          <Route>
            <HomeRoute />
          </Route>
        </Switch>
        {/* <FooterComponent /> */}
      </Box>
      {isSSR ? (
        <Center
          position="fixed"
          bg="white"
          top={0}
          left={0}
          right={0}
          bottom={0}
          w="100vw"
          h="full"
        >
          <Spinner size="xl" colorScheme="telegram" />
        </Center>
      ) : null}
    </>
  ) : (
    <Center
      position="fixed"
      bg="white"
      top={0}
      left={0}
      right={0}
      bottom={0}
      w="100vw"
      h="full"
    >
      <Spinner size="xl" colorScheme="telegram" />
    </Center>
  );
};

export const ProtectedRoute = (props) => {
  const auth = useIsAuthenticated();
  const { checkapp } = useAppContext();
  const status = useSelector((s) => s.user.status);

  if (!checkapp || status === STATUS.FETCHING) {
    return <Spinner size="xl" color="red.500" />;
  }

  return auth ? (
    <Route {...props} /> // same as <Route path="/dashboard" component={AppRoutes} />
  ) : (
    <Redirect to={"/"} />
  );
};

export default AppRouter;
