export const WebsiteData = {
    "data": {
      "id": 1,
      "attributes": {
        "exams": [
          {
            "__v": 0,
            "_id": "6128775a86d06b16de10898a",
            "name": {
              "en": "CGPSC",
              "hn": "सीजीपीएससी"
            },
            "exams": [
              {
                "__v": 0,
                "_id": "6128779e86d06b16de10898c",
                "name": {
                  "en": "CGPSC PRELIMS",
                  "hn": "सीजीपीएससी प्रीलिम्स"
                },
                "image": "https://coco-v2-lms.s3.ap-south-1.amazonaws.com/images/2ef04c22-9077-442a-994a-a8e672ea7e20.jpeg"
              },
              {
                "__v": 0,
                "_id": "612877bf86d06b16de10898d",
                "name": {
                  "en": "CGPSC MAINS",
                  "hn": "सीजीपीएससी मेन्स"
                }
              }
            ]
          },
          {
            "__v": 0,
            "_id": "612878c886d06b16de10898f",
            "name": {
              "en": "MPPSC",
              "hn": "एमपीपीएससी"
            },
            "exams": [
              {
                "__v": 0,
                "_id": "6128793386d06b16de108990",
                "name": {
                  "en": "MPPSC PRELIMS",
                  "hn": "एमपीपीएससी प्रीलिम्स "
                },
                "image": "https://coco-v2-lms.s3.ap-south-1.amazonaws.com/images/40600659-fb20-449f-b723-462cbfc55ca5.jpeg"
              }
            ]
          },
          {
            "__v": 0,
            "_id": "61adc087e0dd15ab576e216f",
            "name": {
              "en": "SSC",
              "hn": ""
            },
            "exams": [
              {
                "__v": 0,
                "_id": "61adc098a40a63ab3d848901",
                "name": {
                  "en": "CHSL",
                  "hn": ""
                }
              },
              {
                "__v": 0,
                "_id": "62d26f0813262dd01ab5d480",
                "name": {
                  "en": "CGL",
                  "hn": ""
                }
              }
            ]
          },
          {
            "__v": 0,
            "_id": "62c960659b176b1aa0be4ade",
            "name": {
              "en": "Bank",
              "hn": "बैंक"
            },
            "exams": [
              {
                "__v": 0,
                "_id": "62d2a963a7cd87125e628d3a",
                "name": {
                  "en": "SBI PO",
                  "hn": "बैंक"
                }
              },
              {
                "__v": 0,
                "_id": "62d272d513262dd01ab5d625",
                "name": {
                  "en": "IBPS RRB",
                  "hn": ""
                }
              },
              {
                "__v": 0,
                "_id": "62d2729c13262dd01ab5d60f",
                "name": {
                  "en": "IBPS PO",
                  "hn": ""
                }
              },
              {
                "__v": 0,
                "_id": "62d2728313262dd01ab5d604",
                "name": {
                  "en": "IBPS CLERK",
                  "hn": ""
                }
              }
            ]
          },
          {
            "__v": 0,
            "_id": "62c960d2b007eb3567d5ddcf",
            "name": {
              "en": "Railway",
              "hn": ""
            },
            "exams": [
              {
                "__v": 0,
                "_id": "62c97b329b176b1aa0be5728",
                "name": {
                  "en": "RRB NTPC",
                  "hn": ""
                }
              },
              {
                "__v": 0,
                "_id": "62c97ab7b007eb3567d5edc1",
                "name": {
                  "en": "RRB Group D",
                  "hn": ""
                }
              },
              {
                "__v": 0,
                "_id": "62d26c7c409a50d03136773e",
                "name": {
                  "en": "RRB ALP & Technician ",
                  "hn": ""
                }
              }
            ]
          },
          {
            "_id": "62dfee7ebcd6490106bd0698",
            "name": {
              "en": "CG Vyapam",
              "hn": ""
            },
            "exams": [
              {
                "_id": "62dfdf84a7cd87125e634e3c",
                "name": {
                  "en": "Sub Inspector",
                  "hn": ""
                }
              }
            ]
          }
        ],
        "testseries": [
          {
            "__v": 0,
            "_id": "6219d23c123e1f1144851821",
            "name": {
              "en": "CGPSC PRELIMS Test Series",
              "hn": "सीजीपीएससी प्रीलिम्स"
            },
            "image": "https://coco-v2-lms.s3.ap-south-1.amazonaws.com/images/9454e7b7-e39d-4f25-a48a-3965303fe71f.jpeg"
          },
          {
            "__v": 0,
            "_id": "6219d212123e1f1144851820",
            "name": {
              "en": "CGPSC MAINS Test Series",
              "hn": "सीजीपीएससी मेन्स"
            },
            "image": "https://coco-v2-lms.s3.ap-south-1.amazonaws.com/images/8a1fcfd2-ddac-42d1-bf63-f4f1ff224a20.jpeg"
          }
        ],
        "createdAt": "2022-05-21T06:09:09.137Z",
        "updatedAt": "2022-07-27T12:30:09.470Z",
        "publishedAt": "2022-07-21T10:28:26.037Z",
        "contact": [
          {
            "id": 2,
            "text": "99999XXXXX",
            "active": true
          },
          {
            "id": 193,
            "text": "999888XXXX",
            "active": true
          }
        ],
        "social": {
          "id": 1,
          "facebook": "https://www.facebook.com/prayaspcisikar123",
          "youtube": "https://www.youtube.com/channel/UCOyfhI9UQ5jNzdvasIwZ2xA",
          "linkedin": "",
          "twitter": "",
          "whatsapp": ""
        },
        "menu_extras": [
          {
            "id": 17,
            "title": "Help & Support",
            "value": null,
            "valuehref": "/help"
          },
          {
            "id": 15,
            "title": "Exam Alerts",
            "value": null,
            "valuehref": "/page/examalerts"
          },
          {
            "id": 18,
            "title": "Careers",
            "value": null,
            "valuehref": "/page/careers"
          },
          {
            "id": 19,
            "title": "Achievements",
            "value": null,
            "valuehref": "/achievement"
          },
          {
            "id": 20,
            "title": "About us",
            "value": null,
            "valuehref": "/about-us"
          }
        ],
        "footer": {
          "id": 1,
          "footer_text": "© Prayas Sikar 2022 | All rights Reserved.",
          "footer_text2": "",
          "firsttitle": "",
          "secondtitle": "Support",
          "thirdtitle": "Legal",
          "fourthtitle": "Quick Links",
          "quick_links": [
            {
              "id": 38,
              "label": "About us",
              "href": "",
              "isExternal": false
            }
          ],
          "firstcolumn": [
            {}
          ],
          "secondcolumn": [
            {
              "id": 16,
              "label": "Discussion Community",
              "href": "/dashboard/community-discussion",
              "isExternal": true
            },
            {
              "id": 17,
              "label": "Question Community",
              "href": "/dashboard/community-questions",
              "isExternal": true
            },
            {
              "id": 18,
              "label": "Feedback",
              "href": "/dashboard/feedback",
              "isExternal": true
            },
          ],
          "thirdcolumn": [
            {
              "id": 20,
              "label": "Terms and Conditions",
              "href": "/terms-conditions",
              "isExternal": true
            },
            {
              "id": 21,
              "label": "Privacy Policy",
              "href": "/privacypolicy",
              "isExternal": true
            }
          ],
          "addresses": [
            {
              "id": 7,
              "line1": "Head Office : 4th Floor, Real Heaven Business Arcade, opp. Vandana Hospital, Mangla, Bilaspur, Chhattisgarh 495001",
              "line2": null,
              "line3": null,
              // "map_lat": "22.081087506741365",
              // "map_lng": "82.16971356809542",
              "title": null
            }
          ]
        }
      }
    },
    "meta": {}
  }