import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Select, Text } from "@chakra-ui/react";
import { filter } from "lodash";
import { forEach } from "lodash";
import { find } from "lodash";
import { map } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { FromLeftAnimation, FromRightAnimation } from "../common/animation";
import SliderCarousel from "../common/slider";
import { Card } from "../Components/ItemCard";
import { pkg } from "./ourCourses";

export default function OfferDiscount({ data }) {
  const [allUniqueExams, setAllUniqueExams] = useState([]);
  const [curSelectedTab, setCurSelectedTab] = useState({ _id: "" });

  useEffect(() => {
    const newUniqueExam = [{ name: { en: "Courses" }, _id: "" }];
    if (data?.length > 0) {
      forEach(data, (packageData) => {
        forEach(packageData.exams, (exam) => {
          if (!find(newUniqueExam, (e) => e._id === exam._id)) {
            newUniqueExam.push(exam);
          }
        });
      });
    }
    setAllUniqueExams(newUniqueExam);
  }, [data]);

  const CurPackagesData = useMemo(() => {
    return filter(
      data,
      (packageData) =>
        !curSelectedTab._id ||
        find(packageData.exams, (e) => e._id === curSelectedTab._id)
    );
  }, [data, curSelectedTab]);

  // const packages = filter(courses, course => course?.offers?.length)
  return (
    <Flex id="offer-discount" mb={10} display={{ base: "column", md: "flex" }}>
      <Box
        py={{ base: 8, md: 20 }}
        w={{ base: "100%", md: "50%" }}
        bg="gray.100"
        alignSelf={"stretch"}
      >
        <FromLeftAnimation>
          <Flex align="center" flexDir={"column"} justifyContent="center">
            <Text
              fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: "4xl" }}
              textAlign="center"
            >
              HURRY UP! GRAB THE OFFER..
            </Text>
            <Box w={{ base: "85%", md: "60%" }}>
              {/* <Text mb={3} fontSize={{ base: "xl", sm: "2xl", md: "3xl", lg: '4xl' }} wordBreak="break-word">50% discount until 1 January</Text> */}
              <Text mt={16} align={"justify"} fontSize={"lg"} display={{ base: "none", md: "initial" }}>
                Prayas provides the best test series, with in-depth result analysis carefully crafted by our team of experts to leverage aspirants to excel in competitive exams.
                To help the students we are providing huge discounts and free test series with courses.

                {/* We are providing free test series along with online courses. */}
              </Text>
              <br />
              <Text align={"justify"} fontSize={{ base: "md", md: "lg" }}>
                The offer is only valid for a limited time.
              </Text>
              <Text my={{ base: 4, md: 10 }} fontSize={{ base: "md", md: "lg" }} fontWeight={"bold"}>
                Dont miss the opportunity. Select course and buy it now.
              </Text>
            </Box>
            {/* <Text fontWeight={"bold"}>Don't miss the chance! (bold)</Text> */}

            <Center>
              <Select
                bg="gray.700"
                color="white"
                w="100%"
                onChange={(e) =>
                  setCurSelectedTab(
                    find(allUniqueExams, (p) => p._id === e.target.value)
                  )
                }
              >
                {map(allUniqueExams, (c) => (
                  <option key={c._id} style={{ color: "black" }} value={c._id}>
                    {c.name.en || c.name.hn}
                  </option>
                ))}
              </Select>
            </Center>

            {/* <Button bg="blackAlpha.900" color='white'>Course <ChevronDownIcon /></Button> */}
          </Flex>
        </FromLeftAnimation>
      </Box>
      <Box w={{ base: "100%", md: "50%" }} px={{ base: 3}} align="center">
        <Box w={{ base: "85%", sm: "85%", md: "90%", lg: "60%" }}>
          <FromRightAnimation>
            <SliderCarousel>
              {map(CurPackagesData, (pkg) => (
                <Box key={pkg._id} align="center" py={20}>
                  <Box w="fit-content" align="left">
                    <Text mx={4} fontSize={"lg"} wordBreak="break-word">
                      {pkg?.offers?.[0]?.title}
                    </Text>
                    <Text
                      mx={4}
                      mb={5}
                      color="gray.500" wordBreak={"break-word"}
                      dangerouslySetInnerHTML={{ __html: pkg?.offers?.[0]?.description }}
                    />
                  </Box>
                  <Card width={{ base: "250px", sm: "350px", md: "100%" }} item={pkg} key={pkg._id} />
                  {/* <Card width={"400px"} item={pkg} key={pkg._id} /> */}
                </Box>
              ))}
            </SliderCarousel>
          </FromRightAnimation>
        </Box>
      </Box>
    </Flex>
  );
}
