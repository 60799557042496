import {
  Box,
  Icon,
  HStack,
  Flex,
  Heading,
  Text,
  Center,
  VStack,
  Image,
} from "@chakra-ui/react";
import { map } from "lodash";
import {
  AiFillInstagram,
  AiFillQuestionCircle,
} from "react-icons/ai";
import { BsFacebook, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaMapMarkerAlt, FaTelegram } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImPhone } from "react-icons/im";
import { MdNotificationsActive } from "react-icons/md";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ContainerX from "../common/containerX";

export default function Footer(props) {
  const { events } = useSelector((s) => ({
    events: s.package.events,
  }));

  return (
    <Box mt={20} id="footer">
      <footer>
        <Box>
          <Box
            py={20}
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            bg="gray.800"
          >
            <ContainerX>
              <Box>
                <Box mb={10} color="white">
                  <Flex align="start" h="fit-content">
                    <Icon w={16} h={16}>
                      <AiFillQuestionCircle />
                    </Icon>
                    <Text mt={2}>Have some questions</Text>
                  </Flex>
                </Box>
                <Flex
                  flexDir={{ base: "column", lg: "row" }}
                  justify="space-between"
                >
                  <VStack
                    justify="start"
                    align="start"
                    spacing={{ lg: 10 }}
                  >
                    <Box maxW={300}>
                      <Icon color="default.500" w={16} h={12}>
                        <FaMapMarkerAlt />
                      </Icon>
                      <Box color="white">Address</Box>
                      <Box mt={2} fontSize="sm" color="gray.400">
                        Nawalgarh Rd, opposite udhyog nagar thana,
                        Ram Nagar, Charan Singh Nagar, Sikar, Rajasthan 332001
                      </Box>
                    </Box>
                    <Box pt={{ base: 4, lg: 0 }} minW={200}>
                      <Icon color="default.500" w={16} h={12}>
                        <ImPhone />
                      </Icon>
                      <Box color="white">Phone no.</Box>
                      <Box fontSize="sm" color="gray.400">
                        +91 8290645748
                      </Box>
                    </Box>
                    <Box pt={{ base: 4, lg: 0 }}>
                      <Icon color="default.500" w={16} h={12}>
                        <GrMail />
                      </Icon>
                      <Box color="white">Email</Box>
                      <Box fontSize="sm" color="gray.400">
                        prayassikar@gmail.com
                      </Box>
                    </Box>
                  </VStack>
                  <Box mt={10}>
                    <Box mb={6} color="white">
                      <Box>Recent updates</Box>
                    </Box>
                    <Flex
                      flexDir={{ base: "column", lg: "row" }}
                      justify="space-between"
                    >
                      <Box flexDir={{ base: "column", lg: "row" }}>
                        {map(events, (event, index) =>
                          index < 2 ? (
                            <RecentBlog key={index} event={event} />
                          ) : null
                        )}
                      </Box>

                    </Flex>
                  </Box>
                  <ImportantLinks />
                </Flex>


                <Flex
                  mt={10}
                  justify="space-between"
                  flexDir={{ base: "column-reverse", md: "row" }}
                >
                  <Image
                    mt={{ base: 5, md: 0 }}
                    w={{ base: "60%", sm: "40%", md: "20%" }}
                    h="80px"
                    src="assets/google-play.png"
                    alt=""
                    cursor="pointer"
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.prayascareerinstitute"
                      )
                    }
                  />
                  <Box
                    mt={{ base: 5, md: 0 }}
                    color="white"
                    cursor="pointer"
                    onClick={() =>
                      window.open("https://youtube.com/@prayaseduhub")
                    }
                  >
                    <Heading fontSize={"lg"}>Subscribe us</Heading>
                    <Flex align="start" mt={4}>
                      <Box>
                        <Icon w={55} h={55} mr={0} color="#ff0000">
                          <BsYoutube />
                        </Icon>
                      </Box>
                      <Box fontSize={20}>Youtube</Box>
                    </Flex>
                  </Box>
                  <ConnectWithUs />
                </Flex>
                <Flex
                  flexDir={{ base: "column", lg: "row" }}
                  mt={10}
                  justify="space-between"
                >
                  <Box color="gray.400">copyright @2022 all right reserved</Box>
                </Flex>
              </Box>
            </ContainerX>
          </Box>
        </Box>
      </footer>
    </Box>
  );
}

const ImportantLinks = () => {
  const history = useHistory();

  return (
    <Flex>
      <Box mt={{ base: 10, lg: 0 }}>
        <Box color="white">Quick Links</Box>
        <VStack align="start" mt={2}>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() => history.push("/")}
          >
            Home
          </Box>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() => history.push("/about-us")}
          >
            About us
          </Box>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() =>
              document
                .getElementById("courses")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Courses
          </Box>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() =>
              document
                .getElementById("test-series")
                .scrollIntoView({ behavior: "smooth" })
            }
          >
            Test series
          </Box>
        </VStack>
      </Box>
      <Box ml={16} mt={{ base: 10, lg: 0 }}>
        <Box color="white">Legal Links</Box>
        <VStack align="start" mt={2}>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() => history.push("/terms-conditions")}
          >
            Term of Services
          </Box>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() => history.push("/privacy-policy")}
          >
            Privacy Policy
          </Box>
          <Box
            color="gray.400"
            cursor="pointer"
            onClick={() => history.push("/privacy-policy")}
          >
            Refund Policy
          </Box>
        </VStack>
      </Box >
    </Flex>
  );
};

const ConnectWithUs = () => {
  return (
    <Box mt={{ base: 4, lg: 0 }}>
      <Box>
        <Heading fontSize="lg" color="white">
          Connect with us
        </Heading>
        <HStack mt={4} spacing={4}>
          <SocialIcon
            onClick={() =>
              window.open("https://www.facebook.com/prayaseduhubsikar/")
            }
            icon={<BsFacebook />}
          />
          <SocialIcon
            onClick={() =>
              window.open("https://www.instagram.com/prayaseduhub/")
            }
            icon={<AiFillInstagram />}
          />
          <SocialIcon
            onClick={() =>
              window.open("https://t.me/prayasonlinestudyy")
            }
            icon={<FaTelegram />}
          />
        </HStack>
      </Box>
    </Box>
  );
};

const SocialIcon = ({ onClick, icon }) => {
  return (
    <Center
      onClick={onClick}
      cursor="pointer"
      fontSize={20}
      color="white"
      _hover={{ color: "default.500" }}
      w={10}
      h={10}
      borderRadius="50%"
      bg="gray.700"
    >
      {icon}
    </Center>
  );
};

const RecentBlog = ({ key, event }) => {
  return (
    <Box key={key} py={2} pl={0} pr={4} maxW={{ lg: 400 }} cursor="pointer" onClick={() => window.open(event.link)}>
      <HStack spacing={2} align="center">
        <Flex color="default.500">
          <MdNotificationsActive color="default.500" fontSize={35} />
        </Flex>
        <Text color="gray.400" noOflines={3}>
          {event.body}
        </Text>
      </HStack>
    </Box>
  );
};
