
import { Box } from '@chakra-ui/react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VimeoPlayer from './VimeoPlayer'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { setActiveVideoPlayer } from '../../redux/reducers/courses'

export default function VideoPlayer(props) {
    
    const history = useHistory();
    const dispatch = useDispatch();

    const { activeVideoUrlData } = useSelector( s => ({
        activeVideoUrlData: s.course.activeVideoUrlData
    }))

    useEffect( () => {
        return () => dispatch(setActiveVideoPlayer(null));
    },[dispatch])

    useEffect( () => {
        if(!activeVideoUrlData){
            history.push('/');
        } 
    },[activeVideoUrlData, history])

    return (
        <Box>
            <VimeoPlayer url={activeVideoUrlData?.url}/>
        </Box>
    )
}

