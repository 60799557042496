import { Box, Text, List } from '@chakra-ui/layout'
import React from 'react'
import { useSelector } from 'react-redux'
import { checkExpiry } from '../../utils/Helper'
import { PdfCard } from '../Contents/PdfFiles'

export const PDFLibrary = ({content}) => {
    const {courseList, user} = useSelector(state => ({
        courseList:state.package.packageContents?.courses,
        user:state.user.student
    }))
    //user->student

    let list = _.chain(content).map(d => {
        let course = courseList?.length ? _.find(courseList,c => c._id == d.courseId) : []
        let assignedCourseDetails = user.courses?.length ? _.find(user.courses,c => c.course == d.courseId) : []
        return course ? ({...d, ..._.omit(assignedCourseDetails, ['_id']), course:{name:course.name}}) : d
    }).filter(d => d.course && checkExpiry(d.expireOn)).value()

    return(
        <Box>
            {list?.length ? 
                <List w='100%' spacing={4}>
                    {list.map(doc =>
                        <PdfCard key={doc._id} library={list} doc={doc}/>
                    )}
                </List>
                :
                <Text>No document added</Text>
            }
        </Box>
    )
}