import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddressComponent, WalletDetails } from "../Cart";
import { useState } from "react";
import { useEffect } from "react";
import { requestUserAddressAction } from "../../redux/reducers/address";
import { useCallback } from "react";
import { useAppContext, useIsAuthenticated } from "../../App/Context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQueryParams } from "../../utils/useQueryParams";
import { useCart } from "../../Cart/useCart";
import { ButtonX } from "../../Components/ButtonX";
import { PromoCodeSection } from "./PromoCodeSection";
import { getOrderDetailsAction } from "../../redux/reducers/checkout";
import { usePaymentVerify } from "../Cart/usePaymentVerify";
import ContainerX from "../../common/containerX";
import { CommonHeader } from "../../Components/CommonHeader";
import { useLoginModal } from "../../App/useLoginModal";
import { setStudentData } from "../../redux/reducers/user";
import { map } from "lodash";
import { ROUTES } from "../../Constants/Routes";

export const Checkout = () => {
  const dispatch = useDispatch();
  let query = useQueryParams();
  const id = query.get("id");
  const name = query.get("name");

  const {
    cartAnalysis: { isOffline },
  } = useCart();

  const isAuthenticated = useIsAuthenticated();
  const { openLoginModal } = useLoginModal();
  const { userData } = useSelector((s) => ({
    userData: s?.user?.user,
  }));

  useEffect(() => {
    if (!isAuthenticated) {
      openLoginModal();
    }
  }, [isAuthenticated, openLoginModal]);

  const [useWallet, setUseWallet] = useState(false);
  const [userBillingAddress, setUserBillingAddress] = useState(null);
  const [userShippingAddress, setUserShippingAddress] = useState(null);

  useEffect(() => {
    if (userData && isAuthenticated) {
      dispatch(requestUserAddressAction({ userId: userData._id }));
    }
  }, [dispatch, isAuthenticated, userData]);

  const handleUpdateUserAddresses = useCallback((address, addressType) => {
    if (addressType === "billing") {
      setUserBillingAddress(address);
    } else if (addressType === "shipping") {
      setUserShippingAddress(address);
    }
  }, []);

  const getData = useCallback(
    (payload) => {
      dispatch(
        getOrderDetailsAction({
          ...payload,
          packageId: id,
          user: userData?._id,
        })
      );
    },
    [dispatch, id, userData?._id]
  );

  useEffect(() => {
    if (isAuthenticated) getData();
  }, [getData, isAuthenticated]);

  return (
    <>
      <CommonHeader pageContext="cart" />
      <ContainerX>
        <Box>
          {/* <Nabar /> */}
          {!isAuthenticated ? (
            <Alert status="warning">
              <AlertIcon />
              You are not Logged In, Please login now to Complete your Purchase
            </Alert>
          ) : null}
          <Heading size="md" mt={5}>
            Checkout
          </Heading>
          <Text ml={5} mt={6} mb={2} fontSize={"xl"}>
            {name}
          </Text>
          <Flex justify={"space-around"} flexWrap={"wrap"}>
            <Box
              boxShadow="xs"
              borderRadius="xl"
              bg="white"
              p={"1.5rem"}
              mb={4}
              width={["100%", "100%", "60%"]}
            >
              {isOffline ? (
                <>
                  <Text my={2}>Please provide Address</Text>
                  <AddressComponent
                    addressType="shipping"
                    userData={userData}
                    selectedAddress={userShippingAddress}
                    updateUserAddresses={handleUpdateUserAddresses}
                  />
                </>
              ) : null}

              <br />

              <AddressComponent
                addressType="billing"
                userData={userData}
                selectedAddress={userBillingAddress}
                updateUserAddresses={handleUpdateUserAddresses}
              />
            </Box>
            <Box
              boxShadow="xs"
              borderRadius="xl"
              bg="white"
              p="1.5rem"
              ml={[0, 0, 10]}
              width={["100%", "100%", "30%"]}
            >
              <CheckoutSection
                id={id}
                getData={getData}
                userAddresses={{ userBillingAddress, userShippingAddress }}
                // checkout={checkout}
                useWallet={useWallet}
                setUseWallet={setUseWallet}
              />
            </Box>
          </Flex>
        </Box>
      </ContainerX>
    </>
  );
};

const CheckoutSection = ({
  id,
  getData,
  userAddresses,
  useWallet,
  setUseWallet,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const toast = useToast();
  const history = useHistory();
  const { orderdata, pkg } = useSelector((s) => ({
    orderdata: s.checkout.orderDetails,
    pkg: s.checkout.pkg,
  }));

  const {
    cartAnalysis: { isOffline },
  } = useCart();
  const _completeUserAddress = () => {
    toast.closeAll();
    toast({
      status: "error",
      title: "Add a Billing Address",
      description: "Please add a Billing Address to Continue this Purchase",
      duration: 5000,
    });
  };

  const { getPackageContent } = useAppContext();

  const dispatch = useDispatch();
  const { _checkout } = usePaymentVerify({
    onSuccess: (data, orderId, resp) => {
      toast({
        status: "success",
        title: "Payment done",
        description: "Packages have been added to your account",
      });

      if (data?.student) {
        data?.student && dispatch(setStudentData(data));
        getPackageContent(data?.student);
      }
      const finalData = !orderId
        ? {
            amount: data.amount,
            status: data.status,
            orderId: data._id,
            packages: map(data.cart?.packages, (p) => {
              return {
                _id: p._id,
                name: p.name?.en,
              };
            }),
          }
        : {
            amount: data.amount,
            status: data.status,
            rOrderId: resp.razorpay_order_id,
            rPaymentId: resp.razorpay_payment_id,
            orderId: orderId,
            packages: map(data.cart?.packages, (p) => {
              return {
                _id: p._id,
                name: p.name?.en,
              };
            }),
          };
      history.push(
        `${ROUTES.SUCCESS_CART}?rpdata=${JSON.stringify(finalData)}`
      );
      // const finalData = !orderId
      //   ? {
      //     amount: data.amount,
      //     status: data.status,
      //     orderId: data._id,
      //     packages: [id],
      //   }
      //   : {
      //     amount: data.amount,
      //     status: data.status,
      //     rOrderId: resp.razorpay_order_id,
      //     rPaymentId: resp.razorpay_payment_id,
      //     orderId: orderId,
      //     packages: [id],
      //   };
      // history.push("/dashboard/order");
    },
  });

  const _thisCheckout = (lang, mode, center) => {
    _checkout({
      amount: orderdata?.topay,
      promoCode:
        orderdata?.promoMessage === "valid" ? orderdata?.promocode : null,
      lang,
      mode,
      center,
      packages: [id],
      // New Params
      deliverable: isOffline ? true : false,
      type: useWallet ? "Wallet" : "Online",
      addressBilling: userAddresses?.userBillingAddress,
      addressShipping: userAddresses?.userShippingAddress,
    });
  };

  const handlePaymentProcess = () => {
    if (!userAddresses) {
      _completeUserAddress();
    } else {
      _thisCheckout();
    }
  };

  if (orderdata && orderdata?.topay === 0) {
    return (
      <Box pt={14}>
        <VStack spacing="10px" alignItems="left">
          <Box
            spacing="1rem"
            boxShadow="md"
            borderRadius="xl"
            overflow="hidden"
            p="0"
          >
            <Box
              color="brand.redAccent"
              bg="brand.redAccentLight"
              fontWeight="bold"
              display="flex"
              alignItems="center"
            >
              <Text m={4} fontSize="xl" marginLeft="20px">
                Unlock
              </Text>
            </Box>
            <Box p={5}>
              <Text>This package is free to unlock</Text>
            </Box>

            <ButtonX
              w="100%"
              mt="0rem"
              colorScheme="green"
              borderRadius="5px"
              // onClick={_checkout}
              //   disabled={!isAuthenticated}
            >
              Unlock Now
            </ButtonX>
          </Box>
        </VStack>
      </Box>
    );
  }
  if (orderdata?.error) {
    return <Box>{orderdata.error}</Box>;
  }

  return (
    <Box>
      <PromoCodeSection getOrderDetails={getData} />
      <VStack spacing="10px" alignItems="left">
        <Box
          spacing="1rem"
          boxShadow="md"
          borderRadius="xl"
          overflow="hidden"
          p="0"
        >
          <Box
            color="brand.redAccent"
            bg="brand.redAccentLight"
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <Text m={4} fontSize="xl" marginLeft="20px">
              Checkout
            </Text>
          </Box>
          {/* Price Details */}
          <Box p="1rem" backgroundColor="white">
            <VStack spacing="1rem" alignItems="flex-start">
              <Box display="flex" width="100%" justifyContent="space-between">
                <Text>Total MRP</Text>
                <Text>₹{orderdata?.total}</Text>
              </Box>
              <Box display="flex" width="100%" justifyContent="space-between">
                <Text>Discount on MRP</Text>
                <Text color="green.500">
                  - ₹{orderdata?.initialDisocunt || "0"}
                </Text>
              </Box>
              <Box display="flex" width="100%" justifyContent="space-between">
                <Text>Promocode savings </Text>
                <Text color="green.500">₹ {orderdata?.promoDiscount}</Text>
              </Box>

              {orderdata?.extraUserDiscount ? (
                <Box display="flex" width="100%" justifyContent="space-between">
                  <Text>Extra savings just for you </Text>
                  <Text color="green.500">
                    - ₹ {orderdata?.extraUserDiscount}
                  </Text>
                </Box>
              ) : null}

              <Box display="flex" width="100%" justifyContent="space-between">
                <Text>GST </Text>
                <Text>+ ₹{orderdata?.gst}</Text>
              </Box>
              <Divider marginTop="10px" />
              <Box display="flex" width="100%" justifyContent="space-between">
                <Text fontWeight="bold">Total Amount</Text>
                <Text fontWeight="bold">₹{orderdata?.topay}</Text>
              </Box>
            </VStack>
            {/* Place Order Button */}

            {orderdata?.wallet ? (
              <Box>
                <WalletDetails
                  topay={orderdata?.topay}
                  useWallet={useWallet}
                  setUseWallet={setUseWallet}
                />
              </Box>
            ) : (
              <Box
                p={3}
                m={2}
                fontSize="xs"
                color="gray.500"
                borderRadius={"md"}
                boxShadow="sm"
              >
                Wallet is not enabled for this package
              </Box>
            )}
            <ButtonX
              w="100%"
              mt="0rem"
              colorScheme="green"
              borderRadius="5px"
              onClick={handlePaymentProcess}
              disabled={!isAuthenticated}
            >
              {" "}
              Proceed to pay {useWallet ? "using Wallet " : "online"}
            </ButtonX>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};
