import { Box, Button, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { STATUS } from "../../Constants";
import { bilingualText } from "../../utils/Helper";
import { RegisterationForm } from "./RegisterationForm";

export const InfoOfflinePackage = ({ currentPkg }) => {
  return (
    <VStack border="1px solid #D6DBDF" align={"stretch"} spacing={0}>
      <DetailField
        highlight
        type="Registeration Start On"
        value={
          currentPkg.startDate ? moment(currentPkg.startDate).format("LL") : "-"
        }
      />
      <DetailField
        type="Last Date to Apply"
        value={
          currentPkg.endDate ? moment(currentPkg.endDate).format("LL") : "-"
        }
      />
      <DetailField
        type="Issue Admit Card"
        value={
          currentPkg.admitCardDate
            ? moment(currentPkg.admitCardDate).format("LL")
            : "-"
        }
      />
      <DetailField
        type="Exam Date"
        extra={<pre>{currentPkg.description?.hn}</pre>}
      />
      <DetailField
        type="Result Declaration On"
        value={
          currentPkg.resultDate
            ? moment(currentPkg.resultDate).format("LL")
            : "-"
        }
      />
    </VStack>
  );
};

export const OfflinePackageRegistration = ({}) => {
  const { getPkgStatus, currentPkg, getPublicContentStatus } = useSelector(
    (state) => ({
      getPkgStatus: state.package?.getSinglePackgStatus,
      currentPkg: state.package.currentPackage?.[0],
      getPublicContentStatus: state.course.getPublicContentStatus,
    })
  );

  const [successView, setSuccessView] = useState(false);
  return (
    <Box p={[5, 50]}>
      <ErrorChecker status={getPkgStatus}>
        {getPkgStatus === STATUS.SUCCESS && currentPkg ? (
          <Flex justify={"center"}>
            <Box width={{ base: "100%", md: "80%" }}>
              <Heading as="h5" size="md">
                Register Now For{" "}
                {currentPkg?.name && bilingualText(currentPkg.name)}
              </Heading>
              <br />

              <InfoOfflinePackage currentPkg={currentPkg} />
              <br />
              <br />
              {/* <Tabs isFitted variant='enclosed' index={currentIndex}>
                <TabList mb='1em'>
                  <Tab _selected={{ color: 'white', bg: '#304188' }} color='#304188' padding={3}><ChevronRightIcon/>Register Now</Tab>
                  <Tab _selected={{ color: 'white', bg: '#304188' }} color='#304188'><ChevronRightIcon/>Verify Payment</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <RegisterationForm currentPkg={currentPkg} nextStep={_nextStep} />
                  </TabPanel>
                  <TabPanel>
                    <OfflineTestPackagePayment currentPkg={currentPkg} />
                  </TabPanel>
                </TabPanels>
              </Tabs> */}
              {successView ? (
                <Flex direction={"column"} align={"center"} p={20} mt={50}>
                  <Heading color="green.600" fontSize="xl">
                    You have been successfully registered.
                  </Heading>
                  <Text fontSize="md">
                    Thank you for registeration with us!
                  </Text>
                  <Button
                    mt={20}
                    colorScheme="primary"
                    onClick={() => setSuccessView(false)}
                  >
                    Register another student
                  </Button>
                </Flex>
              ) : (
                <RegisterationForm
                  successView={successView}
                  setSuccessView={setSuccessView}
                  currentPkg={currentPkg}
                />
              )}
            </Box>
          </Flex>
        ) : null}
      </ErrorChecker>
    </Box>
  );
};

const DetailField = ({ type, value, extra, highlight }) => {
  return (
    <Flex wrap={"wrap"} borderBottom="1px solid #D6DBDF" align={"Stretch"}>
      <Box p={2} w={{ base: "100%", md: 300 }}>
        <Text color="GrayText">{type} </Text>
      </Box>
      <Box borderLeft="1px solid #D6DBDF" p={2}>
        {extra || <Text color={highlight && "#E74C3C"}>{value}</Text>}
      </Box>
    </Flex>
  );
};
