import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import _ from 'lodash';
import { Avatar, Box, Button, CircularProgress, CircularProgressLabel, HStack, Image, List, ListItem, Text, VStack } from '@chakra-ui/react';
import { contentType, headerIcon } from './DashboardMain';
import { checkExpiry } from '../../utils/Helper';
import { Progressbar } from '../../Components/Progressbar';

export const CoursesList = () => {
    const history = useHistory()
    const { courses, trackings, student } = useSelector((state) => ({
        courses:state.package.packageContents?.courses,
        trackings:state.tracking.trackings,
        student:state.user.student
    }))

    const [courseList, setCourseList] = useState()

    useEffect(() => {
        if(student && courses){
            let list = courses.map(l => _.findIndex(student?.courses,c => c.course == l._id) != -1 ? ({..._.find(student?.courses,c => c.course == l._id), ...l}) : l)
            setCourseList(list)
        }
    }, [student, courses])

    const openCourse = (crc, type) => {
        history.push(`/dashboard/courses/${crc._id}/${type}`)
    }

    const allCourses = () => {
        history.push('/dashboard/courses')
    }
    
    return(
        <VStack flexGrow={2} borderRadius='15px' align='stretch' bg='#FFEBF6'>
            <HStack px={6} pt={2} justifyContent='space-between'>
                <HStack spacing={4}>
                    {headerIcon}
                    <Text fontSize='md'>Courses</Text>
                </HStack>
                <Button onClick={allCourses} color='brand.red' variant='link' fontSize='sm' fontWeight='400'>View All</Button>
            </HStack>

            <Box flexGrow={1} borderRadius='15px' background='white'>
                <List w='100%'>
                    {courseList?.length ? 
                        courseList.map(crc => 
                            {
                                const courseTrackings = trackings?.[crc._id] || {};
                                let totalCount = _.sum([crc.countTexts, crc.countVideos, crc.countAudios, crc.countDocs])
                                let progress = _.chain(courseTrackings).flatMap(d => _.size(d)).sum().value()
                                let track = totalCount ? ((progress/totalCount) * 100) : 0
                                let daysLeft = crc.expireOn ? checkExpiry(crc.expireOn, crc.duration) : 'nonExpired'
                                let expired = daysLeft ? false : true
                                return(
                                    <ListItem key={crc._id} pos='relative' boxShadow='0px 3px 6px #0000000A' px={6} py={2}>
                                        {expired ?
                                            <HStack justifyContent='center' textAlign='center' background='#ECF0F1' opacity='0.2' 
                                                pos='absolute' zIndex='10' top={0} bottom={0} left={0} right={0}
                                            >
                                                <Image src='/images/expired.jpg' w='100px'/>
                                            </HStack>
                                            :
                                            null
                                        }
                                        
                                        <HStack filter={expired && 'grayscale(1)'} spacing={6} justifyContent='space-between'>
                                            <HStack>
                                                <Progressbar value={track}/>
                                                <Text fontSize='md'>{crc.name}</Text>
                                            </HStack>
                                            <HStack spacing={4}>
                                                {crc.countAudios ? <Avatar cursor='pointer' size='sm' bg={contentType('Audio').bg} icon={contentType('Audio', 17).icon} 
                                                    onClick={() => expired ? null : openCourse(crc, 'audios')}
                                                /> : null }
                                                {crc.countVideos ? <Avatar cursor='pointer' size='sm' bg={contentType('Video').bg} icon={contentType('Video', 17).icon} 
                                                    onClick={() => expired ? null : openCourse(crc, 'videos')}
                                                /> : null }
                                                {crc.countDocs ? <Avatar cursor='pointer' size='sm' bg={contentType('Document').bg} icon={contentType('Document', 17).icon} 
                                                    onClick={() => expired ? null : openCourse(crc, 'documents')}
                                                /> : null }
                                                {crc.countTexts ? <Avatar cursor='pointer' size='sm' bg={contentType('Text').bg} icon={contentType('Text', 17).icon} 
                                                    onClick={() => expired ? null : openCourse(crc, 'texts')}
                                                /> : null }
                                            </HStack>
                                        </HStack>
                                    </ListItem>
                                )
                            }
                        )
                        :
                        <ListItem boxShadow='0px 3px 6px #0000000A' px={6} py={4}>
                            <HStack justifyContent='center'>
                                <Text color='brand.secondary'>No course available</Text>
                            </HStack>
                        </ListItem>
                    }
                </List>
            </Box>
        </VStack>
    )
}