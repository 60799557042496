import { DeleteIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Input,
    InputGroup,
    Text,
    Tooltip,
    VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsAction } from "../../redux/reducers/checkout";
import { useIsAuthenticated } from "../../App/Context";

export const PromoCodeSection = ({ getOrderDetails }) => {
    const dispatch = useDispatch()
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated)
            dispatch(getOrderDetailsAction())
    }, [dispatch, isAuthenticated])
    const orderdata = useSelector((s) => s.checkout.orderDetails);
    const [promoCode, changePromo] = useState("");

    const _changePromo = (e) => {
        changePromo(e.target.value);
    };

    const applyPromo = (e) => {
        e.preventDefault();
        getOrderDetails({ promocode: promoCode });
    };

    const removePromocode = () => {
        getOrderDetails();
        changePromo("");
    };

    return (
        <Box mb={4}>
            <VStack
                spacing="1rem"
                boxShadow="sm"
                borderRadius="xl"
                bg="white"
                p="1.5rem"
            >
                <Box>
                    <Text fontSize="large" fontWeight="bold" color="gray.700">
                        APPLY PROMOCODE
                    </Text>
                </Box>
                <form onSubmit={applyPromo} style={{ width: "100%" }}>
                    <InputGroup>
                        <Input
                            type="text"
                            borderRadius="5px 0 0 5px"
                            color="#3C4043B3"
                            bg="lightGrayBlue"
                            value={promoCode}
                            required
                            name="promo"
                            onChange={_changePromo}
                            placeholder="Enter Coupon Code"
                        />
                        <Button
                            borderRadius="0 5px 5px 0"
                            type="submit"
                            colorScheme="green"
                            fontSize="small"
                            fontWeight="normal"
                        >
                            Apply
                        </Button>
                    </InputGroup>
                </form>
                <Box>
                    {orderdata?.promoData && orderdata?.promoDiscount ? (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            marginBottom="5px"
                            bg="#EDF4E5"
                            color="green.500"
                            border="1px solid green"
                            p="5px"
                        >
                            <Box>
                                Promo Code applied: <b>{orderdata.promoData?.code}</b>
                            </Box>
                            <Tooltip label="Remove" placement="top">
                                <DeleteIcon
                                    color="red.500"
                                    cursor="pointer"
                                    onClick={removePromocode}
                                    fontSize="16px"
                                />
                            </Tooltip>
                        </Box>
                    ) : orderdata && orderdata.promocode && !orderdata.promoDiscount ? (
                        <Text color="red.500" fontWeight="bold" alignItems="left">
                            Promocode not valid
                        </Text>
                    ) : null}
                </Box>
            </VStack>
        </Box>
    );
};

