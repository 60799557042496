import React, { useMemo, useState } from "react";
import {
  Box,
  HStack,
  Text,
  Select,
  Stack,
  Skeleton,
  Flex,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";

import { Card, TestCard } from "../../Components/ItemCard";
import { IoGridOutline } from "react-icons/io5";
import { BsList } from "react-icons/bs";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { orderBy } from "lodash";

export const SearchResultPanel = () => {
  return (
    <Box width="100%" p="5px">
      <HStack justify="space-between">
        <Box style={{ width: "200px" }} bg="white" rounded="md">
          <Select
            placeholder="Select option"
            icon={<ChevronDownIcon />}
            defaultValue="option1"
          >
            <option value="option1">Newly Published</option>
            <option value="option2">Most Popular</option>
            <option value="option3">Option 3</option>
          </Select>
        </Box>
        <HStack>
          <Text color="gray.500">Showing 1-2 of 2 results</Text>
          <BsList
            style={{
              fontSize: "30px",
              backgroundColor: "white",
              padding: "3px",
            }}
          />
          <IoGridOutline
            color="white"
            fontSize="30px"
            bg="primaryBlue.400"
            p="3px"
          />
        </HStack>
      </HStack>
    </Box>
  );
};

export const TestItemsSection = ({ items, title }) => {
  const [isSmallDevice] = useMediaQuery("(max-width: 48em)");

  const itemsInView = useMemo(() => {
    return orderBy(
      items,
      "priority",
      "asc"
      // recent && !filtersApplied ?  "priority" : "updatedAt",
      // recent ? "asc" : "desc"
    );
  }, [items]);

  return (
    <Box p={1} pb={8} pt={4} rounded="md">
      <HStack justify="space-between" paddingX="1rem">
        {title ? (
          <Box>
            <Text fontSize="header" style={{ fontWeight: "bold" }} size="md">
              {title}
            </Text>
            <Box
              borderBottom="3px solid"
              borderColor="brand.redAccent"
              width="32%"
            ></Box>
          </Box>
        ) : null}
      </HStack>
      <Box py={10}>
        {itemsInView?.map((item, i) => (
          <TestCard item={item} key={i} />
        ))}
      </Box>
    </Box>
  );
};

export const ItemsSection = ({
  items,
  recent = false,
  title,
  showAll,
  filtersApplied,
  showToggle = true,
  testSeries,
}) => {
  const [isSmallDevice] = useMediaQuery("(max-width: 48em)");
  const [isShowingAll, setIsShowingAll] = useState(
    isSmallDevice ? true : showAll
  );

  const toogleViewAll = () => {
    if (items.length !== itemsInView.length) {
      setIsShowingAll(true);
    } else {
      setIsShowingAll(false);
    }
  };

  // const numOfItems = useBreakpointValue({xs:2, sm:2, md:2, lg:3, xl:4})
  // console.log({numOfItems})
  const itemsInView = useMemo(() => {
    let finalItems = orderBy(
      items,
      "priority",
      "asc"
      // recent && !filtersApplied ?  "priority" : "updatedAt",
      // recent ? "asc" : "desc"
    );

    return isShowingAll
      ? finalItems
      : finalItems?.slice(0, finalItems.length > 3 ? 3 : finalItems.length);
  }, [isShowingAll, items, recent, filtersApplied]);

  return items ? (
    <Box p={1} pb={8} pt={4} rounded="md">
      <HStack justify="space-between" paddingX="1rem">
        {title ? (
          <Box>
            <Text fontSize="header" style={{ fontWeight: "bold" }} size="md">
              {title}
            </Text>
            <Box
              borderBottom="3px solid"
              borderColor="brand.redAccent"
              width="32%"
            ></Box>
          </Box>
        ) : null}
        <Box className="mobile-hide">
          {items.length > 3 && showToggle ? (
            <Text
              onClick={toogleViewAll}
              color="brand.redAccent"
              cursor="pointer"
              fontWeight="bold"
              fontSize="md"
            >
              {isShowingAll ? "View Less" : "View All"}
            </Text>
          ) : null}
        </Box>
      </HStack>
      <Box
        //padding={[0,0,2]}
        id="itemsListContainer"
        display={["flex"]}
        flexDirection={["column", "row", "row"]}
        flexWrap="wrap"
        w="100%"
        justifyContent={["center", "flex-start"]}
        alignItems="stretch"
        key={items.length}
      >
        {itemsInView?.map((item, i) => (
          <Card
            testSeries={testSeries}
            item={item}
            key={i}
            width={["90vw", "50%", "50%", "33%"]}
            height={["45vw", "150px"]}
          />
        ))}
      </Box>
    </Box>
  ) : (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  );
};
