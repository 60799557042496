import {
  liveBaseUrl,
  localBaseUrl,
  testBaseUrl,
} from "../BaseUrl";

export function getBaseUrl(host) {
  console.log({ host });
  if (host === "localhost") {
    return liveBaseUrl;
  } else if (host === "student.test.prayas.tutorlog.in") {
    return testBaseUrl;
  } else if (
    host === "student.prayassikar.com" ||
    host === "prayassikar.com" ||
    host === "prayassikar.in" ||
    host === "prayaseduhub.com"
  ) {
    return liveBaseUrl;
  } else {
    return localBaseUrl;
  }
}
